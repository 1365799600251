import { useState, useContext } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';

import { RenderContext } from "hooks/context";
import styled from "styled-components";
import CustomButton from "components/Button/CustomButton";
interface AlertProps {
    isOpen: any;
    onClose: () => void;
    contents?: string;
    allClose?: (state: boolean) => void;
    navi?: () => void;
}

export default function CloseAlert({ isOpen, onClose, navi }: AlertProps) {

    const {
        closeCancel,
        closeModal,
        closeUser,
        closeNpp,
        closeAllNpp,
        closeSub,
        closeNppTable,
        closeAllNppTable,
        closeSubTable,
        closeUserTable,
    } = useContext(RenderContext);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: "5px",
        boxShadow: 24,
        // width : "298px",
        // textalign : "center"
        // p: 1,
    };

    const [open, setOpen] = useState(false);

    const closeAll = () => {
        closeModal();
        closeUser();
        closeNpp();
        closeNppTable();
        closeSub();
        closeSubTable();
        closeUserTable();
        closeCancel();
        closeAllNpp();
        closeAllNppTable();
    };

    const movePage = () => {
        onClose();
        navi();
    };

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onClose}
            >
                <Box sx={style}>
                    <Wrapper>
                        <TextSection>
                            If you leave this page,any changes you have made will be lost. Are you sure you wish to leave this page?
                        </TextSection>
                        <BtnBox>
                            <CustomButton
                                title="Leave page"
                                classname={"save-btn"} //disable-check
                                icon={
                                    <></>
                                }
                                btnClick={() => navi ? movePage() : closeAll()}
                                Width="150"
                                Height="40"
                            />
                            <CustomButton
                                title="CANCEL"
                                classname="cancel-btn"
                                icon={
                                    <></>
                                }
                                Width="100"
                                Height="40"
                                // btnClick={() => onClose()} />
                                btnClick={() => navi ? onClose() : closeCancel()} />
                        </BtnBox>
                    </Wrapper>
                </Box>
            </Modal>
        </div>
    );
}

const Wrapper = styled.div`
    width: 300px;
    height: 176px;

    padding-top : 24px;
    padding-bottom : 24px;

    padding-left: 16px;
    padding-right: 16px;
`;
const TextSection = styled.div`
    text-align: center;
    font-family: SamsungOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
`;

const BtnBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    gap : 20px;
`;