import axios from "axios";
import { RenderContext } from "hooks/context";
import { useContext } from "react";
import { ApiResponse, apiresponse } from "types/api/apiType";

export const axiosInstance = axios.create({
  //  baseURL: "http://192.168.50.176:8000",
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config: any) => {
  const accessToken = localStorage.getItem("token");
  const refreshtoken = localStorage.getItem("refreshToken");

  if (!accessToken || !refreshtoken) {
    config.headers["accessToken"] = null;
    config.headers["refreshToken"] = null;
    return config;
  }

  if (config.headers && (accessToken && refreshtoken)) {
    if (config.url?.includes("/refresh")) {
      config.headers["Authorization"] = `Bearer ${refreshtoken}`;
    } else {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  }
});


export const getData = async (url: string): Promise<ApiResponse> => {
  try {
    const response = await axiosInstance.get<ApiResponse>(url, {
    }
    );
    return { isSuccess: true, data: response.data };
  } catch (error: any) {
    return { isSuccess: false, data: error };
  }
};

export const getFileData = async (url: string): Promise<ApiResponse> => {
  try {
    const response = await axiosInstance.get<ApiResponse>(url, {
      responseType: 'blob',
    });
    console.log(response.headers, 'response file down');
    return { isSuccess: true, data: response.data, fileName: response.headers["content-disposition"] };
  } catch (error: any) {
    return { isSuccess: false, data: error };
  }
};

export const postData = async <T>(
  url: string,
  body?: T
): Promise<ApiResponse> => {
  try {
    const response = await axiosInstance.post<ApiResponse>(url, body, {
    }
    );
    return { isSuccess: true, data: response.data };
  } catch (error: any) {
    return { isSuccess: false, data: error };
  }
};

export const postTest = async (
  url: string,
  body?: any
) => {
  try {
    const response = await axiosInstance.post<any>(url, body);
    // console.log(response, 'postTest response');
    return response;
  } catch (error: any) {
    // console.log(error, 'postTest error');
    return error;
  }
};

export const postDataTest = async <T>(
  url: string,
  body?: T
): Promise<apiresponse> => {
  try {
    // const response = await axiosInstance.post<apiresponse>(url, body, {
    const response = await axiosInstance.post<apiresponse>(url, body, {
    }
    );
    // console.log(response, 'postDataTest reponse');
    return response;
  } catch (error: any) {
    // console.log(error, 'postDataTest error');
    return error;
  }
};

export const postDataDircect = async <T>(
  url: string,
  body?: T
): Promise<ApiResponse> => {
  try {
    const response = await axiosInstance.post<ApiResponse>(url, body, {
    }
    );
    return { isSuccess: true, data: response };
  } catch (error: any) {
    return { isSuccess: false, data: error };
  }
};

export const postImgData = async <T>(
  url: string,
  body?: T
): Promise<ApiResponse> => {
  try {
    const response = await axiosInstance.post<ApiResponse>(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
    );
    return { isSuccess: true, data: response };
  } catch (error: any) {
    return { isSuccess: false, data: error };
  }
};

export const patchData = async <T>(
  url: string,
  body?: T,
): Promise<ApiResponse> => {
  try {
    const response = await axiosInstance.patch<ApiResponse>(url, body, {
    });
    return { isSuccess: true, data: response.data };
  } catch (error: any) {
    console.log(error, 'axios error');
    return { isSuccess: false, data: error.response };
  }
};

export const patchDatadircet = async <T>(
  url: string,
  body?: T,
): Promise<ApiResponse> => {
  try {
    const response = await axiosInstance.patch<ApiResponse>(url, body, {
    });
    return { isSuccess: true, data: response };
  } catch (error: any) {
    console.log(error, 'axios error');
    return { isSuccess: false, data: error };
  }
};

export const deleteData = async (url: string): Promise<ApiResponse> => {
  try {
    const response = await axiosInstance.delete<ApiResponse>(url, {
    });
    return { isSuccess: true, data: response.data };
  } catch (error: any) {
    return { isSuccess: false, data: error };
  }
};
