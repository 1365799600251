import { useContext, useEffect, useState } from "react";
import AlertModal from "../modal/AlertModal";
import { RenderContext } from "hooks/context";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { BtnContainer } from "components/alert/PopupAlert";
import styled from "styled-components";
import { axiosInstance } from "apis/apiCore";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    // p: 3,
    width: "300px",
    height: "150px",
};
export default function LogoutAlert() {

    const tokenTime = JSON.parse(localStorage.getItem("tokenTime") || "{}");

    const {
        isLogOut,
        openLogOut
    } = useContext(RenderContext);

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (error) => {
            if (error.response.status === 401) {
                try {
                    openLogOut();
                    return await error.config;
                } catch (error) {
                    console.log(error, "interceptors Error");
                }
            }else {
                return Promise.reject(error)
            }
        }
    );

    

    useEffect(() => {
        const timer = setInterval(() => {
            // console.log(tokenTime, Date.now(), 'timer');
            // if (tokenTime <= Date.now()) {
            if (tokenTime <= Date.now()) {
                console.log(tokenTime, Date.now(), 'logout time');
                openLogOut();
                return clearInterval(timer);
            }
        }, 60000);
    }, []);

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <>
            {isLogOut &&
                <Modal
                    open={isLogOut}
                    onClose={() => logout()}
                >
                    <Box sx={style}>
                        <TextSection>
                            Your session has been automatically logged out for security purposes.
                        </TextSection>
                        <BtnContainer>
                            <OkBtn onClick={() => logout()}>OK</OkBtn>
                        </BtnContainer>
                    </Box>
                </Modal>
            }
        </>
    );
}


const TextSection = styled.div`
    width: 100%;
    font-family: SamsungOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    text-align: center;
    height: 50%;
    margin-top: 20px;
`;

const OkBtn = styled.div`
    width: 70px;
    height: 40px;
    background-color: #2196F3;
    color : white;

    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: SamsungOne;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;

    cursor: pointer;
`;