import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { RenderContext } from "hooks/context";
import CustomButton from "components/Button/CustomButton";
import { INPUT_LABEL_VALUE, INPUT_VALUE } from "constants/CssConstant";
import * as S from "./HowtoGuideModal.style";
import { MediaType } from "util/media";
import CloseAlert from "components/alert/CloseAlert";
import { updateHowToGuide } from "apis/Admin/AdminApi";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
};

interface ModalProps {
    isOpen: boolean;
    isNew?: boolean;
    onClose: () => void;
    selectedData?: any;
    isMyEdit?: boolean;
}


export default function HowToGuideModal({ isNew, isOpen, onClose, selectedData, isMyEdit }: ModalProps) {

    // console.log(userInfo);
    const media = MediaType();
    const {
        isCancel,
        closeModal,
        renderAction,
        renderOpen,
        openCancel,
        closeCancel,
        closeUser,
        closeUserTable,
    } = useContext(RenderContext);

    const [inputLink, setInputLink] = useState<string>(selectedData[0]?.guideLink ? selectedData[0]?.guideLink : "");
    const [isAble, setIsAble] = useState<boolean>(false);

    console.log(selectedData, 'selectedData');

    const useStyles = makeStyles(theme => ({
        root: {
            "& .MuiFormLabel-root": INPUT_LABEL_VALUE,
            "& .MuiInputLabel-shrink": {
                fontSize: 20,
                lineHeight: 1.4
            },
            "& .MuiInput-input": {
                marginTop: 5
            },
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        if (inputLink != "" && inputLink != null) {
            setIsAble(true);
        } else {
            setIsAble(false);
        }
    }, [inputLink]);

    const requsetUpdate = async () => {
        if (isAble) {
            try {
                const inputresponse = await updateHowToGuide(selectedData[0].projectId, inputLink);
                console.log(inputresponse, 'inputresponse');
                renderAction();
                renderOpen();
                onClose();
                closeUser();
                closeUserTable();
                closeModal();
            } catch (e: any) {
                console.log(e, "input user error");
            }
        } else {
            return null;
        }
    };


    return (
        <div>
            <Modal open={isOpen} onClose={onClose}>
                <Box sx={style}>
                    <S.ContentBox>
                        <S.TitleSection>
                            Edit
                        </S.TitleSection>
                        <S.InputSection>
                            <TextField
                                variant="standard"
                                label={<>Download Link<span style={{color : "red"}}> *</span></>}
                                type="text"
                                className={classes.root}
                                value={inputLink || ''}
                                placeholder="Enter Download Link"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setInputLink(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "100%",
                                    input: INPUT_VALUE,
                                }}
                            />
                        </S.InputSection>
                        <S.BtnSection>
                            <CustomButton
                                title="SAVE"
                                classname={isAble ? "save-btn" : "disable-check"} //disable-check
                                icon={
                                    <></>
                                }
                                btnClick={() => requsetUpdate()}
                                Width="79"
                                Height="40"
                            />
                            <CustomButton
                                title="CANCEL"
                                classname="cancel-btn"
                                icon={
                                    <></>
                                }
                                Width="100"
                                Height="40"
                                btnClick={() => openCancel()} />
                        </S.BtnSection>
                    </S.ContentBox>

                    {isCancel
                        &&
                        <CloseAlert
                            isOpen={isCancel}
                            onClose={() => closeCancel()}
                        />
                    }
                </Box>
            </Modal>
        </div>
    );
}
