import { useRef, DragEvent, useEffect, useState, useCallback } from "react";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import MenuIcon from '@mui/icons-material/Menu';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import * as S from "../SettingModal.style";
import { OverFlowBox } from "pages/common.style";

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { DownloadFile } from "util/download";

interface AddSectionProps {
    imgData?: any;
    isMulti?: boolean;
    setData: React.Dispatch<React.SetStateAction<any>>;
    setReplaceImg: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AddSection = ({ imgData, isMulti, setData, setReplaceImg }: AddSectionProps) => {


    const showDownBtn = (fileName: string) => {
        if (imgData.existImgs.find((x: any) => x.File.originalName === fileName)) {
            return true;
        } else {
            return false;
        }
    };

    const deleteItem = (item: any) => {
        console.log(typeof (item), 'item');
        if (item.originalName != "undefined") {
            console.log(item.originalName, 'select exist');
            // exist
            setData({ ...imgData, uploadImgs: imgData.uploadImgs.filter((x: any) => x.name !== item.originalName), displayImgs: imgData.displayImgs.filter((x: any) => x.File.originalName !== item.originalName) });
            // setData({ ...imgData, existImgs: imgData.existImgs.filter((x: any) => x.originalName !== item.originalName), displayImgs: imgData.displayImgs.filter((x: any) => x.File.originalName !== item.originalName) });
        } else {
            console.log(item, 'select upload');
            // upload
            setData({ ...imgData, uploadImgs: imgData.uploadImgs.filter((x: any) => x.File.originalName !== item.name), displayImgs: imgData.displayImgs.filter((x: any) => x.File.originalName !== item.name) });
        }

        if (imgData.displayImgs.length === 0) {
            setReplaceImg(true);
        }
    };


    const [items, setItems] = useState({...imgData})

    useEffect(() => {
        console.log(imgData,'get imgData')
        setItems({...imgData})
    }, [imgData]);

    console.log(imgData, 'img');

    const onDragEnd = ({ source, destination }: DropResult) => {
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
        // console.log('>>> source', source);
        // console.log('>>> destination', destination);
        const _items = JSON.parse(JSON.stringify(items.displayImgs)) as typeof items;
        // console.log(_items,'tiem')
        const [targetItem] = _items.splice(source.index, 1);
        if (destination != null) {
            _items.splice(destination.index, 0, targetItem);
        } else {
            return null;
        }
        setItems(_items);
        // setData(_items)
        setData({...imgData, displayImgs : _items})
    };

    // --- requestAnimationFrame 초기화
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));

        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);

    if (!enabled) {
        return null;
    }

    return (
        <S.ImgArrayBox>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable 
                droppableId="droppable"
                >
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}
                        // style={{overflowX : "hidden",overflow : "scroll"}}
                        >
                            {/* {items.displayImgs?.map((item: any, index) => ( */}
                            {items?.displayImgs?.map((item: any, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                left: 'auto !important',
                                                top: 'auto !important',
                                            }}
                                        >
                                            <S.Boxtext>
                                                <MenuIcon
                                                    sx={{ flex: "1" }}
                                                    fontSize="small"
                                                />
                                                <OverFlowBox style={{ flex: 10 }}>
                                                    {/* {item.originalName ?? item.name} */}
                                                    {/* {item.originalName !="undefined" ? 

                                                    item.originalName : item.name} */}
                                                    {/* {item.File ? 
                                                    item.File.originalName : item.name
                                                    } */}
                                                    {item.File?.originalName}
                                                </OverFlowBox>

                                                <S.IconBox>
                                                    {!isMulti &&
                                                        showDownBtn(item.File.originalName) &&
                                                        <DownloadIcon
                                                            className="down-btn"
                                                            onClick={() => DownloadFile(item.File.url, item.File.originalName)}
                                                        />
                                                    }
                                                    <DeleteOutlineIcon
                                                        onClick={() => deleteItem(item.File)}
                                                        sx={{
                                                            flex: "1",
                                                            '&:hover': {
                                                                color: "#2196F3"
                                                            }
                                                        }}
                                                    />
                                                </S.IconBox>

                                            </S.Boxtext>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                
            </DragDropContext>
        </S.ImgArrayBox>
        // <></>
    );
};