import world from 'assets/world.png';
import { styled } from 'styled-components';
import Marker from './Marker';
import ChartBox from "../ChartBox";
import { useEffect, useState } from "react";
import { getWorldMap } from "apis/DashBoard/DashBoardApi";

function WorldMap() {

    const [worldMapData, setWorldMapData] = useState<any>();
    const [isinit, setIsinit] = useState<boolean>(true);

    const requsetData = async () => {
        const worldRes = await getWorldMap();
        setWorldMapData(worldRes);
        setIsinit(false);
    };

    useEffect(() => {
        requsetData();
    }, []);

    return (
        <ChartBox
            title="Worldmap"
            wd="40%"
            ht="530px"
            contents={
                <>
                    <Container>
                        <Img src={world} alt="Vite logo" />
                        <Marker data={worldMapData} />
                    </Container>
                </>
            }
        />

    );
}

export default WorldMap;

const Container = styled.div`
    position: relative;
    margin-top: 10%;
    width: 100%;
`;

const Img = styled.img`
    position: relative;
    width: 100%;
    /* height: 80%; */
    max-height: 300px;
    min-height: 200px;
    /* scale: 0.5; */
    opacity: 0.2;
`;
