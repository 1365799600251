import useIsMediaQuery from "hooks/useIsMediaQuery";

export function MediaType() {
  const $ismobile = useIsMediaQuery("MOBILE");
  const isTablet = useIsMediaQuery("TABLET");

  if ($ismobile) {
    return "MOBILE";
  } else if (isTablet) {
    return "TABLET";
  }

  return "PC";
}
