import styled from "styled-components";

export const MobileHeaderContainer = styled.div`
    /* position: fixed; */
    display: flex;
    /* justify-content: center; */
    /* justify-content: space-between; */
    
/* align-items: flex-end; */
    /* > Button {
        justify-content: flex-start;
    } */
`

export const MobileHeader = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color : #202020;

    font-family: Samsung Sharp Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
`