import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useRef, useState } from "react";

import { theme } from "constants/theme";
import styled from "styled-components";

const label = {
    inputProps: {
        'aria-label': 'Checkbox demo',
    }
};

interface Props {
    checklabel?: string;
    checkKey?: string;
    isChecked?: boolean;
    setValue?: Function;
    value?: any;
    data?: any;
    idx?: number;
}

export default function CustomCheckBox({ data, setValue }: Props) {


    const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");

    const [isChecked, setIsChecked] = useState(() =>
        data.map((i) => (
            {
                value: i.value,
                label: i.label,
                check: i.checked,
                visible: Object.entries(userInfo?.permission).find((x: any) => x[0] === i.value)[1]
            }
        ))
    );


    const isCheckboxChecked = (index, checked) => {
        setIsChecked((isChecked) => {
            return isChecked.map((c, i) => {
                if (i === index) return (
                    {
                        ...c,
                        check: checked
                    }
                );
                return c;
            });
        });
    };

    useEffect(() => {
        setValue(isChecked);
    }, [isChecked]);

    // console.log(userInfo, 'get user info');
    // console.log(data, 'get data');

    return (
        <CheckBoxWrapper>
            {data.map((checkbox, index) => {
                return (
                    <div key={index}
                    // hidden={checkbox.visible}
                    >
                        <FormControlLabel
                            disabled={!checkbox.visible}
                            className="twocolelement"
                            control={
                                <Checkbox
                                    name={checkbox.name}
                                    // value={checkbox.weight}
                                    // id={checkbox.category}
                                    checked={isChecked[index].check}
                                    color="primary"
                                    onChange={(e) => isCheckboxChecked(index, e.target.checked)}
                                />
                            }
                            label={
                                <label style={{
                                    color: "#202020",
                                    fontFamily: "SamsungOne",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "600"
                                }}>

                                    {checkbox.label}
                                </label>}
                        />
                    </div>
                );
            })}
        </CheckBoxWrapper>
    );
}

const CheckBoxWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    
    @media (${theme.MOBILE}) {
        flex-direction: column;
    }
`;