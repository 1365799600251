import { getConfigJson, getLanguageJson, postLanguageJson } from "apis/Language/LanguageApi";
import CustomTable from "components/CustomTable/CustomTable";
import PageTop from "components/PageTop/PageTop";
import { useContext, useEffect, useMemo, useState } from "react";
import TranslateIcon from '@mui/icons-material/Translate';
import * as S from "./Localization.style";
import { getMySubList, getProjectList, getTvList } from "apis/Admin/AdminApi";
import TableDropDown from "components/DropDown/TableDropDown";
import CustomButton from "components/Button/CustomButton";
import { RenderContext } from "hooks/context";
import { MediaType } from "util/media";
import { TmpText } from "pages/common.style";
import LocalizaionAlert from "components/alert/LocalizaionAlert";
import JSZip from "jszip";


export default function Localization() {

    const media = MediaType();

    const {
        isRender,
        renderAction
    } = useContext(RenderContext);

    const [rowData, setRowData] = useState<any>([]);
    const [localData, setLocalData] = useState<any>([]);
    const [isSaveAble, setIsSaveAble] = useState<boolean>(false);

    const [requestSub, setRequestSub] = useState<any>([]);
    const [dropSub, setDropSub] = useState<string>("");

    const [requestProject, setRequestProject] = useState<any>([]);
    const [dropProject, setDropProject] = useState<string>("");

    const [requestDevice, setRequestDevice] = useState<any>([]);
    const [dropDevice, setDropDevice] = useState<string>("");

    const [isErrorModal, setIsErrorModal] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<any>();

    const [getJson, setGetJson] = useState<any>();
    const [isError, setIsError] = useState<boolean>();
    const [errorTmp, setErrorTmp] = useState<any>();

    const [isExport,setIsExport] = useState<boolean>(true);

    const [downLoadName, setDownLoadName] = useState({
        sub: "",
        project: "",
        device: ""
    });

    const TableInput = (props) => {
        const { column, row, cell, data } = props;
        const [localValue, setLocalValue] = useState<any>(row.original.local);
        const [isError, setIsError] = useState<any>(row.original.isError);

        let count = 0;
        for (let i = 0; i < row.original.local.length; i++) {
            if (row.original.local[i] === "\n") {
                count++;
            }
        }
        const onChange = (e: any) => {
            setLocalValue(e.target.value);
            data.find((x: any) => x.key === row.original.key).local = e.target.value;
            setLocalData([...data]);
            setIsSaveAble(true);
        };
        return <S.InputArea value={localValue} onChange={(e) => onChange(e)} $count={count} $isError={isError} />;
    };

    const columns = useMemo(
        () => [
            {
                Header: "Key",
                accessor: "key",
                Cell: ({ row, value }) =>
                    <div style={{ fontSize: "14px", width: "120px", color: "#9F9F9F", whiteSpace: "normal", wordBreak: "break-all" }}>{row.original.key}</div>
            },
            {
                Header: <div style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "20px" }}>Message (Global)</div>,
                accessor: "global",
                Cell: ({ row, value }) =>
                    <div style={{ whiteSpace: "pre-wrap", marginLeft: "20px" }}>
                        {value}
                    </div>,
            },
            {
                Header: "Message (Local)",
                Cell: TableInput,
            },
        ],
        [rowData, isError]
    );


    const requestList = async (dropProject: string, dropSub: string, dropDevice: string) => {
        if(dropDevice === "MOBILE"){
            setIsExport(false)
        }else{
            setIsExport(true)
        }
        setIsSaveAble(false);
        try {
            const response = await getLanguageJson(dropProject, dropSub, dropDevice);
            // console.log(response,'req response')
            setRowData(Object.entries(response.languageJson)?.map((entrie) => {
                return { key: entrie[0], global: entrie[1][0], local: entrie[1][1], isError: false };
            }));
            setGetJson(Object.entries(response.languageJson)?.map((entrie) => {
                return { key: entrie[0], global: entrie[1][0], local: entrie[1][1] };
            }));
            setDownLoadName({ ...downLoadName, sub: dropSub, project: dropProject, device: dropDevice });
        } catch (e: any) {
            console.log(e, 'getList Error');
        }
    };

    const initRequestList = async () => {
        const subResponse = await getMySubList("");
        const projectResponse = await getProjectList();
        const deviceResponse = await getTvList(projectResponse[0]?.value);

        setRequestSub(subResponse);
        setDropSub(subResponse[0]?.value);

        setRequestProject(projectResponse);
        setDropProject(projectResponse[0]?.value);

        setRequestDevice(deviceResponse);
        setDropDevice(deviceResponse[0]?.value);

        requestList(projectResponse[0]?.value, subResponse[0]?.value, deviceResponse[0]?.value);

        setDownLoadName({ ...downLoadName, sub: subResponse[0]?.value, project: projectResponse[0]?.value, device: deviceResponse[0]?.value });
    };

    const changeProject = async () => {
        const deviceResponse = await getTvList(dropProject);
        setRequestDevice(deviceResponse);
        setDropDevice(deviceResponse[0]?.value);
    };

    useEffect(() => {
        initRequestList();
    }, []);

    useEffect(() => {
        if (dropProject && dropSub && dropDevice) {
            requestList(dropProject, dropSub, dropDevice);
        }
    }, [isRender]);

    useEffect(() => {
        if (dropProject) {
            changeProject();
        }
        // console.log(dropProject,'dropProject')
    }, [dropProject]);


    // useEffect(()=>{
    //     if(dropDevice === "MOBILE"){
    //         setIsExport(false)
    //     }else{
    //         setIsExport(true)
    //     }
    // },[dropDevice])

    const updataLocal = async () => {
        if (isSaveAble === false) {
            return;
        }
        try {
            const response = await postLanguageJson(localData, dropProject, dropSub, dropDevice);
            console.log(response, 'responseresponse');
            if (response.status === 200) {
                setIsErrorModal(true);
                setIsError(false);
                setErrorText("save complete");
                renderAction();
            } else {
                setIsErrorModal(true);
                setIsError(true);
                setErrorText("Please enter the content inside {{ }} exactly as it is.");
                setErrorTmp(response.response.data.message);
                const newData = rowData.map(item => {
                    if (response.response.data.message.includes(item.key)) {
                        return { ...item, isError: true };
                    } else {
                        return { ...item, isError: false };
                    }
                });
                setRowData(newData);
            }
        } catch (e: any) {
            console.log(e, 'updataLocalJson Error');
        }
    };


    const downloadJson = async () => {
        if(!isExport){
            return
        }
        const getConfig = await getConfigJson(downLoadName.project, downLoadName.sub);
        const zip = new JSZip();
        let obj = {};
        getJson.forEach((element) => {
            obj[element.key] = element.local;
        });

        zip.file("language.json", JSON.stringify(obj, null, 4));
        zip.file("config.json", JSON.stringify(getConfig, null, 4));
        zip.generateAsync({ type: "blob" }).then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${downLoadName.sub}_${downLoadName.project}_setting_files.zip`;
            link.click();
        });
    };

    return (
        <>
            {media === "MOBILE" ?
                <TmpText>
                    Localization is not supported on mobile devices. Please use a desktop.
                </TmpText>
                :
                <>
                    <PageTop
                        title="Localization"
                        icon={<TranslateIcon />}
                    />
                    <S.TableTop >
                        <TableDropDown
                            label="Subsidiary"
                            setValue={setDropSub}
                            onchange={() => null}
                            option={requestSub || []}
                            value={dropSub}
                            width={"180px"}
                        />
                        <TableDropDown
                            label="Project"
                            setValue={setDropProject}
                            onchange={() => null}
                            option={requestProject || []}
                            value={dropProject}
                            width={"310px"}
                        />
                        <TableDropDown
                            label="Device"
                            setValue={setDropDevice}
                            onchange={() => null}
                            option={requestDevice || []}
                            value={dropDevice}
                            width={"180px"}
                        />

                        <CustomButton
                            title="MOVE"
                            classname="move-btn"
                            icon={
                                <></>
                            }
                            btnClick={() => requestList(dropProject, dropSub, dropDevice)}
                            Width="85"
                            Height="40"
                        />

                        <div style={{ display: "flex", marginLeft: "auto", gap: "20px" }}>
                            <CustomButton
                                title="EXPORT"
                                classname={isExport ? "preview-btn" : "disable-check"}
                                icon={
                                    <></>
                                }
                                btnClick={() => downloadJson()}
                                Width="98"
                                Height="40"
                            />
                            <CustomButton
                                title="SAVE"
                                classname={isSaveAble ? "save-btn" : "disable-check"}
                                icon={
                                    <></>
                                }
                                btnClick={() => updataLocal()}
                                Width="79"
                                Height="40"
                            />
                        </div>
                    </S.TableTop>
                    {isErrorModal && <LocalizaionAlert isOpen={isErrorModal} onClose={() => setIsErrorModal(false)} contents={errorText} />}
                    <CustomTable data={rowData} columns={columns} />
                </>
            }
        </>
    );
}
