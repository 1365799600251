import { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import CustomButton from "components/Button/CustomButton";
import SettingDropDown from "components/DropDown/SettingDropDown";
import CustomDatePicker from "components/picker/CustomDatePicker";

import { RenderContext } from "hooks/context";

import { changeAllTvSetting } from "apis/TvStatus/TvStatusApi";

import {
  INPUT_DISABLED_VALUE,
  INPUT_MIXED_VALUE,
  INPUT_VALUE_TITLE,
} from "constants/CssConstant";
import { NPPTIME_MIXED } from "constants/NppLoopConstant";
import { IMAGETIME, IMAGETIME_MIXED } from "constants/ImageDurationConstant";

import * as S from "./SettingModal.style";
import { MediaType } from "util/media";
import { AddSection } from "./component/AddSection";
import CloseAlert from "components/alert/CloseAlert";
import { PROMOTION_OPTION_MIXED } from "constants/ContentConstant";
import LoadingAlert from "components/alert/LoadingAlert";
import { isImg, isVideo } from "util/MyRegExp";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 3,
  color: "#525457",
};

interface ModalProps {
  isOpen: boolean;
  isNew?: boolean;
  onClose: () => void;
  searchQuery?: string;
}

export default function SettingAllModal({
  isOpen,
  onClose,
  searchQuery,
}: ModalProps) {
  const userInfo = JSON.parse(localStorage.getItem("User") || "{}");
  console.log(userInfo, "userInfo");
  const media = MediaType();
  const {
    isCancel,
    renderAction,
    renderOpen,
    openCancel,
    closeCancel,
    closeAllNpp,
    closeAllNppTable,
  } = useContext(RenderContext);

  const [getNppValue, setNppValue] = useState<string>("0");
  const [getImgValue, setImgValue] = useState<string>("0");
  const [getTypeValue, setTypeValue] = useState<string>("0"); //todo

  // const [inputValue, setInputValue] = useState<string>(selectedData.length > 1 ? "0" : selectedData[0].imgDurationTime);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputEror, setInputEror] = useState<boolean>(false);

  const [isInput, setIsInput] = useState<boolean>(false);

  const [getStartDay, setStartDay] = useState<string>("0");
  const [getEndDay, setEndDay] = useState<string>("0");

  const [getPdp, setPdp] = useState<string>("Mixed");

  const [IsSelectedImg, setIsSelectedImg] = useState<boolean>(false);
  const [IsSelectedVideo, setIsSelectedVideo] = useState<boolean>(false);
  const [selectedArray, setSelectArray] = useState<any>([]);

  const [imgData, setImgData] = useState<any>({
    displayImgs: [],
    uploadImgs: [],
    existImgs: [],
  });

  const [videoData, setVideoData] = useState<any>({
    displayImgs: [],
    uploadImgs: [],
    existImgs: [],
  });

  const [isAble, setIsAble] = useState<boolean>(false);
  const [isAbleAction, setIsAbleAction] = useState<boolean>(true);

  const [replaceImg, setReplaceImg] = useState<boolean>(true);
  const [replaceVideo, setReplaceVideo] = useState<boolean>(true);

  console.log(getStartDay, getEndDay);

  const updateBody: any = {
    loopType: getNppValue === "0" ? undefined : getNppValue,
    imgDurationTime:
      getImgValue === "0"
        ? undefined
        : isInput
        ? Number(inputValue)
        : getImgValue,
    periodStartTime: getStartDay === "0" ? undefined : getStartDay,
    periodEndTime: getEndDay === "0" ? undefined : getEndDay,
    playType: getTypeValue === "0" ? undefined : getTypeValue,
    pdp: getPdp === "Mixed" ? undefined : getPdp,
  };

  // useEffect(()=>{
  //     if(getPdp === ""){
  //         setPdp("www.samsung.com")
  //     }
  // },[getPdp])

  useEffect(() => {
    if (getImgValue != null) {
      if (IMAGETIME.find((x) => x.value === getImgValue)) {
        setIsInput(false);
      } else {
        setInputValue("Mixed");
      }
    }
  }, []);

  useEffect(() => {
    if (getImgValue === "Input the time") {
      setIsInput(true);
    } else {
      setIsInput(false);
    }
  }, [getImgValue]);

  useEffect(() => {
    if (isInput) {
      if (inputValue === "" || inputValue === "Mixed") {
        setIsAble(false);
      } else {
        if (Number(inputValue) > 60) {
          setIsAble(false);
          setInputEror(true);
        } else {
          setIsAble(true);
          setInputEror(false);
        }
      }
    } else {
      setIsAble(true);
    }
  }, [imgData, updateBody, isInput]);

  const checkId = (data: any) => {
    let str = "";
    for (let i = 0; i < 3; i++) {
      str += Math.floor(Math.random() * 10);
    }
    if (data.displayImgs.includes(str)) {
      return str + 1;
    } else {
      return str;
    }
  };

  const selecedFile = (e: any) => {
    if (isImg.test(e.target.files[0].name)) {
      setReplaceImg(false);
      setIsSelectedImg(true);
      setImgData({
        ...imgData,
        uploadImgs: [...imgData.uploadImgs, e.target.files[0]],
        displayImgs: [
          ...imgData.displayImgs,
          {
            id: checkId(imgData),
            order: null,
            File: {
              originalName: e.target.files[0].name,
            },
            upload: true,
          },
        ],
      });
      setSelectArray([...selectedArray, e.target.files[0]]);
    } else {
      alert(
        "The file you provided is not an image file.\nPlease upload an image (JPG, JPEG, GIF, PNG,SVG)."
      );
    }
  };

  const selecedVideo = (e: any) => {
    if (isVideo.test(e.target.files[0].name)) {
      setReplaceVideo(false);
      setIsSelectedVideo(true);
      setVideoData({
        ...videoData,
        uploadImgs: [...videoData.uploadImgs, e.target.files[0]],
        displayImgs: [
          ...videoData.displayImgs,
          {
            id: checkId(videoData),
            order: null,
            File: {
              originalName: e.target.files[0].name,
            },
            upload: true,
          },
        ],
      });
      setSelectArray([...selectedArray, e.target.files[0]]);
    } else {
      alert(
        "The file you provided is not an video file.\nPlease upload an video (avi, wmv, mov, mp4, flv, asf)."
      );
    }
  };

  useEffect(() => {
    if (imgData?.displayImgs?.length === 0) {
      setIsSelectedImg(false);
      // console.log("emtp");
    }
  }, [imgData.displayImgs]);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormLabel-root": INPUT_DISABLED_VALUE,
      "& .MuiInputLabel-shrink": {
        fontSize: 22, //todo
        lineHeight: 1.4,
      },
      "& .MuiInput-input": {
        marginTop: 5,
      },
    },
  }));

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updataTvsetting = async () => {
    if (isAble) {
      if (isAbleAction) {
        setIsLoading(true);
        try {
          setTimeout(async () => {
            try {
              setIsAbleAction(false);
              const response = await changeAllTvSetting(
                updateBody,
                imgData,
                videoData,
                searchQuery
              );
              console.log(response, "tv update");
              if (response.isSuccess) {
                setIsLoading(false);
                renderAction();
                renderOpen();
                onClose();
                closeAllNpp();
                closeAllNppTable();
              } else {
                setIsAbleAction(true);
                setIsLoading(false);
                alert("TV & NPP Setting Error.\nPlease Check Setting");
              }
            } catch (e: any) {
              setIsAbleAction(true);
              setIsLoading(false);
              alert("TV & NPP Setting Error.\nPlease Check Setting");
            }
          }, 1500);
        } catch (e: any) {
          setIsAbleAction(true);
          setIsLoading(false);
          alert("TV & NPP Setting Error.\nPlease Check Setting");
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const showtooltip = () => {
    if (media !== "MOBILE") {
      return false;
    } else {
      if (IsSelectedImg) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    console.log(value, "dddd");
    if (/^\d*$/.test(value)) {
      setInputValue(value);
    }
  };

  return (
    <div>
      <Modal open={isOpen}>
        <Box sx={style}>
          <S.ContentBox height={userInfo?.Group.isDevmode ? "1000px" : "800px"}>
            {media === "MOBILE" ? null : (
              <>
                <S.Title>TV & NPP Setting</S.Title>
                <S.ContentSection height="27%" isInput>
                  <S.TitleSection>TV Information</S.TitleSection>

                  <S.ContentContainer>
                    {/* left */}
                    <S.SectionDivsion mgTop="10px">
                      <TextField
                        variant="standard"
                        label="Subsidary"
                        type="text"
                        className={classes.root}
                        // value={selectedData[0]?.subsidiary}
                        value={"Mixed"}
                        sx={{
                          width: "100%",
                          input: INPUT_MIXED_VALUE,
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#707070",
                          },
                          //   "& .MuiInputLabel-root": { color: "green" },
                          borderBottom: "1px solid black",
                        }}
                        aria-readonly
                        disabled
                        InputProps={{ disableUnderline: true }}
                      />
                      <TextField
                        variant="standard"
                        label="Store"
                        type="text"
                        className={classes.root}
                        value={"Mixed"}
                        sx={{
                          width: "100%",
                          input: INPUT_MIXED_VALUE,
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#707070",
                          },
                          //   "& .MuiInputLabel-root": { color: "green" },
                          borderBottom: "1px solid black",
                          marginTop: "15px",
                        }}
                        aria-readonly
                        disabled
                        InputProps={{ disableUnderline: true }}
                      />
                      <TextField
                        variant="standard"
                        label="Product Detail Page"
                        type="text"
                        className={classes.root}
                        placeholder="https://www.samsung.com/sec/tvs"
                        value={getPdp}
                        onClick={() => {
                          if (getPdp === "Mixed") {
                            setPdp("");
                          }
                        }}
                        onChange={(e) => setPdp(e.target.value)}
                        onBlur={() => {
                          if (getPdp === "") {
                            setPdp("https://www.samsung.com/sec/tvs");
                          }
                        }}
                        sx={{
                          width: "100%",
                          input: INPUT_MIXED_VALUE,
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#202020",
                          },
                          borderBottom: "1px solid black",
                          marginTop: "15px",
                        }}
                        InputProps={{
                          disableUnderline: true,
                          inputProps: { style: { color: "#202020" } },
                        }}
                        InputLabelProps={{
                          style: { color: "#525457" },
                        }}
                      />
                    </S.SectionDivsion>

                    <S.SectionDivsion mgTop="10px">
                      <TextField
                        variant="standard"
                        label="Retailer"
                        type="text"
                        className={classes.root}
                        value={"Mixed"}
                        sx={{
                          width: "100%",
                          input: INPUT_MIXED_VALUE,
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#707070",
                          },
                          //   "& .MuiInputLabel-root": { color: "green" },
                          borderBottom: "1px solid black",
                        }}
                        aria-readonly
                        disabled
                        InputProps={{ disableUnderline: true }}
                      />
                      <TextField
                        variant="standard"
                        label="TV Name"
                        type="text"
                        className={classes.root}
                        value={"Mixed"}
                        sx={{
                          width: "100%",
                          input: INPUT_MIXED_VALUE,
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#707070",
                          },
                          //   "& .MuiInputLabel-root": { color: "green" },
                          borderBottom: "1px solid black",
                          marginTop: "15px",
                        }}
                        aria-readonly
                        disabled
                        InputProps={{ disableUnderline: true }}
                      />
                    </S.SectionDivsion>
                  </S.ContentContainer>
                </S.ContentSection>
              </>
            )}

            <S.ContentSection
              height={userInfo?.Group.isDevmode ? "50%" : "350px"}
              isInput
            >
              <S.TitleSection>NPP Information</S.TitleSection>

              <S.ContentContainer>
                {/* left */}
                <S.SectionDivsion>
                  {media === "MOBILE" && (
                    <TextField
                      variant="standard"
                      label="Product Detail Page"
                      type="text"
                      className={classes.root}
                      placeholder="https://www.samsung.com/sec/tvs"
                      value={getPdp}
                      onClick={() => {
                        if (getPdp === "Mixed") {
                          setPdp("");
                        }
                      }}
                      onChange={(e) => setPdp(e.target.value)}
                      onBlur={() => {
                        if (getPdp === "") {
                          setPdp("https://www.samsung.com/sec/tvs");
                        }
                      }}
                      sx={{
                        width: "100%",
                        input: INPUT_MIXED_VALUE,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#202020",
                        },
                        borderBottom: "1px solid black",
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        inputProps: { style: { color: "#202020" } },
                      }}
                      InputLabelProps={{
                        style: { color: "#525457" },
                      }}
                    />
                  )}

                  <SettingDropDown
                    label="NPP Loop Type"
                    setValue={setNppValue}
                    option={NPPTIME_MIXED}
                    value={getNppValue}
                    isPoint
                    isMixed={true}
                    width={media === "MOBILE" ? "100%" : "100%"}
                  />
                  <S.SubTitle mgTop="15px">
                    <Typography sx={{ color: INPUT_VALUE_TITLE }}>
                      Promotion Period<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <CustomDatePicker
                      startDay={getStartDay}
                      endDay={getEndDay}
                      setStartDay={setStartDay}
                      setEndDay={setEndDay}
                      isMixed={true}
                    />
                  </S.SubTitle>

                  <SettingDropDown
                    label="Promotion Contents Duration Time"
                    setValue={setImgValue}
                    option={IMAGETIME_MIXED}
                    value={getImgValue}
                    isMixed={true}
                    isPoint
                    width={media === "MOBILE" ? "100%" : "100%"}
                    mgTop="20px"
                  />
                  {isInput && (
                    <TextField
                      variant="standard"
                      placeholder={"Input the time in seconds"}
                      type="text"
                      className={classes.root}
                      value={inputValue}
                      sx={{
                        width: "100%",
                        input: INPUT_MIXED_VALUE,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#202020",
                        },
                        marginTop: "15px",
                      }}
                      aria-readonly
                      InputProps={{ disableUnderline: false }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => handleChange(e)}
                      onClick={() => setInputValue("")}
                      error={inputEror}
                      helperText={inputEror && "Input until 60"}
                    />
                  )}
                </S.SectionDivsion>

                {/* right */}
                <S.SectionDivsion>
                  <SettingDropDown
                    label="Promotion Contents Option"
                    setValue={setTypeValue}
                    option={PROMOTION_OPTION_MIXED}
                    value={getTypeValue}
                    isPoint
                    isMixed={true}
                    width={media === "MOBILE" ? "100%" : "100%"}
                  />

                  <S.SubTitle mgTop="15px">
                    <div style={{ display: "flex", position: "relative" }}>
                      <div style={{ height: "30px" }}>
                        <Typography sx={{ color: INPUT_VALUE_TITLE }}>
                          Promotion Image Files
                          {/* Promotion Image Files<span style={{ color: "red" }}> *</span> */}
                        </Typography>
                      </div>

                      <label
                        htmlFor="upload-photo"
                        style={{ marginLeft: "auto" }}
                      >
                        <input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          onChange={(e) => selecedFile(e)}
                          accept=".jpg, .jpeg, .png, .svg .gif"
                        />
                        <Tooltip
                          title={
                            <span style={{ fontSize: "12px" }}>
                              When adding new files
                              <br />
                              existing ones will be deleted.
                            </span>
                          }
                          arrow
                          open={showtooltip()}
                        >
                          <S.ADDTEXT>ADD FILES</S.ADDTEXT>
                        </Tooltip>
                      </label>
                    </div>

                    {media === "MOBILE" ? (
                      <></>
                    ) : (
                      <span style={{ fontSize: "14px", color: "#FF7800" }}>
                        When adding new files,existing ones will be deleted.
                      </span>
                    )}

                    <S.ADDsection>
                      {imgData?.displayImgs?.length > 0 ? (
                        <>
                          <AddSection
                            imgData={imgData}
                            isMulti={true}
                            setData={setImgData}
                            setReplaceImg={setReplaceImg}
                          />
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              color: INPUT_MIXED_VALUE,
                              fontSize: "24px",
                            }}
                          >
                            Mixed
                          </Typography>
                        </>
                      )}
                    </S.ADDsection>
                  </S.SubTitle>
                  {userInfo?.Group.isDevmode && (
                    <S.SubTitle mgTop="10px">
                      <div style={{ display: "flex", position: "relative" }}>
                        <div style={{ height: "30px" }}>
                          <Typography sx={{ color: INPUT_VALUE_TITLE }}>
                            Promotion Content Files
                          </Typography>
                        </div>
                        <label
                          htmlFor="upload-video"
                          style={{ marginLeft: "auto" }}
                        >
                          <input
                            style={{ display: "none" }}
                            id="upload-video"
                            name="upload-video"
                            type="file"
                            onChange={(e) => selecedVideo(e)}
                            accept="video/*"
                          />
                          <Tooltip
                            title={
                              <span style={{ fontSize: "12px" }}>
                                When adding new files
                                <br />
                                existing ones will be deleted.
                              </span>
                            }
                            arrow
                            open={showtooltip()}
                          >
                            <S.ADDTEXT style={{ color: "#888888" }}>
                              ADD FILES
                            </S.ADDTEXT>
                          </Tooltip>
                        </label>
                      </div>

                      {media === "MOBILE" ? (
                        <></>
                      ) : (
                        <span style={{ fontSize: "14px", color: "#FF7800" }}>
                          When adding new files,existing ones will be deleted.
                        </span>
                      )}

                      <S.ADDsection>
                        {videoData?.displayImgs?.length > 0 ? (
                          <>
                            <AddSection
                              imgData={videoData}
                              isMulti={true}
                              setData={setVideoData}
                              setReplaceImg={setReplaceVideo}
                            />
                          </>
                        ) : (
                          <Typography
                            sx={{
                              color: INPUT_MIXED_VALUE,
                              fontSize: "24px",
                            }}
                          >
                            Mixed
                          </Typography>
                        )}
                      </S.ADDsection>
                    </S.SubTitle>
                  )}
                </S.SectionDivsion>
              </S.ContentContainer>
            </S.ContentSection>

            <S.BtnSection mgTop={userInfo?.Group.isDevmode ? "280px" : "60px"}>
              <CustomButton
                title="SAVE"
                classname={isAble ? "save-btn" : "disable-check"} //disable-check
                icon={<></>}
                btnClick={() => updataTvsetting()}
                Width="79"
                Height="40"
              />
              <CustomButton
                title="CANCEL"
                classname="cancel-btn"
                icon={<></>}
                Width="100"
                Height="40"
                // btnClick={() => confirmOpen()} />
                btnClick={() => openCancel()}
              />
            </S.BtnSection>
          </S.ContentBox>
          {isCancel && (
            <CloseAlert isOpen={isCancel} onClose={() => closeCancel()} />
          )}
          {isLoading && (
            <LoadingAlert
              isOpen={isLoading}
              onClose={() => setIsLoading(false)}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
