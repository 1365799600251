export const DownloadFile = (url : string,fileName : string) => {
    console.log(url,fileName)
    // url = ""
    fetch(url, { method: 'GET' })
        .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 60000);
            a.remove();
            // setOpen(false);
        })
        .catch((err) => {
            console.error('err: ', err);
        });
};

export const DownloadLink = (donwlink : string , name : string) => {
    const blob = new Blob([donwlink], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  