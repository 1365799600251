import styled from "styled-components";

const local1920 = '1920px';
const local1513 = '1513px';

export const ContentBox = styled.div<{ height?: string; }>`
    width: 846px;
    height: 969px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > img {
        margin-top: 18px;
        margin-left: 10px;
    }

    @media (max-width: ${local1920}) {
        width: 734px;
        height: 940px;
        > img {
            margin-top: 18px;
            margin-left: 20px;
        }
    }

    @media (max-width: ${local1513}) {
        width: 606px;
        height: 790px;
        > img {
            margin-top: 16px;
            margin-left: 10px;
        }
    }
`;

export const NotiTitle = styled.div`
    margin-left: 24px;
    margin-top: 44px;
    color: #000;
    text-align: center;
    font-family: "Samsung Sharp Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 666;
    line-height: normal;
    white-space: nowrap;

    @media (max-width: ${local1920}) {
        margin-left: 24px;
        font-size: 22px;
    }

    @media (max-width: ${local1513}) {
        margin-left: 24px;
        font-size: 18px;
    }
`;

export const NotiSubTItle = styled.div`
    text-align: left;
    margin-top: 8px;
    color: #F00;
    font-family: "SamsungOne";
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 19px;

    @media (max-width: ${local1920}) {
        font-size: 14px;
    }

    @media (max-width: ${local1513}) {
        font-size: 12px;
    }
`;

export const NumberTitle = styled.div`
    margin-top: 32px;
    margin-left: 28px;
    color: #00C2FF;
    font-family: "SamsungOne";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 19px;

    @media (max-width: ${local1920}) {
        font-size: 24px;
    }

    @media (max-width: ${local1513}) {
        font-size: 18px;
    }
`;

export const DesText = styled.div`
    margin-left: 24px;
    margin-top: 42px;
    color: #000;
    font-family: "SamsungOne";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    > div {
        color: #F00;
        font-family: "SamsungOne";
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 22px;
    }

    @media (max-width: ${local1920}) {
        font-size: 14px;
        margin-left: 24px;
        > div {
            font-size: 16px;
        }
    }

    @media (max-width: ${local1513}) {
        font-size: 12px;
        margin-left: 24px;

        > div {
            font-size: 14px;
        }
    }
`;

export const MobileImgBox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-left: 24px;
    margin-top: 12px;
`;

export const BtnBox = styled.div`
    width: 95%;
    margin-top: 22px;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${local1920}) {
        margin-left: 25px;
        width: 93%;
    }
    
    @media (max-width: ${local1513}) {
        margin-left: 25px;
        width: 90%;
    }
`;