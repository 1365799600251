import styled from "styled-components";
interface Props {
    width?: string;
    height?: string;
}

export const CustomBtn = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    gap : 5px;
    z-index: 999;
    font-family: SamsungOne;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 160% */

    min-width: 80px;
    padding : 10px;
    border-radius : 5px;

    width: ${props => `${props.width}px`};
    height: ${props => `${props.height}px`};

    font-size : 20px;
    color : #2196f3;

    cursor: pointer;

    &:hover {
    transition: all 0.5s;
    background-color: #aedafdc1;
    }

    &.qrdown-btn {
        font-family: SamsungOne;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }

    &.black-btn {
    color : black;

    &:hover {
        transition: all 0.5s;
        background-color: #a5a9aac1;
    }
    }

    &.setting-btn {
        background-color: #2196f3;
        color : white;
    }

    &.disable {
        background-color: #e8e8e8;
        color : black;
    }

    &.save-btn {
        background-color : #2196F3;
        color : #fff;
        font-family: SamsungOne;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 160% */
        text-transform: uppercase;
    }

    &.save-btn-disable {
        background-color: #e8e8e8;
        color : black;
        font-family: SamsungOne;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 160% */
        text-transform: uppercase;
    }

    &.cancel-btn {
        /* background-color : rgba(0, 0, 0, 0.12); */
        border : 1px solid rgba(0, 0, 0, 0.12);
        color : rgba(0, 0, 0, 0.38);
        font-family: SamsungOne;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 160% */
        text-transform: uppercase;
    }

    &.preview-btn {
        border : none;
        background-color : rgba(33, 150, 243, 0.04);
        color : #2196F3;
        font-family: SamsungOne;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 160% */
        text-transform: uppercase;
    }

    
    &.disable-check{
        background-color : rgba(0, 0, 0, 0.12);
        color : rgba(0, 0, 0, 0.38);
        font-family: SamsungOne;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 160% */
        text-transform: uppercase;
        cursor: auto;
    }

    &.move-btn{
        border : 1px solid #2196F3; 
        color : #2196F3;
        font-family: SamsungOne;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 160% */
        text-transform: uppercase;
        cursor: pointer;
    }
    `;
