import styled from "styled-components";

export const InputArea = styled.textarea<{ $count?: number, $isError? : boolean}>`
    min-width: 774px;
    width: 100%;
    height : ${(props) => props.$count === 0 ? "50px" : props.$count === 1 ? `calc(40px + ${props.$count * 34}px)` : `calc(40px + ${props.$count * 28}px)`};
    border: 1px solid ${props => (props.$isError ? 'red' : 'rgba(0, 0, 0, 0.23)')};
    border-radius: 4px;
    padding : 12px;
    outline : none;
    resize: none;
`;

export const TableTop = styled.div`
    display: flex;
    gap : 20px;
    margin-top: 16px;
    margin-bottom: 32px;
`
