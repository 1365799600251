import { deleteData, getData, patchData, patchDatadircet, postData, postImgData } from "../apiCore";

const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");

export const getUserList = async () => {
    const response = await getData(`/users`);
    const temp = [];
    // console.log(userInfo,'userInfos')
    if (response.isSuccess) {
        // console.log(response, 'response');
        for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].loginId != userInfo?.name) {
                temp.push({
                    id: response.data.data[i].id,
                    name: response.data.data[i].loginId,
                    subsidiary: response.data.data[i].Subsidiary?.code,
                    use: response.data.data[i].Status.name === "active" ? true : false,
                    // authority: response.data.data[i].Role.name,
                    information: response.data.data[i].information,
                    group: response.data.data[i].Group.name,
                    permission: response.data.data[i].Permission,
                });
            }
            // else {
            //     console.log(userInfo?.name,'userInfo?.name')
            // }
        }
        console.log(temp, 'temp');
        return temp;
    } else {
        return response;

    }
};

export const getMyUserList = async (text?: string, page?: number, take?: number) => {
    const response = await getData(`/users/me/users?sort=-id&searchQuery=${text}&page=${page}&take=${take}`);
    const temp = [];
    if (response.isSuccess) {
        for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].loginId != userInfo?.name) {
                temp.push({
                    id: response.data.data[i].id,
                    name: response.data.data[i].loginId,
                    subsidiary: response.data.data[i].Subsidiary?.code,
                    use: response.data.data[i].Status.name === "active" ? true : false,
                    // authority: response.data.data[i].Role.name,
                    information: response.data.data[i].information,
                    group: response.data.data[i].Group.name,
                    permission: response.data.data[i].Permission,
                });
            }
        }
        console.log(temp, 'temp');
        return { rowData: temp, pageData: response.data.meta };
    } else {
        return response;

    }
};



export const getMySubListAll = async () => {
    const response = await getData(`/users/me/subsidiarys`);
    const temp = [];
    if (response.isSuccess) {
        // console.log(response, 'response');
        for (let i = 0; i < response?.data?.length; i++) {
            temp.push({
                idx: i,
                key: response?.data[i].code,
                value: response?.data[i].code,
            });
        }
        temp.unshift({ idx: 9999, key: "ALL", value: "ALL" });
        console.log(temp, 'temp');
        return temp;
    } else {
        return response;
    }
};

export const getMySubList = async (groupName?: string, isALL?: boolean) => {
    const response = await getData(`/users/me/subsidiarys?groupName=${groupName}`);
    const temp = [];
    if (response.isSuccess) {
        // console.log(response, 'response');
        for (let i = 0; i < response?.data?.length; i++) {
            temp.push({
                idx: i,
                key: response?.data[i].code,
                value: response?.data[i].code,
            });
        }
        if (isALL) {
            temp.unshift({ idx: 9999, key: "ALL", value: "ALL" });
            console.log(temp, 'temp');
        }
        // console.log(temp, 'temp');
        return temp;
    } else {
        return response;
    }
};

export const getSubList = async (group?: string) => {
    const response = await getData(`/subsidiarys?groupName=${group}`);
    const temp = [];
    if (response.isSuccess) {
        // console.log(response, 'response');
        for (let i = 0; i < response.data.length; i++) {
            temp.push({
                idx: i,
                key: response.data[i].code,
                value: response.data[i].code,
            });
        }
        console.log(temp, 'temp');
        return temp;
    } else {
        return response;
    }
};
export const getMyGroupList = async () => {
    const response = await getData(`/users/me/groups`);
    const temp = [];
    if (response.isSuccess) {
        console.log(response, 'response my gruop');
        for (let i = 0; i < response.data.length; i++) {
            temp.push({
                idx: i,
                key: response.data[i].name,
                value: response.data[i].name,
            });
        }
        console.log(temp, 'temp');
        return temp;
    } else {
        return response;
    }
};

export const getGroupList = async () => {
    const response = await getData(`/groups`);
    const temp = [];
    if (response.isSuccess) {
        // console.log(response, 'response');
        for (let i = 0; i < response.data.length; i++) {
            temp.push({
                idx: i,
                key: response.data[i].name,
                value: response.data[i].name,
            });
        }
        console.log(temp, 'temp');
        return temp;
    } else {
        return response;
    }
};

export const getProjectList = async () => {
    const response = await getData(`/projects/me`);
    const temp = [];
    if (response.isSuccess) {
        for (let i = 0; i < response?.data?.length; i++) {
            temp.push({
                idx: i,
                key: response.data[i].projectName,
                value: response.data[i].projectName,
            });
        }
        return temp;
    } else {
        return response;
    }
};

export const getTableProjectList = async (page?: number, take?: number, text?: string,isGuide? : boolean) => {
    const response = await getData(`/projects/detail?page=${page}&take=${take}&searchQuery=${text}&sort=projectId&isGuide=${isGuide}`);
    console.log(response, 'response how to');
    const temp = [];
    if (response.isSuccess) {
        for (let i = 0; i < response?.data?.data?.length; i++) {
            temp.push({
                id: i,
                projectId: response.data.data[i].id,
                name: response.data.data[i].projectName,
                guideLink: response.data.data[i]?.guideDownloadLink,
                packageLink: response.data.data[i]?.packageDownloadLink,
                guideUpdate: response.data.data[i]?.guideUpdatedAt,
                packageUpdate: response.data.data[i]?.packageUpdatedAt,
                version: response.data.data[i]?.packageVersion,
            });
        }
        return { rowData: temp, pageData: response.data.meta };
    } else {
        return response;
    }
};

export const getTvList = async (projectName: string) => {
    const response = await getData(`/projects/deviceType?projectName=${projectName}`);
    // console.log(response, 'response project');
    const temp = [];
    if (response.isSuccess) {
        for (let i = 0; i < response?.data?.length; i++) {
            temp.push({
                idx: i,
                key: response.data[i].type.toUpperCase(),
                value: response.data[i].type.toUpperCase(),
            });
        }
        return temp;
    } else {
        return response;
    }
};

export const getRegion = async () => {
    const response = await getData(`/regions`);
    const temp = [];
    if (response.isSuccess) {
        console.log(response, 'response');
        for (let i = 0; i < response.data.length; i++) {
            temp.push({
                idx: i,
                key: response.data[i].code,
                value: response.data[i].code,
            });
        }
        console.log(temp, 'temp');
        return temp;
    } else {
        return response;
    }
};

export const getTimezone = async () => {
    const response = await getData(`/timezones`);
    const temp = [];
    if (response.isSuccess) {
        console.log(response, 'response');
        for (let i = 0; i < response.data.length; i++) {
            temp.push({
                idx: i,
                key: response.data[i].name,
                value: response.data[i].id,
            });
        }
        console.log(temp, 'temp');
        return temp;
    } else {
        return response;
    }
};

export const getSubTableList = async (order?: string, text?: string, page?: number, take?: number) => {
    const response = await getData(`/users/me/subsidiarys?sort=${order}&searchQuery=${text}&page=${page}&take=${take}`);
    const temp = [];
    if (response.isSuccess) {
        console.log(response.data.data, 'response');
        for (let i = 0; i < response.data.data.length; i++) {
            temp.push({
                id: response.data.data[i].id,
                subsidiary: response.data.data[i].code,
                region: response.data.data[i].Region.code,
                information: response.data.data[i].information,
                updatedAt: response.data.data[i].updatedAt,
                createdAt: response.data.data[i].createdAt,
                group: response.data.data[i].Group.name,
                magazine: response.data.data[i].magazineLink,
                smartThings: response.data.data[i].smartthingsLink,
                pdpLink: response.data.data[i].pdpLink,
                timezone: response.data.data[i].Timezone.id
            });
        }
        console.log(temp, 'temp');
        return { rowData: temp, pageData: response.data.meta };
    } else {
        return response;
    }
};

export const inPutUser = async (inputData: any, permission?: any) => {
    console.log(permission, 'permission');
    let tmp = {
        ...inputData,
        "Permissions": {
            "createUser": permission?.find((x: any) => x.value === "createUser").check,
            "createSubsidiary": permission?.find((x: any) => x.value === "createSubsidiary").check,
            // "developerMode": permission?.find((x: any) => x.value === "developerMode").check,
            "modifyLocalization": permission?.find((x: any) => x.value === "modifyLocalization").check,
            "viewAllSubsidiary": permission?.find((x: any) => x.value === "viewAllSubsidiary").check,
        }
    };

    const response = await postData(`/users`,
        permission ?
            tmp
            :
            inputData
    );
    return response;
};

export const updateUser = async (id: string, inputData: any, permission?: any) => {
    console.log(permission, 'update');
    let tmp = {
        ...inputData,
        "Permissions": {
            "createUser": permission?.find((x: any) => x.value === "createUser").check,
            "createSubsidiary": permission?.find((x: any) => x.value === "createSubsidiary").check,
            // "developerMode": permission?.find((x: any) => x.value === "developerMode").check,
            "modifyLocalization": permission?.find((x: any) => x.value === "modifyLocalization").check,
            "viewAllSubsidiary": permission?.find((x: any) => x.value === "viewAllSubsidiary").check,
        }
    };

    const response = await patchData(`/users/${id}`,
        permission ?
            tmp
            :
            inputData
    );
    return response;
};

export const updateMe = async (inputData: any) => {
    const response = await patchDatadircet(`/auth/me`,
        inputData
    );
    return response;
};

export const deleteUser = async (id: string) => {
    const response = await deleteData(`/users/${id}`,
    );
    return response;
};

export const inPutSub = async (inputData: any) => {
    const response = await postData(`/subsidiarys`,
        inputData
    );
    return response;
};

export const updateSub = async (id: string, inputData: any) => {
    const response = await patchData(`/subsidiarys/${id}`,
        inputData
    );
    return response;
};

export const deleteSub = async (id: string) => {
    const response = await deleteData(`/subsidiarys/${id}`,
    );
    return response;
};

export const updateHowToGuide = async (id: string, guide: string) => {
    const response = await patchData(`/projects/detail/guide/${id}`,
        {
            "guideDownloadLink": guide,
        }
    );
    return response;
};

export const updatePakage = async (id: string, packageLink: string, version?: string) => {
    const response = await patchData(`/projects/detail/package/${id}`,
        {
            "packageDownloadLink": packageLink,
            "packageVersion": version
        }
    );
    return response;
};

