import ChartBox from "./ChartBox";
import Chart from "react-apexcharts";
import { ChartProps } from "./ChartInterface";
import { useEffect, useState } from "react";
import ExportBtn from "components/ExportBtn/ExportBtn";
import { CHART_COLORS } from "constants/ChartColor";
import Tooltip from '@mui/material/Tooltip';
import Info from 'assets/info.svg';


export default function StatusAppStack({ getData, startDate, endDate, dropSub }: ChartProps) {

    const [series, setSeries] = useState<any>([]);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        if (getData) {
            setSeries(getData?.appname.map((x: any) => ({
                name: x,
                data: getData?.content[x].map((v: any) => v.value),
                sub: getData?.content[x].map((v: any) => v.key)
            })));
        }

    }, [getData]);


    const data: any = {
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    // borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: '14px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            xaxis: {
                // categories: series ? series[0]?.sub : []
                categories: getData ? getData?.subsidiarys : [],
                labels: {
                    rotate: -45,
                    rotateAlways: false,
                    style: {
                        // colors: colors,
                        fontSize: '14px',
                        fontWeight: 500,
                        color: "#000",
                        fontFamily: "Samsung Sharp Sans"
                    }
                }
            },
            legend: {
                position: 'right',
                offsetY: 40
            },
            fill: {
                opacity: 1
            },
            colors: CHART_COLORS
        },
    };

    const tooltipStyle = {
        listStyleType: 'disc',
        margin: 0,
        lineHeight: '16px',
        fontSize: '10px',
        fontStyle : "normal",
        fontWeight : "600",
        fontFamily : "Samsung Sharp Sans",
    };

    return (
        <>
            <ChartBox
                title={
                    <div style={{position : "relative"}}>
                        Status of New App Installations
                        <Tooltip
                           componentsProps={{
                            tooltip: {
                                sx: {
                                    width: 'auto',
                                    maxWidth: '600px',
                                }
                            }
                        }}
                        placement="bottom" 
                        arrow
                        title={
                        <div style={tooltipStyle}>
                            <li>Installing one app, and then installing a different app also counts towards the installation count.</li>
                            <li>Also includes TVs deleted from the console page.</li>
                        </div>} >
                            <img src={Info}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                style={{ position : "absolute", cursor: 'pointer', width : "18px", top : 1,marginLeft : "5px"}}
                            />
                        </Tooltip>
                    </div>
                }
                wd="50%"
                ht="500px"
                Topcontents={
                    <ExportBtn
                        startDate={startDate}
                        endDate={endDate}
                        subCode={dropSub}
                        type="appstatus"
                    />
                }
                contents={
                    <Chart
                        options={data.options}
                        // series={[]}
                        series={series || []}
                        type="bar"
                        width="100%"
                        height="390"
                    />
                } />
        </>
    );
}
