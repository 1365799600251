import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RenderProvider } from "hooks/context";
import DrawerLeft from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Logout from "components/alert/LogoutAlert";

export const PrivateRoute = (): React.ReactElement => {


  if (localStorage.getItem("token")) {
    return <>
      <RenderProvider>
        <DrawerLeft>
          <Outlet />
          <Logout />
        </DrawerLeft>
        {/* <Footer /> */}
      </RenderProvider>
    </>;
  } else {
    return <Navigate to="/" />;
  }
};