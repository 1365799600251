import { theme } from "constants/theme";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 3000px;
  min-width: 330px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (${theme.MOBILE}) {
    border: none;
    box-shadow: none;
  }
`;
export const TableTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  /* border : 1px solid #e8e8e8; */
  margin-bottom: 5px;

  @media (${theme.MOBILE}) {
    flex-direction: column;
    border: none;
  }
`;

export const BtnWrapper = styled.div`
  height: inherit;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (${theme.MOBILE}) {
    justify-content: right;
    margin-bottom: 10px;
    margin-top: 32px;
  }
`;
