import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";

import { DROP_MENU_VALUE, INPUT_LABEL_VALUE, INPUT_MIXED_VALUE, INPUT_VALUE } from "constants/CssConstant";
import { MediaType } from "util/media";
interface SettingDropDownProps {
    label?: string;
    option?: any;
    defaultValue?: string;
    value: string;
    setValue: Function;
    isPoint?: boolean;
    isMixed?: boolean;
    isPosition?: boolean;
    isUser?: boolean;
    isMyEdit?: boolean;
    width?: string;
    isDisable?: boolean;
    mgTop?: string;
}

export default function SettingDropDown({ label, option, value, setValue, isPoint, isMixed, isUser, isMyEdit, width, isDisable, mgTop }: SettingDropDownProps) {
    const media = MediaType();

    const handleChange = (e: any) => {
        setValue(e.target.key);
        console.log(e.target.value);
    };

    const defaultCheck = (getValue: any) => {
        if (isMixed) {
            return 0;
        } else {
            return option.length > 0 && option?.find((x: any) => x.key === getValue)?.value;
        }
    };

    const useStyles = makeStyles(theme => ({
        menuPaper: {
            maxHeight: 350
        }
    }));

    const classes = useStyles();

    const mixedDropDown = (value: any) => {
        return INPUT_MIXED_VALUE;
    };

    return (
        <FormControl size="medium" variant="standard" sx={{ width: width, marginTop: mgTop }}>
            <InputLabel
                id="select-label"
                sx={{
                    color: INPUT_LABEL_VALUE,
                    "&.MuiInputLabel-shrink": {
                        fontSize: 20,
                        lineHeight: 1.4
                    },
                }}
            >
                {label}
                {isPoint &&
                    <span style={{ color: "red" }}> *</span>
                }
            </InputLabel>
            <Select
                disabled={isMyEdit ? true : isDisable ? true : false}
                className="test"
                labelId="select-label"
                id="select-demo"
                value={value}
                label={label}
                onChange={(e: any) => setValue(e.target.value)}
                defaultValue={defaultCheck(value)}
                // defaultValue={value}
                sx={{
                    // color: isMixed ? mixedDropDown(value) : INPUT_LABEL_VALUE,
                    color: INPUT_MIXED_VALUE,
                    "& .MuiSelect-select": {
                        marginTop: isUser ? 0.65 : 1
                    }
                }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
                {option.length > 0 && option?.map((x: any, idx: number) => (
                    <MenuItem
                        value={x.value}
                        key={idx}
                        sx={{ color: DROP_MENU_VALUE, p: 2 }}
                    >
                        {x.key}
                    </MenuItem>
                ))}
            </Select>

        </FormControl>

    );

}