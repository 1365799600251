import { theme } from "constants/theme";
import styled from "styled-components";

export const ChartContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap : 40px;
    padding : 10px;
`

export const RowSection = styled.div`
    width: 100%;
    display: flex;
    gap : 30px;
    
    @media (${theme.MOBILE}) {
        flex-direction: column;
    }
`
export const ColumnSection = styled.div`
    width: 100%;
    display: flex;
    gap : 30px;
`

export const HalfSection = styled.div`
    display: flex;
    gap : 13px;
    width: 40%;
    flex-direction: column;
`