import { useEffect, useState } from 'react';

function useIsMedia() {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1080px)'); // 예시로 768px 기준으로 모바일인지 아닌지 확인

    // 초기 값 설정
    setIsMobile(mediaQuery.matches);

    // 미디어 쿼리 변경 시 이벤트 리스너 등록
    const handleChange = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    // 컴포넌트가 언마운트되면 리스너 제거
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return isMobile;
}

export default useIsMedia;
