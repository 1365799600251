import Chart from "react-apexcharts";
import { ChartProps } from "./ChartInterface";
import ExportBtn from "components/ExportBtn/ExportBtn";
import { CHART_COLORS } from "constants/ChartColor";
import HalfChartBox from "./HalfChartBox";
import styled from "styled-components";

export default function SettingChange({ getData, startDate, endDate, dropSub }: ChartProps) {

    return (
        <>
            <HalfChartBox
                title="Number of NPP Setting Changes"
                wd="100%"
                ht="50%"
                Topcontents={
                    <ExportBtn
                        startDate={startDate}
                        endDate={endDate}
                        subCode={dropSub}
                        type="tvsetting"
                    />
                }
                contents={
                    <Wrapper>
                        <div className="title">
                            Total
                        </div>
                        <div className="desc">
                            (for the specified period)
                        </div>
                        <div className="value">
                        {getData ? getData.toLocaleString() : 0}
                        </div>
                    </Wrapper>
                } />
        </>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-family: "Samsung Sharp Sans";

    .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: -0.05px;
    }

    .desc {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: -0.05px;
    }

    .value {
        margin-top: 18px;
        font-size: 36px;
        font-weight: 666;
        line-height: 38px;
    }
`;