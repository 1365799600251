import ChartBox from "./ChartBox";
import Chart from "react-apexcharts";
import { ChartProps } from "./ChartInterface";
import { useEffect, useState } from "react";
import ExportBtn from "components/ExportBtn/ExportBtn";
import { CHART_COLORS } from "constants/ChartColor";

export default function MobileExpStack({ getData, startDate, endDate, dropSub }: ChartProps) {

    const [series, setSeries] = useState<any>([]);

    useEffect(() => {
        if (getData) {
          const processedDataArray = getData?.count?.map((count: any, timeIndex: any) => {
              const appValues: string[] = getData ? getData?.country?.map((country) => getData?.content[country][timeIndex]?.value) : [];
              return { name: count, data: appValues };
          });
          getData?.count?.forEach((count: any, timeIndex: any) => {
              const appValues = [];
              getData?.country?.forEach((country) => {
                  const value = getData?.content[country][timeIndex]?.value;
                  appValues.push(value);
              });
          });
          setSeries(processedDataArray);
      }
    }, [getData]);

    const data: any = {
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    // borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: '14px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            xaxis: {
                categories: getData ? getData?.country : [],
                labels: {
                    rotate: -45,
                    rotateAlways: false,
                    style: {
                        // colors: colors,
                        fontSize: '14px',
                        fontWeight: 500,
                        color: "#000",
                        fontFamily: "Samsung Sharp Sans"
                    }
                }
            },
            legend: {
                position: 'right',
                offsetY: 40
            },
            fill: {
                opacity: 1
            },
            colors: CHART_COLORS
        },
    };

    const descStyle = {
        margin: 0,
        lineHeight: '19px',
        fontSize: '11px',
        fontStyle : "normal",
        fontWeight : "600",
        fontFamily : "Samsung Sharp Sans",
    };

    return (
        <>
            <ChartBox
                title={
                    <div style={{position : "relative"}}>
                        Status of Mobile Experience by Country<br/>
                        <span style={descStyle}>(The number of mobile experiences per IP by country)</span>
                    </div>
                }
                wd="45%"
                ht="500px"
                Topcontents={
                    <ExportBtn
                        startDate={startDate}
                        endDate={endDate}
                        subCode={dropSub}
                        type="mobileexperience"
                    />
                }
                contents={
                    <Chart
                        options={data.options}
                        series={series || []}
                        type="bar"
                        width="100%"
                        height="380"
                    />
                } />
        </>
    );
}
