import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import * as S from "./LocalModal.style";

import local1080 from 'assets/local1080.svg';
import local1920 from 'assets/local1920.svg';
import local1513 from 'assets/local1513.svg';
import localM1080 from 'assets/localM1080.svg';
import localM1513 from 'assets/localM1513.svg';
import localM1920 from 'assets/localM1920.svg';

import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { setCookie } from "util/Cookie";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};
interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}


export default function LocalNotiModal({ isOpen, onClose }: ModalProps) {

    const [isDont, setIsDont] = useState<boolean>(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const selectImage = () => {
        if (windowWidth >= 1920) {
            return local1920;
        } else if (windowWidth >= 1512) {
            return local1513;
        } else {
            return local1080;
        }
    };

    const selectMobileImage = () => {
        if (windowWidth >= 1920) {
            return localM1920;
        } else if (windowWidth >= 1512) {
            return localM1513;
        } else {
            return localM1080;
        }
    };

    const saveDontShow = () => {
        if (isDont) {
            setCookie("isLocalDont", "true", 1);
        } else {
            setCookie("isLocalDont", "false", 1);
        }
        onClose();
    };

    return (
        <div>
            <Modal
                open={isOpen}
            >
                <Box sx={style}>
                    <S.ContentBox>
                        <S.NotiTitle>
                            ESSENTIAL CHECKS “BEFORE DOWNLOADING THE EXPERIENCE APP”
                            <S.NotiSubTItle>
                                * Before downloading the experience app, be sure to follow the steps below!!!
                            </S.NotiSubTItle>
                        </S.NotiTitle>

                        <S.NumberTitle>
                            1. Local Variation
                        </S.NumberTitle>
                        <img src={selectImage()} />

                        <S.DesText>
                            If you don't follow the above steps, when registering the TV to the console, the subsidiary will appear as ‘GLOBAL’.
                            <div>
                                Following the above steps first will ensure the correct subsidiary appears.
                            </div>
                        </S.DesText>

                        <S.MobileImgBox>
                            <img src={selectMobileImage()} />
                        </S.MobileImgBox>

                        <S.BtnBox>
                            <div style={{
                                color: "#707070",
                                fontFamily: "SamsungOne",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "19px"
                            }}>
                                <Checkbox
                                    onChange={() => setIsDont(!isDont)}
                                />Don’t show for today
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{
                                    background: "#2196F3",
                                    color: "#FFF",
                                    fontFamily: "SamsungOne",
                                    fontSize: "15px",
                                    fontStyle: "normal",
                                    fontWeight: "800",
                                    lineHeight: "24px"
                                }}
                                onClick={saveDontShow}
                            // onClick={()=>console.log(isShow)}
                            >
                                GOT IT
                            </Button>
                        </S.BtnBox>
                    </S.ContentBox>
                </Box>
            </Modal>
        </div>
    );
}
