export const CHART_COLORS = [
    "#FF5733",
    "#FFD133",
    "#33E3FF",
    "#33A5FF",
    "#334DFF",
    "#7033FF",
    "#B033FF",
    "#FF33F3",
    "#FF33AE",
    "#FF3375",
    "#FF3362",
    "#FFD700",
    "#FF4500",
    "#8A2BE2",
    "#4B0082",
    "#00FF7F",
    "#20B2AA",
    "#0000FF",
    "#800080",
    "#000000",
    "#FFFF00",
    "#00FF00",
    "#008000",
    "#00FFFF",
    "#000080",
    "#FFBD33",
  ];
  