export const TV_STATUS_EXCEL = [
    { label: "TV Name", key: "tvName" },
    { label: "Subsidiary", key: "subsidiary" },
    { label: "Retailer", key: "retailer" },
    { label: "Store Name", key: "storeName" },
    { label: "PDP", key: "pdp" },
    { label: "Loop Type", key: "loopType" },
    { label: "Image Duration Time", key: "imgDurationTime" },
    { label: "Use", key: "use" },
    { label: "NPP Status", key: "nppstatus" },
    { label: "Start Time", key: "startTime" },
    { label: "End Time", key: "endTime" },
    { label: "Play Type", key: "playType" },
    { label: "offlineQR", key: "isQrUrl" }
];