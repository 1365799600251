import { useState, useContext, useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import { deleteSub, getSubTableList } from "apis/Admin/AdminApi";
import { RenderContext } from "hooks/context";
import * as S from "./WriteQnA.style";
import { OverFlowBox, QnATitle } from "pages/common.style";

import PopupAlert from "components/alert/PopupAlert"; import PageTop from "components/PageTop/PageTop";
import DataTable from "components/Table/DataTable";
import ConfirmAlert from "components/alert/ConfirmAlert";
import SubsidiaryModifyModal from "components/modal/SubsidiaryModifyModal";

import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { BTN_TEXT, BTN_TEXT_RED } from "constants/CssConstant";
import { useNavigate } from "react-router-dom";
import { getQnaList } from "apis/Qna/QnaApi";

import { GridCellParams } from '@mui/x-data-grid';

export default function QnA() {
  const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");
  const navi = useNavigate();
  const {
    isRender,
    isSubsidiary,
    renderAction,
  } = useContext(RenderContext);


  const [selectedData, setSelectedData] = useState<any>();

  const [isError, setIsError] = useState<boolean>(false);
  const [isUsed, setIsUsed] = useState<boolean>(false);

  const [deleteID, setDeleteID] = useState<string>("");
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [rowData, setRowData] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [take, setTake] = useState<number>(10);

  useEffect(() => {
    requestList();
  }, [text, page, take, isRender]);

  const requestList = async () => {
    try {
      const response: any = await getQnaList(text, page + 1, take);
      setRowData(response.rowData);
      setPageCount(response.pageData?.itemCount);
    } catch (e: any) {
      console.log(e, 'getList Error');
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id', headerName: 'No',
      // width: 50,
      flex: 0.2,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.id}
        </OverFlowBox>
      )
    },
    {
      field: 'title', headerName: 'Title',
      minWidth: 150,
      flex: 3.5,
      renderCell: (row: any) => (
        <QnATitle onClick={() => navi(userInfo?.permission?.createUser ? row.row.status === "Not yet" ? "/qnaDetail" : "/qnaModify" : "/qnaDetail", {
          state: {
            getId: row.row.postId
          }
        })}>
          {row.row.title}
        </QnATitle>
      )
    },
    {
      field: 'status', headerName: 'Status',
      minWidth: 150,
      flex: 0.3,
      renderCell: (row: any) => (
        <S.StatusBox
          className={row.row.status === "Not yet" ? "NotSet" : "complete"}
        >
          {row.row.status}
        </S.StatusBox>
      )
    },
    {
      field: 'date', headerName: 'Date',
      minWidth: 150,
      flex: 0.3,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.date}
        </OverFlowBox>
      )
    },
    {
      field: 'user', headerName: 'User',
      minWidth: 150,
      flex: 0.5,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.user}
        </OverFlowBox>
      )
    },
    {
      field: 'subsidiary', headerName: 'Subsidiary',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.subsidiary}
        </OverFlowBox>
      )
    },
  ];

  const [IsEdit, setIsEdit] = useState<boolean>(false);

  return (
    <>
      <PageTop
        title="Q&A"
        icon={<QuestionAnswerOutlinedIcon />}
      />
      <Box
        sx={{
          width: '100%',
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(238, 238, 238, 1)",
            color: "black",
            fontFamily: "SamsungOne",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "16px"
          }
        }}
      >
        <DataTable
          setSearchText={setText}
          setSearchPage={setPage}
          rowCount={pageCount}
          setTableCount={setTake}
          apiData={rowData}
          cellHandle={(params: GridCellParams) => console.log(params)}
          columnData={columns}
          placeHolder={"Title, Date, User, Subsidiary"}
          btnContent={
            <>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navi("/writeqna")}
                sx={{
                  color: BTN_TEXT,
                }}
              >
                COMPOSE
              </Button>
            </>
          }
        />
      </Box>
      {isSubsidiary &&
        <SubsidiaryModifyModal
          isOpen={isSubsidiary}
          onClose={() => setIsEdit(false)}
          selectedData={selectedData}
        />
      }
      {isError &&
        <PopupAlert
          isOpen={isError}
          onClose={() => setIsError(false)}
          contents={`Delete Complete`}
        />
      }
      {isUsed &&
        <PopupAlert
          isOpen={isUsed}
          onClose={() => setIsUsed(false)}
          contents={`Already used`}
        />
      }
      {isDelete &&
        <ConfirmAlert
          isOpen={isDelete}
          onClose={() => setIsDelete(false)}
          requserApi={() => console.log('asdasd')}
        />
      }
    </>
  );
}
