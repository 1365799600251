import ChartBox from "./ChartBox";
import Chart from "react-apexcharts";
import { ChartProps } from "./ChartInterface";
import ExportBtn from "components/ExportBtn/ExportBtn";
import { CHART_COLORS } from "constants/ChartColor";


export default function StatusApp({ getData, startDate, endDate, dropSub }: ChartProps) {
    // console.log(getData, 'getData nu');

    const data: any = {
        series: [{
            name: "",
            data: getData ? getData?.map((x: any) => x.value) : []
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                },
                toolbar: {
                    show: false
                },
            },
            //   colors: colors,
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: getData ? getData?.map((x: any) => x.key) : [],
                labels: {
                    style: {
                        // colors: colors,
                        fontSize: '14px',
                        fontWeight: 500,
                        color: "#000",
                        fontFamily: "Samsung Sharp Sans"
                    }
                }
            },
            tooltip: {
                enabled: true, // 툴팁 활성화
                x: {
                    show: false,
                },
                y: {
                    formatter: function (val, opts) {
                        const dataKey = opts.w.globals.labels[opts.dataPointIndex];
                        return `${dataKey}: ${val}`;
                    }
                }
            },
            colors : CHART_COLORS
        },
    };

    return (
        <>
            <ChartBox
                title="Status of New App Installations"
                wd="50%"
                ht="500px"
                Topcontents={
                    <ExportBtn
                        startDate={startDate}
                        endDate={endDate}
                        subCode={dropSub}
                        type="appstatus"
                    />
                }
                contents={
                    <div style={{ marginTop: "30px" }}>
                        <Chart
                            options={data.options}
                            series={data.series}
                            // options={options}
                            // series={ser}
                            type="bar"
                            width="100%"
                            height="390"
                        />
                    </div>
                } />

        </>
    );
}