import styled from "styled-components";

export const OverFlowBox = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StatusBox = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding : 3px 4px;
  font-family: SamsungOne;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;

  &.Running {
    width: 70px;
    color : white;
    background-color: green;
  }

  &.Wait {
    width: 45px;
    color : white;
    background-color: orange;
  }

  &.NotSet {
    width: 60px;
    background-color: #e8e8e8;
    color : black;
  }

  &.Pause {
    width: 50px;
    color : white;
    background-color: #8B00AE;
  }

  &.Expired {
    width: 60px;
    color : white;
    background-color: #B40000;
  }
`;

export const BtnBox = styled.div`
  display: flex;
  gap : 12px;
`;

export const BtnWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  outline: auto;

  &:hover {
    background-color: #BFBFBF;
    cursor: pointer;
  }
`;

export const TmpText = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    font-family: SamsungOne;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 24px; 133.333% */
    /* letter-spacing: 0.15px; */
    color : #707070;
`;


export const QnATitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DownloadBtn = styled.div`
  height: 26px;
  gap : 8px;
  display: flex;
  padding: 4px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color :#2196F3;
  background-color:  rgba(33, 150, 243, 0.04);
  cursor: pointer;
`;

export const DownloadLink = styled.a`
  height: 26px;
  gap : 8px;
  display: flex;
  padding: 4px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color :#2196F3;
  background-color:  rgba(33, 150, 243, 0.04);
  cursor: pointer;
  text-decoration: none;
`;