import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";

import { DROP_MENU_VALUE, INPUT_LABEL_VALUE, INPUT_MIXED_VALUE, INPUT_VALUE } from "constants/CssConstant";
import { MediaType } from "util/media";

interface SettingDropDownProps {
    label?: string;
    option?: any;
    defaultValue?: string;
    value: string;
    setValue: Function;
    width?: string;
    onchange?: () => void;
    onclick?: () => void;
}

export default function TableDropDown({ label, option, value, setValue, width, onclick, onchange }: SettingDropDownProps) {

    const changeValue = (e: any) => {
        setValue(e.target.value);
        onchange();
        // console.log(e.target.value,'e.target.value')
    };

    return (
        <FormControl sx={{ width: { width } }}>
            <InputLabel
                id="select-label"
            >
                {label}
            </InputLabel>
            <Select
                className="test"
                labelId="select-label"
                id="select-demo"
                value={value}
                label={label}
                // onChange={(e: any) => setValue(e.target.value)}
                onChange={(e: any) => changeValue(e)}
                defaultValue={value}
                sx={{
                    height: "40px"
                }}
            >
                {option.length > 0 && option?.map((x: any, idx: number) => (
                    <MenuItem
                        value={x.value}
                        key={idx}
                    // sx={{ color: DROP_MENU_VALUE, p: 2 }}
                    >
                        {x.key}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

}