const COLORS = {
  PRIMARY: {
    PRIMARY_1: "#7B26E7",
    PRIMARY_2: "#7000ff",
    SUB_PRIMARY: "#F0C534",
  },
  SECONDARY: "#E80000",
  BACKGROUND: "#F9F7FB",
  GRAY: {
    DARK_0: "#FFFFFF",
    DARK_10: "#F0F0F0",
    DARK_20: "rgba(0, 0, 0, 0.50)",
    DARK_30: "#b5b5b5",
    DARK_35: "#8b8b8b",
    DARK_40: "#5B5662",
    DARK_50: "#504A57",
    DARK_60: "#372F41",
    DARK_70: "#121212",
  },
};

const TRANS = "all 0.7s ease-out";

const SHADOW = {
  OPTION_1: "10px 15px 30px 0 rgba(94, 68, 148, 0.10)",
  OPTION_2: "0 0 50px 0 rgba(94, 68, 148, 0.20);",
};

const FONT = {
  TITLE: "GmarketSansBold",
  DEFAULT: "Apple SD Gothic Neo",
};

const MOBILE = "max-width: 1080px";
// const MOBILE = "max-width: 800px";
// const TABLET = "max-width: 1080px";
const PC = "min-width: 1080px";

const FULL_WIDTH_BY_SIZE = {
  MOBILE: "90%",
  TABLET: "calc(100% - 6vw)",
  PC: "90rem",
};

export const theme = {
  COLORS,
  TRANS,
  SHADOW,
  FONT,
  MOBILE,
  // TABLET,
  PC,
  FULL_WIDTH_BY_SIZE,
};
