import TableDropDown from "components/DropDown/TableDropDown";
import ChartBox from "./ChartBox";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { getMySubList } from "apis/Admin/AdminApi";
import CustomButton from "components/Button/CustomButton";
import { ChartProps } from "./ChartInterface";
import { getAppTrends, getFileSrc } from "apis/DashBoard/DashBoardApi";
import axios from "axios";
import ExportBtn from "components/ExportBtn/ExportBtn";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CHART_COLORS } from "constants/ChartColor";

export default function Experience() {
  const userInfo = JSON.parse(localStorage.getItem("User") || "{}");

  const [trendsData, setTrendsData] = useState<any>();
  const [series, setSeries] = useState<any>([]);

  const data: any = {
    options: {
      chart: {
        width: 200,
        height: 450,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      markers: {
        size: 6,
        hover: {
          sizeOffset: 0
        }
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      },
      tooltip: {
      },
      grid: {
        borderColor: '#f1f1f1',
      },
      colors : CHART_COLORS
    },
  };

  const [requestSub, setRequestSub] = useState<any>([]);
  const [dropSub, setDropSub] = useState<string>("");
  const [isinit, setIsinit] = useState<boolean>(true);

  const getAppData = async (sub: string) => {
    // console.log(sub,'suvb')
    const trendsRes = await getAppTrends(sub);
    setTrendsData(trendsRes?.data);
    const dataInx = trendsRes?.data?.date?.map((x: any) => x);
    setSeries(trendsRes?.data?.appname?.map((x: any) => ({
      name: x,
      data: dataInx.map((i: any) => trendsRes?.data?.content[x]?.find((k: any) => i === k.date).value)
    })));
  };

  useEffect(() => {
    initRequestList();
  }, []);

  const initRequestList = async () => {
    const subResponse = await getMySubList("",true);
    setDropSub(subResponse[0]?.value);
    setRequestSub(subResponse);
    setIsinit(false);
  };

  useEffect(() => {
    if (!isinit) {
      if(dropSub === "ALL"){
        getAppData("");
      }else{
        getAppData(dropSub);
      }
    }
  }, [isinit, dropSub]);

  const changeValue = (e: any) => {
    setDropSub(e.target.value);
  };


  return (
    <>
      <ChartBox
        title="App Trends by Experience"
        wd="60%"
        ht="530px"
        Topcontents={
          <>
            {
              userInfo?.Permission?.viewAllSubsidiary &&
              <FormControl sx={{ width: "180px" }}>
                <InputLabel
                  id="select-label"
                >
                  Subsidiary
                </InputLabel>
                <Select
                  className="test"
                  labelId="select-label"
                  id="select-demo"
                  value={dropSub}
                  label="Subsidiary"
                  // onChange={(e: any) => setValue(e.target.value)}
                  onChange={(e: any) => changeValue(e)}
                  defaultValue={dropSub}
                  sx={{
                    height: "40px"
                  }}
                >
                  {requestSub.length > 0 && requestSub?.map((x: any, idx: number) => (
                    <MenuItem
                      value={x.value}
                      key={idx}
                    >
                      {x.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
            <ExportBtn
              startDate={''}
              endDate={''}
              subCode={dropSub}
              type="trends"
            />
          </>
        }
        contents={
          <Chart
            options={data.options}
            series={series || []}
            type="line"
            width="100%"
            height="420"
          />
        } />
    </>
  );
}