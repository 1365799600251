import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@mui/material/FormHelperText";

import { getGroupList, getMySubList, getSubList, inPutUser, updateMe, updateUser } from "apis/Admin/AdminApi";
import { getMyProfile } from "apis/AuthApi/AuthApi";

import { RenderContext } from "hooks/context";

import CustomButton from "components/Button/CustomButton";
import SettingDropDown from "components/DropDown/SettingDropDown";
import PopupAlert from "components/alert/PopupAlert";

import { INPUT_LABEL_VALUE, INPUT_VALUE } from "constants/CssConstant";
import { AUTHORITY, CREATE_AUTHORITY } from "constants/AuthConstant";
import * as S from "./UserModal.style";
import { MediaType } from "util/media";
import CloseAlert from "components/alert/CloseAlert";
import CustomCheckBox from "components/CheckBox/CustomCheckBox";
import { Checkbox } from "@mui/material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    p: 3,
};

interface ModalProps {
    isOpen: boolean;
    isNew?: boolean;
    onClose: () => void;
    selectedData?: any;
    isMyEdit?: boolean;
}


export default function EditMyModal({ isNew, isOpen, onClose, selectedData, isMyEdit }: ModalProps) {

    const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");

    const media = MediaType();
    const {
        isCancel,
        closeModal,
        renderAction,
        renderOpen,
        openCancel,
        closeCancel,
        closeUser,
        closeUserTable,
    } = useContext(RenderContext);


    const [getId, setId] = useState<string>(selectedData != undefined ? selectedData[0].name : "");
    const [getPw, setPw] = useState<any>(isNew ? null : "******");

    const [getSub, setSub] = useState<string>(selectedData != undefined ? selectedData[0].subsidiary : "");
    const [getGroup, setGroup] = useState<string>(selectedData != undefined ? selectedData[0].group : "");

    const [information, setInformation] = useState<string>(selectedData != undefined ? selectedData[0].information : "");

    const [prePw, setPrePw] = useState<string>("");
    const [createPw, setCreatePw] = useState<string>("");
    const [prePwError, setPrePwError] = useState<boolean>(false);
    const [createPwError, setCreatePwError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");
    const [responeText, setResponseText] = useState<string>("");
    const [isAble, setIsAble] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isErrorModal, setIsErrorModal] = useState<boolean>(false);
    const [myData, setMyData] = useState<any>({
        loginId: "",
        authority: "",
        subsidiary: "",
        information: "",
        getGroup: "",

    });

    const requsetMyProfile = async () => {
        try {
            const response = await getMyProfile();
            setMyData({
                ...myData,
                loginId: response.loginId,
                subsidiary: response.Subsidiary?.code,
                information: response.information,
                getGroup: response.Group.name
            });
            console.log(response, 'my data');
        } catch (e: any) {
            console.log(e, 'change Error');
        }
    };

    useEffect(() => {
        requsetMyProfile();
    }, []);

    const useStyles = makeStyles(theme => ({
        root: {
            "& .MuiFormLabel-root": INPUT_LABEL_VALUE,
            "& .MuiInputLabel-shrink": {
                fontSize: 20,
                lineHeight: 1.4
            },
            "& .MuiInput-input": {
                marginTop: 5
            },
        }
    }));
    const classes = useStyles();

    const updateMeData = {
        "name": getId,
        "oldPassword": prePw != "" ? prePw : undefined,
        "password": createPw != "" ? createPw : undefined,
        "StatusName": "active",
        "information": myData.information
    };

    const inputUser = async () => {
        if (!isAble) {
            return;
        }
        if (isMyEdit) {
            if (createPwError === false) {
                try {
                    const inputresponse = await updateMe(updateMeData);
                    if (inputresponse.isSuccess) {
                        console.log(inputresponse, 'inputresponse');
                        if (inputresponse.data.status === 400) {
                            setPrePwError(true);
                            setIsAble(false);
                        } else {
                            closeModal();
                            closeUser();
                            closeUserTable();
                            onClose();
                        }
                    }
                } catch (e: any) {
                    console.log(e, "update user error");
                }
            } else {
                return null;
            }
        }
    };

    const reqSubsidiary = async () => {
        try {
            const response = await getMySubList("");
            setRequestSub(response);
        } catch (e: any) {
            console.log(e, "sub res error");
        }
    };

    const reqGroup = async () => {
        try {
            const response = await getGroupList();
            setRequestGroup(response);
        } catch (e: any) {
            console.log(e, "group res error");
        }
    };

    const [requestSub, setRequestSub] = useState<any>([]);
    const [requestGroup, setRequestGroup] = useState<any>([]);

    useEffect(() => {
        reqSubsidiary();
        reqGroup();
    }, []);

    useEffect(() => {
        if (prePw != "" && createPw != "") {
            if (prePw == createPw) {
                setCreatePwError(true);
                setIsAble(false);
                setErrorText("Same as the previous password. Please enter a different password.");
            } else {
                setCreatePwError(false);
                setErrorText("");
                setIsAble(true);
            }
        }
    }, [prePw, createPw, getPw]);

    return (
        <div>
            <Modal open={isOpen} onClose={onClose}>
                <Box sx={style}>
                    <S.ContentBox $isMyEdit={isMyEdit}>
                        <S.TitleSection>
                            Edit My Profile
                        </S.TitleSection>
                        <>
                            <TextField
                                variant="standard"
                                label={<>ID</>}
                                type="text"
                                className={classes.root}
                                value={myData.loginId || ''}
                                // value={getId}
                                placeholder="Enter ID"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => setId(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "95%",
                                    input: INPUT_VALUE
                                }}
                                disabled={isNew ? false : true}
                            />
                            <>
                                <S.ContentSection>
                                    <TextField
                                        variant="standard"
                                        label={<>Previous Password<span style={{ color: "red" }}> *</span></>}
                                        type="password"
                                        className={classes.root}
                                        value={prePw || ''}
                                        placeholder="Enter Previous Password"
                                        InputLabelProps={{
                                            shrink: true, sx: {
                                                //label css
                                                mt: media === "MOBILE" ? 1 : 0
                                            }
                                        }}
                                        onChange={(e) => setPrePw(e.target.value)}
                                        sx={{
                                            width: media === "MOBILE" ? "100%" : "90%",
                                            // width: "100%",
                                            input: INPUT_VALUE
                                        }}
                                        error={prePwError}
                                        helperText={prePwError ? "You are entering the wrong password.If you can't remember your password, please contact the administrator." : ""}
                                    />
                                    <TextField
                                        variant="standard"
                                        label={<>New Password<span style={{ color: "red" }}> *</span></>}
                                        type="password"
                                        className={classes.root}
                                        value={createPw || ''}
                                        placeholder="Enter New Password"
                                        InputLabelProps={{
                                            shrink: true, sx: {
                                                //label css
                                            }
                                        }}
                                        onChange={(e) => setCreatePw(e.target.value)}
                                        sx={{
                                            width: media === "MOBILE" ? "100%" : "90%",
                                            input: INPUT_VALUE
                                        }}
                                        error={createPwError}
                                        helperText={errorText}
                                    />
                                </S.ContentSection>
                            </>
                        </>

                        <S.ContentSection $isMyEdit={isMyEdit}>
                            <SettingDropDown
                                label={"Group"}
                                setValue={setGroup}
                                option={requestGroup}
                                value={myData.getGroup}
                                isMyEdit={isMyEdit}
                                isPoint={true}
                                width={media === "MOBILE" ? "100%" : "90%"}
                                isDisable={myData.getGroup.name === "SUPER" ? false : true}
                            />
                            <SettingDropDown
                                label={"Subsidiary"}
                                setValue={setSub}
                                option={requestSub}
                                value={myData.subsidiary}
                                isMyEdit={isMyEdit}
                                isPoint={true}
                                width={media === "MOBILE" ? "100%" : "90%"}
                            // isUser
                            />
                        </S.ContentSection>

                        <Box sx={{ marginTop: isMyEdit ? "0px" : "0px" }}>
                            <TextField
                                variant="standard"
                                label="Information"
                                type="text"
                                className={classes.root}
                                value={isMyEdit ? myData.information : information || ''}
                                placeholder="Enter Information"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => setMyData({ ...myData, information: e.target.value })}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "95%",
                                    mt: media === "MOBILE" ? 1 : 0,
                                    input: INPUT_VALUE,
                                }}
                            />
                            <FormHelperText
                                sx={{
                                    display: "flex",
                                    justifyContent: "right",
                                    padding: media === "MOBILE" ? 0 : "0 55px"
                                }}
                            >
                                <span>{`${information != undefined ? information.length : 0}/${255}`}</span>
                            </FormHelperText>
                        </Box>

                        <S.BtnSection>
                            <CustomButton
                                title="SAVE"
                                classname={isAble ? "save-btn" : "disable-check"} //disable-check
                                icon={
                                    <></>
                                }
                                btnClick={() => inputUser()}
                                Width="79"
                                Height="40"
                            />
                            <CustomButton
                                title="CANCEL"
                                classname="cancel-btn"
                                icon={
                                    <></>
                                }
                                Width="100"
                                Height="40"
                                btnClick={() => openCancel()} />
                        </S.BtnSection>
                    </S.ContentBox>

                    {isError
                        &&
                        <PopupAlert
                            isOpen={isErrorModal}
                            onClose={() => setIsErrorModal(false)}
                            contents={responeText}
                        />
                    }
                    {isCancel
                        &&
                        <CloseAlert
                            isOpen={isCancel}
                            onClose={() => closeCancel()}
                        />
                    }
                </Box>
            </Modal>
        </div>
    );
}
