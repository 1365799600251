import styled from "styled-components";

export const TopSection = styled.div`
    display: flex;
    padding : 10px;
    /* border: 1px solid #EFEFEF; */
`;

export const TitleBox = styled.div<{$sideline? : boolean}>`
    height: 80%;
    display: flex;
    gap : 10px;
    padding : 5px;
    padding-right: 24px;
    padding-left: 0;
    border-right: ${({ $sideline }) => `${!$sideline && "1px solid #BFBFBF"}`};
    align-items: center;
    justify-content: center;
    font-family: "Samsung Sharp Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 666;
    line-height: 34px; /* 170% */
    letter-spacing: 1px;
`;

export const BtnBox = styled.div`
    display: flex;
    gap : 5px;
    margin-left: 20px;
`;

export const BlueBtn = styled.div`
    min-width: 130px;
    border-radius : 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size : 20px;
    color : #2196f3;
    
    &:hover {
        transition: all 0.5s;
        background-color: #aedafdc1;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
`;