import { useContext, useEffect, useState } from 'react';
import Moment from 'moment';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@mui/material/FormHelperText";

import * as S from "./SubsidiaryModal.style";
import { getMyGroupList, getRegion, getSubList, getTimezone, inPutSub, inPutUser, updateSub, updateUser } from "apis/Admin/AdminApi";
import { RenderContext } from "hooks/context";

import PopupAlert from "components/alert/PopupAlert";
import CustomButton from "components/Button/CustomButton";
import SettingDropDown from "components/DropDown/SettingDropDown";

import { INPUT_LABEL_VALUE, INPUT_VALUE } from "constants/CssConstant";
import { MediaType } from "util/media";
import CloseAlert from "components/alert/CloseAlert";
import { dateFormat } from "util/Formatter";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    p: 3,
};

interface ModalProps {
    isOpen: boolean;
    isNew?: boolean;
    onClose: () => void;
    selectedData?: any;
    isMyEdit?: boolean;
}

export default function SubsidiaryModifyModal({ isNew, isOpen, onClose, selectedData, isMyEdit }: ModalProps) {
    const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");
    const media = MediaType();
    const {
        isCancel,
        renderAction,
        renderOpen,
        closeCancel,
        openCancel,
        closeSub,
        closeSubTable,
    } = useContext(RenderContext);


    const [getId, setId] = useState<string>(selectedData[0].subsidiary);
    const [getSub, setSub] = useState<string>(selectedData[0].region);

    const [getUpdateTime, setUpdateTime] = useState<string>(selectedData[0].updatedAt);
    const [getCreateTime, setCreateTime] = useState<string>(selectedData[0].createdAt);

    const [information, setInformation] = useState<string>(selectedData[0].information);
    // const [information, setInformation] = useState<string>(selectedData[0].magazineLink);

    const [getGroup, setGroup] = useState<string>(userInfo?.group.name === "SUPER" ? selectedData[0].group : userInfo?.group.name);
    const [getTimeZone, setTimeZone] = useState<string>(selectedData[0].timezone);

    const [isErrorModal, setIsErrorModal] = useState<boolean>(false);
    const [responeText, setResponseText] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);

    const [isAble, setIsAble] = useState<boolean>(false);
    const [inputUrl, setInputUrl] = useState<string>(selectedData[0].magazine);
    const [inputSmart, setInputSmart] = useState<string>(selectedData[0].smartThings);
    const [inputPdp, setInputPdp] = useState<string>(selectedData[0].pdpLink);

    console.log(selectedData, 'xeleeea');

    const useStyles = makeStyles(theme => ({
        root: {
            "& .MuiFormLabel-root": INPUT_LABEL_VALUE,
            "& .MuiInputLabel-shrink": {
                fontSize: 22,
                lineHeight: 1.4
            },
            "& .MuiInput-input": {
                marginTop: 5
            },
        }
    }));

    const classes = useStyles();

    const updateUserData = {
        "code": getId,
        "information": information,
        "RegionCode": getSub,
        "GroupName": getGroup,
        "magazineLink": inputUrl,
        "smartthingsLink": inputSmart,
        "pdpLink": inputPdp,
        "timezoneId": getTimeZone
    };

    const inputUser = async () => {
        try {
            const inputresponse = await updateSub(selectedData[0].id, updateUserData);
            if (inputresponse.isSuccess) {
                console.log(inputresponse, 'updatetresponse');
                renderAction();
                renderOpen();
                onClose();
                closeCancel();
                closeSub();
                closeSubTable();
            }
        } catch (e: any) {
            console.log(e, "update user error");
        }
    };

    const reqRegion = async () => {
        try {
            const response = await getRegion();
            // if(response){
            console.log(response, 'region res');
            setRequestSub(response);
            // }
        } catch (e: any) {
            console.log(e, "sub res error");
        }
    };

    const reqGroup = async () => {
        try {
            const response = await getMyGroupList();
            // if(response){
            // console.log(response, 'region res');
            setRequestGroup(response);
            // }
        } catch (e: any) {
            console.log(e, "sub res error");
        }
    };

    const reqTimezone = async () => {
        try {
            const response = await getTimezone();
            setRequestTime(response);
        } catch (e: any) {
            console.log(e, "sub res error");
        }
    };

    const [requestTime, setRequestTime] = useState<any>([]);
    const [requestGroup, setRequestGroup] = useState<any>([]);
    const [requestSub, setRequestSub] = useState<any>([]);

    useEffect(() => {
        reqRegion();
        reqTimezone();
        if (userInfo?.group.name === "SUPER") {
            reqGroup();
        }
    }, []);

    useEffect(() => {
        if (userInfo.permission.createSubsidiary) {
            if (getId != "" && getSub != "" && getGroup != "" && (inputUrl != "" && inputUrl != null) && (inputSmart != "" && inputSmart != null)) {
                setIsAble(true);
            } else {
                setIsAble(false);
            }
        } else {
            if (getId != "" && getSub != "" && getGroup != "") {
                setIsAble(true);
            } else {
                setIsAble(false);
            }
        }
    }, [getId, getSub, getGroup, inputUrl, inputSmart]);


    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onClose}
            >
                <Box sx={style}>
                    {isError
                        &&
                        <PopupAlert
                            isOpen={isErrorModal}
                            onClose={() => setIsErrorModal(false)}
                            contents={responeText}
                        />
                    }
                    <S.ContentBox $ismobile={media}>
                        <S.TitleSection>
                            Edit
                        </S.TitleSection>
                        <S.ContentSection $ismobile={media}>
                            <TextField
                                variant="standard"
                                label={<>Subsidiary<span style={{ color: "red" }}> *</span></>}
                                type="text"
                                className={classes.root}
                                value={getId || ''}
                                placeholder="Enter Subsidiary"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => setId(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "90%",
                                    input: INPUT_VALUE
                                }}
                            // disabled={isNew ? false : true}
                            />
                            <SettingDropDown
                                label="Region"
                                setValue={setSub}
                                option={requestSub}
                                value={getSub}
                                isUser
                                isPoint
                                width={media === "MOBILE" ? "100%" : "90%"}
                            />
                        </S.ContentSection>

                        <S.ContentSection $ismobile={media}>

                            {userInfo?.group.name === "SUPER" &&
                                <SettingDropDown
                                    label="Group"
                                    setValue={setGroup}
                                    option={requestGroup}
                                    value={getGroup}
                                    isUser
                                    isPoint
                                    width={media === "MOBILE" ? "100%" : "90%"}
                                />
                            }
                            <SettingDropDown
                                label="Time Zone"
                                setValue={setTimeZone}
                                option={requestTime}
                                value={getTimeZone}
                                isUser
                                isPoint
                                width={media === "MOBILE" ? "100%" : "90%"}
                            />
                        </S.ContentSection>


                        <Box sx={{ marginTop: ["0px", "30px"] }}>
                            <TextField
                                disabled={!userInfo.permission.createSubsidiary}
                                variant="standard"
                                label={<>Magazine Link<span style={{ color: "red" }}> *</span></>}
                                type="text"
                                className={classes.root}
                                value={inputUrl || ''}
                                placeholder="Enter MagazineLink"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => setInputUrl(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "95%",
                                    input: INPUT_VALUE,
                                }}
                            />
                        </Box>

                        <Box sx={{ marginTop: ["0px", "30px"] }}>
                            <TextField
                                disabled={!userInfo.permission.createSubsidiary}
                                variant="standard"
                                label={<>SmartThings<span style={{ color: "red" }}> *</span></>}
                                type="text"
                                className={classes.root}
                                value={inputSmart || ''}
                                placeholder="Enter MagazineLink"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => setInputSmart(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "95%",
                                    input: INPUT_VALUE,
                                }}
                            />
                        </Box>

                        <Box sx={{ marginTop: ["0px", "30px"] }}>
                            <TextField
                                disabled={!userInfo.permission.createSubsidiary}
                                variant="standard"
                                label={<>Default PDP</>}
                                type="text"
                                className={classes.root}
                                value={inputPdp || ''}
                                placeholder="Enter MagazineLink"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => setInputPdp(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "95%",
                                    input: INPUT_VALUE,
                                }}
                            />
                        </Box>

                        <Box sx={{ marginTop: ["0px", "30px"] }}>
                            <TextField
                                variant="standard"
                                label="Information"
                                type="text"
                                className={classes.root}
                                value={information || ''}
                                placeholder="Enter Information"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => setInformation(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "95%",
                                    input: INPUT_VALUE,
                                }}
                            />
                            <FormHelperText
                                sx={{
                                    display: "flex",
                                    justifyContent: "right",
                                    padding: ["none", "0 55px"]
                                }}
                            >
                                <span>{`${information != undefined ? information.length : 0}/${255}`}</span>
                            </FormHelperText>
                        </Box>

                        <S.ContentSection>
                            <TextField
                                variant="standard"
                                label="Create"
                                type="text"
                                className={classes.root}
                                value={dateFormat(getCreateTime) || ''}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "90%",
                                    input: INPUT_VALUE
                                }}
                                disabled
                            />
                            <TextField
                                variant="standard"
                                label="Update"
                                type="text"
                                className={classes.root}
                                value={dateFormat(getUpdateTime) || ''}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "90%",
                                    input: INPUT_VALUE
                                }}
                                disabled
                            />
                        </S.ContentSection>

                        <S.ModifyBtnSection $ismobile={media}>
                            <CustomButton
                                title="SAVE"
                                classname={isAble ? "save-btn" : "disable-check"} //disable-check
                                icon={
                                    <></>
                                }
                                btnClick={() => inputUser()}
                                Width="79"
                                Height="40"
                            />
                            <CustomButton
                                title="CANCEL"
                                classname="cancel-btn"
                                icon={
                                    <></>
                                }
                                Width="100"
                                Height="40"
                                btnClick={() => openCancel()} />
                        </S.ModifyBtnSection>
                    </S.ContentBox>
                    {isCancel
                        &&
                        <CloseAlert
                            isOpen={isCancel}
                            onClose={() => closeCancel()}
                        // contents={responeText}
                        />
                    }
                </Box>
            </Modal>
        </div>
    );
}
