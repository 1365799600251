import { useState, useContext } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';

import { RenderContext } from "hooks/context";
import styled from "styled-components";
import CustomButton from "components/Button/CustomButton";
import { deleteQna } from "apis/Qna/QnaApi";
interface AlertProps {
    isOpen: any;
    onClose: () => void;
    contents?: string;
    allClose?: (state: boolean) => void;
    navi?: () => void;
    deleteId? : string;
}

export default function DeleteAlert({ isOpen, onClose, navi, deleteId }: AlertProps) {

    const {
        closeCancel,
        closeModal,
        closeUser,
        closeNpp,
        closeSub,
        closeNppTable,
        closeSubTable,
        closeUserTable,
    } = useContext(RenderContext);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: "5px",
        boxShadow: 24,
        p: 3,
    };

    const [open, setOpen] = useState(false);

    const closeAll = () => {
        closeModal();
        closeUser();
        closeNpp();
        closeNppTable();
        closeSub();
        closeSubTable();
        closeUserTable();
        closeCancel();
    };

    const movePage = async () => {
        const deleteRes = await deleteQna(deleteId);
        try{
            onClose();
            navi();
        }catch {
            alert("Delete Error")
        }
        
    };

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onClose}
            >
                <Box sx={style}>
                    <DialogTitle id="alert-dialog-title">
                        Are you sure you want to delete the selected item from the table?
                    </DialogTitle>
                    <BtnBox>
                        <CustomButton
                            title="DELETE"
                            classname={"save-btn"} //disable-check
                            icon={
                                <></>
                            }
                            btnClick={() => navi ? movePage() : closeAll()}
                            Width="150"
                            Height="40"
                        />
                        <CustomButton
                            title="CANCEL"
                            classname="cancel-btn"
                            icon={
                                <></>
                            }
                            Width="100"
                            Height="40"
                            // btnClick={() => onClose()} />
                            btnClick={() => navi ? onClose() : closeCancel()} />
                    </BtnBox>
                </Box>
            </Modal>
        </div>
    );
}

const BtnBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    gap : 20px;
`;