import styled from "styled-components";

export const TopSection = styled.div`
    display: flex;
    padding : 10px;
    /* border: 1px solid #EFEFEF; */
`;

export const TitleBox = styled.div`
    height: 80%;
    display: flex;
    gap : 10px;
    padding : 5px;
    padding-right: 24px;
    padding-left: 0;
    align-items: center;
    justify-content: center;
    font-family: Samsung Sharp Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 666;
    line-height: 34px; /* 170% */
    letter-spacing: 1px;
`;

export const BtnBox = styled.div`
    display: flex;
    gap : 20px;
    margin-left: auto;
`;

export const BlueBtn = styled.div`
    min-width: 130px;
    border-radius : 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size : 20px;
    color : #2196f3;
    
    &:hover {
        transition: all 0.5s;
        background-color: #aedafdc1;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
`;

export const ContentWrapper = styled.div`
    padding : 10px;
    display: flex;
    flex-direction: column;

    gap : 24px;
`;

export const IconWrapper = styled.div`
    border : 1px solid red;
    width: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items : center;
    cursor: pointer;
`;

export const ADDsection = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    gap : 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background:  #FFF;
    border-radius: 4px;
    border: 1px dashed rgba(0, 0, 0, 0.12);
    /* box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25); */
    color : #9F9F9F;
    font-family: SamsungOne;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 12px; 100% */
`;

export const ADDTEXT = styled.div`
    color : #2196F3;
    font-family: SamsungOne;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-decoration-line: underline;
    cursor: pointer;
`;

export const InputArea = styled.textarea<{ $height?: number; }>`
    width: 100%;
    height: ${props => `${props.$height}px`};
    border : 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding : 12px;
    outline : none;
    margin-top : 10px;
    resize: none;
`;

export const StatusBox = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding : 3px 4px;
  font-family: SamsungOne;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;


  &.NotSet {
    width: 60px;
    background-color: #e8e8e8;
    color : black;
  }

  &.complete {
    width: 75px;
    color : white;
    background-color: green;
  }
  
`;

export const ResponseTitle = styled.div`
    color: #202020;
    font-family: SamsungOne;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.15px;
`;

export const ResponseName = styled.div`
    color:#888;
    font-family: SamsungOne;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px; /* 114.286% */
`;

export const ResponseContent = styled.div`
    color: #202020;
    font-family: SamsungOne;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    white-space: pre-wrap;
    margin-top: 14px;
`;
export const ImgBox = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    background:  #FFF;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    color : black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%; /* 28px */
    letter-spacing: 0.15px;
`;

export const ImgWrapper = styled.div`
    margin-top: 14px;
    display: flex;
    gap : 5px;
    flex-direction: column;
`;

export const SubText = styled.div`
    font-family: SamsungOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
`;