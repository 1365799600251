import { DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
import dayjs, { Dayjs } from 'dayjs';

interface MonthProps {
    startMonth?: string;
    endMonth?: string;
    setStartMonth?: Function;
    setEndMonth?: Function;
}

export default function MonthPicker({startMonth,endMonth,setStartMonth,setEndMonth} : MonthProps) {


    const maxMonth = dayjs().subtract(365, 'day');

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{
                    display: "flex",
                    gap: "15px"
                }}>
                    <DatePicker
                        // minDate={dayjs(endMonth).subtract(365, 'day')}
                        label={"Date"}
                        openTo="month"
                        views={['year','month']}
                        sx={{
                            width: "150px"
                        }}
                        slotProps={{ textField: { size: 'small' } }}
                        format={"MM/YYYY"}
                        defaultValue={dayjs(startMonth)}
                        onChange={(e: any) => setStartMonth(moment(e.$d).format("YYYYMM"))}
                        />
                    <div style={{ marginTop: "10px" }}>
                        ~
                    </div>
                    <DatePicker
                        // maxDate={dayjs(maxMonth)}
                        label={"Date"}
                        openTo="month"
                        views={['year','month' ]}
                        sx={{
                            width: "150px"
                        }}
                        format={"MM/YYYY"}
                        slotProps={{ textField: { size: 'small' } }}
                        defaultValue={dayjs(endMonth)}
                        onChange={(e: any) => setEndMonth(moment(e.$d).format("YYYYMM"))}
                    />
                </div>
            </LocalizationProvider>
        </>
    );
}