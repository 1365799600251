import { theme } from "constants/theme";
import styled from "styled-components";

export const ContentBox = styled.div<{ $isMyEdit?: boolean; }>`
    width: 1000px;
    /* max-height: 590px; */
    display: flex;
    gap : 40px;
    flex-direction: column;
    padding : 20px;
    padding-left: 45px;
    /* padding-right : 5px; */
    overflow-y: auto;

    /* background-color: red; */

    @media (${theme.MOBILE}) {
        width: 320px;
        height: 70vh;
        /* height: ${(props) => props.$isMyEdit ? "560px" : "500px"}; */
        gap : 0;
        padding : 0;

        @media (orientation: landscape){
        width: 500px;
        overflow-y: scroll;
        }
    }
`;

export const TitleSection = styled.div`
    flex : 0.5;
    font-size: 20px;
    font-style: normal;
    font-weight: 666;
    line-height: 34px; /* 170% */
    letter-spacing: 1px;
`;

export const ContentSection = styled.div<{ $isMyEdit?: boolean; }>`
    /* background : red; */
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0;
    /* grid-gap: 30px; */
    @media (${theme.MOBILE}) {
        grid-template-columns: none;
        margin-top : 10px;
    }
`;

export const BtnSection = styled.div<{ $isMyEdit?: boolean; }>`
    flex : 1;
    display: flex;
    gap : 10px;
    margin-right : 50px;
    margin-top : 30px;
    justify-content: right;

    @media (${theme.MOBILE}) {
        margin-right : 0;
        margin-top : ${(props) => props.$isMyEdit ? "" : "10px"};
    }
`;

export const Title = styled.div`
    font-size: 20px;
    font-style: normal;
    font-weight: 666;
    line-height: 34px; /* 170% */
    letter-spacing: 1px;
`;

export const CheckBoxSection = styled.div`
    margin-top: 10px;
    /* width: 100%; */
    color: var(--Color-Grayscale-30, #707070);
    font-family: SamsungOne;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px; /* 114.286% */
    text-transform: capitalize;
`;