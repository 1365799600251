import { useContext, useEffect, useRef, useState, DragEvent } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';

import CustomButton from "components/Button/CustomButton";
import SettingDropDown from "components/DropDown/SettingDropDown";
import CustomDatePicker from "components/picker/CustomDatePicker";

import { RenderContext } from "hooks/context";

import { changeTvSetting, previewTv } from "apis/TvStatus/TvStatusApi";

import { INPUT_DISABLED_VALUE, INPUT_LABEL_VALUE, INPUT_MIXED_VALUE, INPUT_VALUE, INPUT_VALUE_TITLE } from "constants/CssConstant";
import { NPPTIME, NPPTIME_MIXED } from "constants/NppLoopConstant";
import { IMAGETIME, IMAGETIME_MIXED } from "constants/ImageDurationConstant";

import * as S from "./SettingModal.style";
import { MediaType } from "util/media";
import { AddSection } from "./component/AddSection";
import CloseAlert from "components/alert/CloseAlert";
import { PROMOTION_OPTION, PROMOTION_OPTION_MIXED } from "constants/ContentConstant";
import LoadingAlert from "components/alert/LoadingAlert";
import { isImg, isVideo } from "util/MyRegExp";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    p: 3,
    color: "#525457"
};

interface ModalProps {
    isOpen: boolean;
    isNew?: boolean;
    onClose: () => void;
    selectedData?: any;
}

export default function SettingModal({ isNew, isOpen, onClose, selectedData }: ModalProps) {
    const userInfo = JSON.parse(localStorage.getItem("User") || "{}");
    console.log(userInfo, 'userInfo');
    const media = MediaType();
    const {
        isCancel,
        renderAction,
        renderOpen,
        openCancel,
        closeCancel,
        closeNpp,
        closeNppTable,
    } = useContext(RenderContext);
    const [isMixedSub, setIsMixedSub] = useState<boolean>(false);

    const mixedSub = () => {
        for (let i = 0; i < selectedData.length; i++) {
            if (selectedData[0].subsidiary != selectedData[i].subsidiary) {
                setIsMixedSub(true);
                break;
                console.log('mix');
            }
        }
    };

    const multuSetting = selectedData?.length > 1 ? true : false;

    const [getNppValue, setNppValue] = useState<string>(selectedData.length > 1 ? "0" : selectedData[0]?.loopType);
    const [getImgValue, setImgValue] = useState<string>(selectedData.length > 1 ? "0" : selectedData[0]?.imgDurationTime);
    const [getTypeValue, setTypeValue] = useState<string>(selectedData.length > 1 ? "0" : selectedData[0]?.playType); //todo

    // const [inputValue, setInputValue] = useState<string>(selectedData.length > 1 ? "0" : selectedData[0].imgDurationTime);
    const [inputValue, setInputValue] = useState<string>("");
    const [inputEror, setInputEror] = useState<boolean>(false);

    const [isInput, setIsInput] = useState<boolean>(false);

    const [getStartDay, setStartDay] = useState<string>(selectedData.length > 1 ? "0" : selectedData[0]?.startTime);
    const [getEndDay, setEndDay] = useState<string>(selectedData.length > 1 ? "0" : selectedData[0]?.endTime);

    const [getPdp, setPdp] = useState<string>(selectedData.length > 1 ? "Mixed" : selectedData[0]?.pdp);

    const [IsSelectedImg, setIsSelectedImg] = useState<boolean>(false);
    const [IsSelectedVideo, setIsSelectedVideo] = useState<boolean>(false);
    const [selectedArray, setSelectArray] = useState<any>([]);

    const [imgData, setImgData] = useState<any>({
        displayImgs: multuSetting ? [] : selectedData[0].img,
        uploadImgs: [],
        existImgs: multuSetting ? [] : selectedData[0].img
    });

    const [videoData, setVideoData] = useState<any>({
        displayImgs: multuSetting ? [] : selectedData[0].video,
        uploadImgs: [],
        existImgs: multuSetting ? [] : selectedData[0].video
    });

    const [isAble, setIsAble] = useState<boolean>(false);
    const [isAbleAction, setIsAbleAction] = useState<boolean>(true);

    const [replaceImg, setReplaceImg] = useState<boolean>(selectedData.length > 1 ? true : false);
    const [replaceVideo, setReplaceVideo] = useState<boolean>(selectedData.length > 1 ? true : false);

    const updateDuid: any = [];

    console.log(getStartDay, getEndDay);

    const updateBody: any = {
        "loopType": getNppValue == "0" ? undefined : getNppValue,
        "imgDurationTime": getImgValue == "0" ? undefined : isInput ? Number(inputValue) : getImgValue,
        "periodStartTime": getStartDay === "0" ? undefined : getStartDay,
        "periodEndTime": getEndDay === "0" ? undefined : getEndDay,
        "playType": getTypeValue === "0" ? undefined : getTypeValue,
        "pdp": getPdp === "Mixed" ? undefined : getPdp,
    };

    // useEffect(()=>{
    //     if(getPdp === ""){
    //         setPdp("www.samsung.com")
    //     }
    // },[getPdp])

    useEffect(() => {
        if (getImgValue != null) {
            if (IMAGETIME.find((x) => x.value === getImgValue)) {
                setIsInput(false);
            } else {
                if (selectedData.length > 1) {
                    setInputValue("Mixed");
                } else {
                    setInputValue(selectedData[0]?.imgDurationTime);
                    setImgValue("Input the time");

                }
            }
        }
    }, []);

    useEffect(() => {
        if (getImgValue === "Input the time") {
            setIsInput(true);
        } else {
            setIsInput(false);
        }
    }, [getImgValue]);


    useEffect(() => {
        if (multuSetting) {
            if (isInput) {
                if (inputValue === "" || inputValue === "Mixed") {
                    setIsAble(false);
                } else {
                    if (Number(inputValue) > 60) {
                        setIsAble(false);
                        setInputEror(true);
                    } else {
                        setIsAble(true);
                        setInputEror(false);
                    }
                }
            } else {
                setIsAble(true);
            }
        } else {
            if (getStartDay > getEndDay) {
                setIsAble(false);
            } else {
                if (isInput) {
                    if (inputValue === "") {
                        setIsAble(false);
                    } else {
                        if (Number(inputValue) > 60) {
                            setInputEror(true);
                            setIsAble(false);
                        } else {
                            setInputEror(false);
                            if (userInfo?.Group.isDevmode) {
                                if (Object.values(updateBody).includes(null)
                                    // || imgData.displayImgs.length === 0
                                ) {
                                    setIsAble(false);
                                } else {
                                    setIsAble(true);
                                }
                            } else {
                                if (updateBody.loopType === null ||
                                    updateBody.imgDurationTime === null ||
                                    updateBody.periodStartTime === undefined ||
                                    updateBody.periodEndTime === undefined ||
                                    updateBody.playType === undefined ||
                                    updateBody.playType === null
                                    // ||imgData.displayImgs.length === 0
                                ) {
                                    setIsAble(false);
                                } else {
                                    setIsAble(true);
                                }
                            }
                        }
                    }
                } else {
                    console.log(updateBody, 'body');
                    if (userInfo?.Group.isDevmode) {
                        if (Object.values(updateBody).includes(null)) {
                            // if (Object.values(updateBody).includes(null) || imgData.displayImgs.length === 0) {
                            setIsAble(false);
                        } else {
                            setIsAble(true);
                        }
                    } else {
                        if (updateBody.loopType === null ||
                            updateBody.imgDurationTime === null ||
                            updateBody.periodStartTime === undefined ||
                            updateBody.periodEndTime === undefined ||
                            updateBody.playType === undefined ||
                            updateBody.playType === null
                            // ||imgData.displayImgs.length === 0
                        ) {
                            setIsAble(false);
                        } else {
                            setIsAble(true);
                        }
                    }
                }
            }
        }
    }, [imgData, updateBody, isInput]);


    const checkId = (data: any) => {
        let str = '';
        for (let i = 0; i < 3; i++) {
            str += Math.floor(Math.random() * 10);
        }
        if (data.displayImgs.includes(str)) {
            return str + 1;
        } else {
            return str;
        }

    };

    const selecedFile = (e: any) => {
        if (isImg.test(e.target.files[0].name)) {
            setReplaceImg(false);
            setIsSelectedImg(true);
            setImgData({
                ...imgData, uploadImgs: [...imgData.uploadImgs, e.target.files[0]], displayImgs: [...imgData.displayImgs,
                {
                    id: checkId(imgData),
                    order: null,
                    File: {
                        originalName: e.target.files[0].name,
                    },
                    upload: true
                }]
            });
            setSelectArray([...selectedArray, e.target.files[0]]);
        }
        else {
            alert("The file you provided is not an image file.\nPlease upload an image (JPG, JPEG, GIF, PNG,SVG).");
        }
    };

    const selecedVideo = (e: any) => {
        if (isVideo.test(e.target.files[0].name)) {
            setReplaceVideo(false);
            setIsSelectedVideo(true);
            setVideoData({
                ...videoData, uploadImgs: [...videoData.uploadImgs, e.target.files[0]], displayImgs: [...videoData.displayImgs,
                {
                    id: checkId(videoData),
                    order: null,
                    File: {
                        originalName: e.target.files[0].name,
                    },
                    upload: true
                }]
            });
            setSelectArray([...selectedArray, e.target.files[0]]);
        } else {
            alert("The file you provided is not an video file.\nPlease upload an video (avi, wmv, mov, mp4, flv, asf).");
        }
    };

    useEffect(() => {
        if (imgData?.displayImgs?.length === 0) {
            setIsSelectedImg(false);
            // console.log("emtp");
        }
    }, [imgData.displayImgs]);

    const useStyles = makeStyles(theme => ({
        root: {
            "& .MuiFormLabel-root": INPUT_DISABLED_VALUE,
            "& .MuiInputLabel-shrink": {
                fontSize: 22,//todo
                lineHeight: 1.4
            },
            "& .MuiInput-input": {
                marginTop: 5
            }
        }
    }));

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const updataTvsetting = async () => {
        if (isAble) {
            if (isAbleAction) {
                setIsLoading(true);
                try {
                    selectedData.map((x: any, idx: number) => (
                        updateDuid.push(x.id)
                    ));
                    setTimeout(async () => {
                        try {
                            setIsAbleAction(false);
                            const response = await changeTvSetting(updateDuid, updateBody, imgData, videoData, replaceImg, multuSetting);
                            console.log(response, 'tv update');
                            if (response.isSuccess) {
                                setIsLoading(false);
                                renderAction();
                                renderOpen();
                                onClose();
                                closeNpp();
                                closeNppTable();
                            } else {
                                setIsAbleAction(true);
                                setIsLoading(false);
                                alert("TV & NPP Setting Error.\nPlease Check Setting");
                            }
                        } catch (e: any) {
                            setIsAbleAction(true);
                            setIsLoading(false);
                            alert("TV & NPP Setting Error.\nPlease Check Setting");
                        }
                    }, 1500);
                } catch (e: any) {
                    setIsAbleAction(true);
                    setIsLoading(false);
                    alert("TV & NPP Setting Error.\nPlease Check Setting");
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    useEffect(() => {
        selectedData.length > 1 && mixedSub();
    }, [updateBody]);

    const previewAction = async () => {
        const previewList = [];
        for (let i = 0; i < selectedData.length; i++) {
            previewList.push(selectedData[i].id);
        }
        try {
            const inputresponse = await previewTv(previewList);
            if (inputresponse.isSuccess) {
            }
        } catch (e: any) {
            console.log(e, "use delete error");
        }
    };

    const showtooltip = () => {
        if (media != "MOBILE") {
            return false;
        } else {
            if (multuSetting) {
                if (IsSelectedImg) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        console.log(value, "dddd");
        if (/^\d*$/.test(value)) {
            setInputValue(value);
        }
    };

    return (
        <div>
            <Modal
                open={isOpen}>
                <Box sx={style}>
                    <S.ContentBox height={userInfo?.Group.isDevmode ? "1000px" : "800px"} >
                        {media === "MOBILE" ?
                            null
                            :
                            <>
                                <S.Title>
                                    TV & NPP Setting
                                </S.Title>
                                <S.ContentSection height="27%" isInput>
                                    <S.TitleSection>
                                        TV Information
                                    </S.TitleSection>

                                    <S.ContentContainer>
                                        {/* left */}
                                        <S.SectionDivsion mgTop="10px">
                                            <TextField
                                                variant="standard"
                                                label="Subsidary"
                                                type="text"
                                                className={classes.root}
                                                // value={selectedData[0]?.subsidiary}
                                                value={isMixedSub ? "Mixed" : selectedData[0]?.subsidiary}
                                                sx={{
                                                    width: "100%", input: isMixedSub ? INPUT_MIXED_VALUE : INPUT_VALUE,
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#707070",
                                                    },
                                                    //   "& .MuiInputLabel-root": { color: "green" },
                                                    borderBottom: "1px solid black",
                                                }}
                                                aria-readonly
                                                disabled
                                                InputProps={{ disableUnderline: true }}
                                            />
                                            <TextField
                                                variant="standard"
                                                label="Store"
                                                type="text"
                                                className={classes.root}
                                                value={multuSetting ? "Mixed" : selectedData[0]?.storeName}
                                                sx={{
                                                    width: "100%", input: isMixedSub ? INPUT_MIXED_VALUE : INPUT_VALUE,
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#707070",
                                                    },
                                                    //   "& .MuiInputLabel-root": { color: "green" },
                                                    borderBottom: "1px solid black",
                                                    marginTop: "15px",
                                                }}
                                                aria-readonly
                                                disabled
                                                InputProps={{ disableUnderline: true }}
                                            />
                                            <TextField
                                                variant="standard"
                                                label="Product Detail Page"
                                                type="text"
                                                className={classes.root}
                                                placeholder="https://www.samsung.com/sec/tvs"
                                                value={getPdp}
                                                onClick={() => {if(getPdp === "Mixed"){setPdp("")}}}
                                                onChange={(e) => setPdp(e.target.value)}
                                                onBlur={() => {
                                                    if (getPdp === "") {
                                                        setPdp("https://www.samsung.com/sec/tvs");
                                                    }
                                                }}
                                                sx={{
                                                    width: "100%", input: isMixedSub ? INPUT_MIXED_VALUE : INPUT_VALUE,
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#202020",
                                                    },
                                                    borderBottom: "1px solid black",
                                                    marginTop: "15px",
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    inputProps: { style: { color: "#202020" } }
                                                }}
                                                InputLabelProps={{
                                                    style: { color: "#525457" }
                                                }}
                                            />
                                        </S.SectionDivsion>

                                        <S.SectionDivsion mgTop="10px">
                                            <TextField
                                                variant="standard"
                                                label="Retailer"
                                                type="text"
                                                className={classes.root}
                                                value={multuSetting ? "Mixed" : selectedData[0]?.retailer}
                                                sx={{
                                                    width: "100%", input: isMixedSub ? INPUT_MIXED_VALUE : INPUT_VALUE,
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#707070",
                                                    },
                                                    //   "& .MuiInputLabel-root": { color: "green" },
                                                    borderBottom: "1px solid black",
                                                }}
                                                aria-readonly
                                                disabled
                                                InputProps={{ disableUnderline: true }}
                                            />
                                            <TextField
                                                variant="standard"
                                                label="TV Name"
                                                type="text"
                                                className={classes.root}
                                                value={multuSetting ? "Mixed" : selectedData[0]?.tvName}
                                                sx={{
                                                    width: "100%", input: isMixedSub ? INPUT_MIXED_VALUE : INPUT_VALUE,
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#707070",
                                                    },
                                                    //   "& .MuiInputLabel-root": { color: "green" },
                                                    borderBottom: "1px solid black",
                                                    marginTop: "15px",
                                                }}
                                                aria-readonly
                                                disabled
                                                InputProps={{ disableUnderline: true }}
                                            />

                                        </S.SectionDivsion>
                                    </S.ContentContainer>

                                </S.ContentSection>

                            </>
                        }

                        <S.ContentSection height={userInfo?.Group.isDevmode ? "50%" : "350px"} isInput>
                            <S.TitleSection>
                                NPP Information
                            </S.TitleSection>

                            <S.ContentContainer>
                                {/* left */}
                                <S.SectionDivsion >
                                    {media === "MOBILE" &&
                                        <TextField
                                            variant="standard"
                                            label="Product Detail Page"
                                            type="text"
                                            className={classes.root}
                                            placeholder="https://www.samsung.com/sec/tvs"
                                            value={getPdp}
                                            onClick={() => {if(getPdp === "Mixed"){setPdp("")}}}
                                            onChange={(e) => setPdp(e.target.value)}
                                            onBlur={() => {
                                                if (getPdp === "") {
                                                    setPdp("https://www.samsung.com/sec/tvs");
                                                }
                                            }}
                                            sx={{
                                                width: "100%", input: isMixedSub ? INPUT_MIXED_VALUE : INPUT_VALUE,
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#202020",
                                                },
                                                borderBottom: "1px solid black",
                                                marginTop: "15px",
                                                marginBottom: "15px",
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                                inputProps: { style: { color: "#202020" } }
                                            }}
                                            InputLabelProps={{
                                                style: { color: "#525457" }
                                            }}
                                        />
                                    }

                                    <SettingDropDown
                                        label="NPP Loop Type"
                                        setValue={setNppValue}
                                        option={multuSetting ? NPPTIME_MIXED : NPPTIME}
                                        value={getNppValue}
                                        isPoint
                                        isMixed={multuSetting}
                                        width={media === "MOBILE" ? "100%" : "100%"}
                                    />
                                    <S.SubTitle mgTop="15px">
                                        <Typography sx={{ color: INPUT_VALUE_TITLE }}>
                                            Promotion Period<span style={{ color: "red" }}> *</span>
                                        </Typography>
                                        <CustomDatePicker
                                            startDay={getStartDay}
                                            endDay={getEndDay}
                                            setStartDay={setStartDay}
                                            setEndDay={setEndDay}
                                            isMixed={multuSetting}
                                        />
                                    </S.SubTitle>

                                    <SettingDropDown
                                        label="Promotion Contents Duration Time"
                                        setValue={setImgValue}
                                        option={multuSetting ? IMAGETIME_MIXED : IMAGETIME}
                                        value={getImgValue}
                                        isMixed={multuSetting}
                                        isPoint
                                        width={media === "MOBILE" ? "100%" : "100%"}
                                        mgTop="20px"
                                    />
                                    {
                                        isInput &&
                                        <TextField
                                            variant="standard"
                                            placeholder={"Input the time in seconds"}
                                            type="text"
                                            className={classes.root}
                                            value={inputValue}
                                            sx={{
                                                width: "100%", input: isMixedSub ? INPUT_MIXED_VALUE : INPUT_VALUE,
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#202020",
                                                },
                                                marginTop: "15px",
                                            }}
                                            aria-readonly
                                            InputProps={{ disableUnderline: false }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={(e) => handleChange(e)}
                                            onClick={() => setInputValue("")}
                                            error={inputEror}
                                            helperText={inputEror && "Input until 60"}
                                        />
                                    }

                                </S.SectionDivsion>

                                {/* right */}
                                <S.SectionDivsion >
                                    <SettingDropDown
                                        label="Promotion Contents Option"
                                        setValue={setTypeValue}
                                        option={multuSetting ? PROMOTION_OPTION_MIXED : PROMOTION_OPTION}
                                        value={getTypeValue}
                                        isPoint
                                        isMixed={multuSetting}
                                        width={media === "MOBILE" ? "100%" : "100%"}
                                    />

                                    <S.SubTitle mgTop="15px">
                                        <div style={{ display: "flex", position: "relative" }}>
                                            <div style={{ height: "30px" }}>
                                                <Typography sx={{ color: INPUT_VALUE_TITLE }}>
                                                    Promotion Image Files
                                                    {/* Promotion Image Files<span style={{ color: "red" }}> *</span> */}
                                                </Typography>
                                            </div>

                                            <label htmlFor="upload-photo" style={{ marginLeft: "auto" }}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="upload-photo"
                                                    name="upload-photo"
                                                    type="file"
                                                    onChange={(e) => selecedFile(e)}
                                                    accept=".jpg, .jpeg, .png, .svg .gif"
                                                />
                                                <Tooltip title={
                                                    <span style={{ fontSize: "12px" }}>
                                                        When adding new files<br />existing ones will be deleted.
                                                    </span>
                                                }
                                                    arrow
                                                    open={showtooltip()}
                                                >
                                                    <S.ADDTEXT>
                                                        ADD FILES
                                                    </S.ADDTEXT>
                                                </Tooltip>

                                            </label>
                                        </div>

                                        {multuSetting &&
                                            <>
                                                {
                                                    media === "MOBILE" ?
                                                        <>
                                                        </>
                                                        :
                                                        <span style={{ fontSize: "14px", color: "#FF7800" }}>
                                                            When adding new files,existing ones will be deleted.
                                                        </span>
                                                }

                                            </>
                                        }

                                        <S.ADDsection>
                                            {
                                                imgData?.displayImgs?.length > 0 ?
                                                    <>
                                                        <AddSection
                                                            imgData={imgData}
                                                            isMulti={multuSetting}
                                                            setData={setImgData}
                                                            setReplaceImg={setReplaceImg}
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        {multuSetting ?
                                                            <Typography
                                                                sx={{
                                                                    color: INPUT_MIXED_VALUE,
                                                                    fontSize: "24px"
                                                                }}
                                                            >
                                                                Mixed
                                                            </Typography>
                                                            :
                                                            <>
                                                                <PhotoOutlinedIcon />
                                                                <div>
                                                                    No Images
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: "12px" }}>
                                                                    Click
                                                                    <label htmlFor="upload-photo" style={{ marginLeft: "auto" }}>
                                                                        <input
                                                                            style={{ display: 'none' }}
                                                                            id="upload-photo"
                                                                            name="upload-photo"
                                                                            type="file"
                                                                            onChange={(e) => selecedFile(e)}
                                                                            accept=".jpg, .jpeg, .png, .svg"
                                                                        />
                                                                        <S.ADDTEXTInBox>
                                                                            ADD FILES
                                                                        </S.ADDTEXTInBox>
                                                                    </label>
                                                                    button to upload images.
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                            }
                                        </S.ADDsection>
                                    </S.SubTitle>
                                    {userInfo?.Group.isDevmode &&

                                        <S.SubTitle mgTop="10px">
                                            <div style={{ display: "flex", position: "relative" }}>
                                                <div style={{ height: "30px" }}>
                                                    <Typography sx={{ color: INPUT_VALUE_TITLE }}>
                                                        Promotion Content Files
                                                    </Typography>
                                                </div>
                                                <label htmlFor="upload-video" style={{ marginLeft: "auto" }}>
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="upload-video"
                                                        name="upload-video"
                                                        type="file"
                                                        onChange={(e) => selecedVideo(e)}
                                                        accept="video/*"
                                                    />
                                                    <Tooltip title={
                                                        <span style={{ fontSize: "12px" }}>
                                                            When adding new files<br />existing ones will be deleted.
                                                        </span>
                                                    }
                                                        arrow
                                                        open={showtooltip()}
                                                    >
                                                        <S.ADDTEXT style={{ color: "#888888" }}>
                                                            ADD FILES
                                                        </S.ADDTEXT>
                                                    </Tooltip>

                                                </label>
                                            </div>

                                            {multuSetting &&
                                                <>
                                                    {
                                                        media === "MOBILE" ?
                                                            <>
                                                            </>
                                                            :
                                                            <span style={{ fontSize: "14px", color: "#FF7800" }}>
                                                                When adding new files,existing ones will be deleted.
                                                            </span>
                                                    }

                                                </>
                                            }

                                            <S.ADDsection>
                                                {
                                                    videoData?.displayImgs?.length > 0 ?
                                                        <>
                                                            <AddSection
                                                                imgData={videoData}
                                                                isMulti={multuSetting}
                                                                setData={setVideoData}
                                                                setReplaceImg={setReplaceVideo}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            {multuSetting ?
                                                                <Typography
                                                                    sx={{
                                                                        color: INPUT_MIXED_VALUE,
                                                                        fontSize: "24px"
                                                                    }}
                                                                >
                                                                    Mixed
                                                                </Typography>
                                                                :
                                                                <>
                                                                    <PhotoOutlinedIcon />
                                                                    <div>
                                                                        No Video
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: "12px" }}>
                                                                        Click
                                                                        <label htmlFor="upload-video" style={{ marginLeft: "auto" }}>
                                                                            <input
                                                                                style={{ display: 'none' }}
                                                                                id="upload-video"
                                                                                name="upload-video"
                                                                                type="file"
                                                                                onChange={(e) => selecedVideo(e)}
                                                                                accept="video/*"
                                                                            />
                                                                            <S.ADDTEXTInBox style={{ color: "#888888" }}>
                                                                                ADD FILES
                                                                            </S.ADDTEXTInBox>
                                                                        </label>
                                                                        button to upload Video.
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                }
                                            </S.ADDsection>
                                        </S.SubTitle>
                                    }
                                </S.SectionDivsion>

                            </S.ContentContainer>

                        </S.ContentSection>

                        <S.BtnSection mgTop={userInfo?.Group.isDevmode ? "280px" : "60px"}>
                            <CustomButton
                                title="PREVIEW"
                                classname="preview-btn"
                                icon={
                                    <></>
                                }
                                btnClick={() => previewAction()}
                                Width="106"
                                Height="40"
                            />
                            <CustomButton
                                title="SAVE"
                                classname={isAble ? "save-btn" : "disable-check"} //disable-check
                                icon={
                                    <></>
                                }
                                btnClick={() => updataTvsetting()}
                                Width="79"
                                Height="40"
                            />
                            <CustomButton
                                title="CANCEL"
                                classname="cancel-btn"
                                icon={
                                    <></>
                                }
                                Width="100"
                                Height="40"
                                // btnClick={() => confirmOpen()} />
                                btnClick={() => openCancel()} />
                        </S.BtnSection>
                    </S.ContentBox>
                    {isCancel
                        &&
                        <CloseAlert
                            isOpen={isCancel}
                            onClose={() => closeCancel()}
                        />
                    }
                    {isLoading
                        &&
                        <LoadingAlert
                            isOpen={isLoading}
                            onClose={() => setIsLoading(false)}
                        />
                    }
                </Box>
            </Modal>
        </div>
    );
}

