import { theme } from "constants/theme";
import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Wrapper = styled.div`
    position : relative;
    width: 620px;
    height: 551px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    box-shadow: 0 0 5px 1px #888888;

    @media (${theme.MOBILE}) {
        width: 100vw;
        box-shadow: none;
    }
`

export const TextBox = styled.div`
    width:  90%;
    /* height: 210px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    color: #000;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (${theme.MOBILE}) {
        font-size: 30px;
        font-style: normal;
        font-weight: 666;
        line-height: 38px; 
    }
`

export const InputBox = styled.div`
    height: 150px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    
    @media (${theme.MOBILE}) {
        width:  90%;
        max-width: 343px;
        margin-top : 54px;
    }

    `

export const Btn = styled.div`
    width: 90%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap : 20px;
    margin-top: 20px;
    color : white;
    background : black;
    border-radius: 5px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    .disabled-login {
        color : white;
        background : black;
    }

    .abled-login {
        color : white;
        background : black;
    }
`
