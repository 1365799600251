import ChartBox from "./ChartBox";
import Chart from "react-apexcharts";
import { ChartProps } from "./ChartInterface";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ExportBtn from "components/ExportBtn/ExportBtn";
import { CHART_COLORS } from "constants/ChartColor";
import PieChartBox from "./PieChartBox";

export default function NumberApp({ getData, startDate, endDate, dropSub }: ChartProps) {

    const data: any = {
        series: getData ? getData.map((x: any) => x.value) : [],
        options: {
            noData: {
                text: "No data",
                align: "center",
                verticalAlign: "middle",
            },
            chart: {
                width: 380,
                // height : 300,
                type: 'pie',
                toolbar: {
                    show: false
                },

            },
            labels: getData ? getData.map((x: any) => x.key) : [],
            responsive: [{
                breakpoint: 1750,
                options: {
                    chart: {
                        width: "150%"
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            colors : CHART_COLORS
        },
    };


    return (
        <>
            <PieChartBox
                title="Number of New TVs Installation by App"
                wd="40%"
                ht="510px"
                Topcontents={
                    <ExportBtn
                        startDate={startDate}
                        endDate={endDate}
                        subCode={dropSub}
                        type="appnumber"
                    />
                }
                contents={
                    <Chart
                        options={data.options}
                        series={data.series}
                        type="pie"
                        // width="500"
                        width="180%"
                        // height="390"
                    />
                } />

        </>
    );
}