import dayjs, { Dayjs } from 'dayjs';
import styled from "styled-components";
import moment from "moment";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from "@material-ui/core/styles";

import { INPUT_MIXED_VALUE, INPUT_VALUE } from "constants/CssConstant";
import { MediaType } from "util/media";
import { theme } from "constants/theme";
import { TextField } from "@mui/material";
import { useState } from "react";

interface DateProps {
  startDay: string;
  endDay: string;
  setStartDay: Function;
  setEndDay: Function;
  isMixed?: boolean;
}

export default function CustomDatePicker({ startDay, endDay, setStartDay, setEndDay, isMixed }: DateProps) {
  const today = dayjs();
  const tomorrow = dayjs().add(1, 'day');

  const useStyles = makeStyles((theme: any) => ({
    root: {
      "& .MuiOutlinedInput-root": INPUT_VALUE,
      '& fieldset': {
        borderColor: 'black',
        borderTop: '#e8e8e8',
        borderLeft: '#e8e8e8',
        borderRight: '#e8e8e8',
        borderRadius: '0px',
      },
      // '& .Mui-focused': {
      // }
    }
  }));
  const classes = useStyles();

  const [isStartFocus,setIsStartFocus] = useState<boolean>(true);
  const [isEndFocus,setIsEndFocus] = useState<boolean>(true);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container onKeyDown={(e : any)=>e.preventDefault()} >
        <DatePicker
          minDate={today}
          defaultValue={isMixed ? null: dayjs(startDay)}
          label={isMixed ? isStartFocus ? <span style={INPUT_MIXED_VALUE}>Mixed</span> : isStartFocus : false}
          className={classes.root}
          onChange={(e: any) => setStartDay(moment(e.$d).format("YYYY-MM-DD"))}
          onSelectedSectionsChange={()=> setIsStartFocus(false)}
        />
        <div style={{ marginTop: "20px" }}>
          ~
        </div>
        <DatePicker
          minDate={dayjs(startDay)}
          label={isMixed ? isEndFocus ? <span style={INPUT_MIXED_VALUE}>Mixed</span> : isEndFocus : false}
          className={classes.root}
          defaultValue={isMixed ? null : dayjs(endDay)}
          onChange={(e: any) => setEndDay(moment(e.$d).format("YYYY-MM-DD"))}
          onSelectedSectionsChange={()=> setIsEndFocus(false)}
        />
      </Container>
    </LocalizationProvider>
  );
}

const Container = styled.div`
  display: flex;
  gap : 10px;
  align-items: center;
  /* margin-top: 20px; */

  @media (${theme.MOBILE}) {
    width: 320px;
    margin-top: 0;
  };
`;