import { useState, useContext } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import useIsMedia from "hooks/useIsMedia";
import styled from "styled-components";

interface AlertProps {
    isOpen: any;
    onClose: () => void;
}

export default function LoadingAlert({ isOpen, onClose }: AlertProps) {

    const isMobile = useIsMedia();

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: "5px",
        boxShadow: 24,
        p: 3,
    };

    return (
        <div>
            <Modal
                open={isOpen}>
                <Box sx={style}>
                    <TextSection>
                        It's in progress. {isMobile && <br/>} Please wait a moment.
                    </TextSection>
                </Box>
            </Modal>
        </div>
    );
}


const TextSection = styled.div`
    width: 100%;
    font-family: SamsungOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
`;