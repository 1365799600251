import { useState, useContext, useEffect } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { deleteSub, getSubTableList } from "apis/Admin/AdminApi";
import { RenderContext } from "hooks/context";
import { OverFlowBox } from "pages/common.style";
import * as S from "pages/common.style";
import PopupAlert from "components/alert/PopupAlert"; import PageTop from "components/PageTop/PageTop";
import DataTable from "components/Table/DataTable";
import ConfirmAlert from "components/alert/ConfirmAlert";
import SubsidiaryModifyModal from "components/modal/SubsidiaryModifyModal";

export default function Subsidiary() {
  const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");

  const {
    isRender,
    isSubsidiary,
    renderAction,
    openSub
  } = useContext(RenderContext);
  const [selectedData, setSelectedData] = useState<any>();

  const [isError, setIsError] = useState<boolean>(false);
  const [isUsed, setIsUsed] = useState<boolean>(false);
  const [deleteName, setDeleteName] = useState<string>("");

  const [deleteID, setDeleteID] = useState<string>("");
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [rowData, setRowData] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [take, setTake] = useState<number>(10);

  useEffect(() => {
    requestList();
  }, [text, page, take, isRender]);

  const requestList = async () => {
    try {
      const response: any = await getSubTableList("-id", text, page + 1, take);
      setRowData(response.rowData);
      setPageCount(response.pageData?.itemCount);
    } catch (e: any) {
      console.log(e, 'getList Error');
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'subsidiary', headerName: 'Subsidiary',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.subsidiary}
        </OverFlowBox>
      )
    },
    {
      field: 'region', headerName: 'Region',
      // minWidth: 50,
      flex: 0.8,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.region}
        </OverFlowBox>
      )
    },
    {
      field: 'smartThings', headerName: 'SmartThings',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.smartThings}
        </OverFlowBox>
      )
    },
    {
      field: 'magazine', headerName: 'MagazineLink',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.magazine}
        </OverFlowBox>
      )
    },
    {
      field: 'pdpLink', headerName: 'Default PDP',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.pdpLink}
        </OverFlowBox>
      )
    },
    {
      field: 'information', headerName: 'Information',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.information}
        </OverFlowBox>
      )
    },

    {
      field: 'action', headerName: 'Action',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <S.BtnBox>
          <S.BtnWrapper>
            <EditOutlinedIcon onClick={(e: any) => {
              e.stopPropagation();
              console.log(row, 'btnclci');
              setSelectedData([row.row]);
              openSub();
            }}
            />
          </S.BtnWrapper>
          <S.BtnWrapper>
            <DeleteOutlineOutlinedIcon onClick={(e: any) => {
              e.stopPropagation();
              setDeleteID(row.row.id);
              openCloseModal();
            }}
            />
          </S.BtnWrapper>
        </S.BtnBox>
      )
    }
  ];

  const seachkey = [
    "subsidiary",
    "region",
    "information",
  ];

  const [IsEdit, setIsEdit] = useState<boolean>(false);

  const deleteSubAction = async (id: string, deleteName?: string) => {

    try {
      const inputresponse = await deleteSub(deleteID);
      if (inputresponse.isSuccess) {
        setIsError(true);
        renderAction();
      } else {
        setIsUsed(true);
      }
    } catch (e: any) {
      console.log(e, "use delete error");
    }
  };

  const openCloseModal = () => {
    if (userInfo?.group.name != "SUPER") {
      alert("no permission delete");
      return;
    } else {
      setIsDelete(true);
    }
  };


  return (
    <>
      <PageTop
        title="Subsidiary"
      />
      <Box
        sx={{
          width: '100%',
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(238, 238, 238, 1)",
            color: "black",
            fontFamily: "SamsungOne",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "16px"
          }
        }}
      >
        <DataTable
          setSearchText={setText}
          setSearchPage={setPage}
          rowCount={pageCount}
          setTableCount={setTake}
          apiData={rowData}
          columnData={columns}
          placeHolder={"Subsidiary, Region, Information"}
          isNewBtn
          isSubBtn
        />
      </Box>
      {/* {IsEdit && <SubsidiaryModal isOpen={IsEdit} onClose={() => setIsEdit(false)} selectedData={selectedData} />} */}
      {isSubsidiary &&
        <SubsidiaryModifyModal
          isOpen={isSubsidiary}
          onClose={() => setIsEdit(false)}
          selectedData={selectedData}
        />
      }
      {isError &&
        <PopupAlert
          isOpen={isError}
          onClose={() => setIsError(false)}
          contents={`Delete Complete`}
        />
      }
      {isUsed &&
        <PopupAlert
          isOpen={isUsed}
          onClose={() => setIsUsed(false)}
          contents={`Already used`}
        />
      }
      {isDelete &&
        <ConfirmAlert
          isOpen={isDelete}
          onClose={() => setIsDelete(false)}
          requserApi={() => deleteSubAction(deleteID)}
        />
      }
    </>
  );
}
