import PageTop from "components/PageTop/PageTop";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import * as S from "./DashBoard.style";
import Experience from "components/Chart/Experience";
import StatusApp from "components/Chart/StatusApp";
import NumberApp from "components/Chart/NumberApp";
import UsageTime from "components/Chart/UsageTime";
import Registeration from "components/Chart/Registeration";
import CustomButton from "components/Button/CustomButton";
import TableDropDown from "components/DropDown/TableDropDown";
import { useEffect, useState } from "react";
import { getMySubListAll } from "apis/Admin/AdminApi";
import MonthPicker from "components/picker/MonthPicker";
import StatusAppStack from "components/Chart/StatusAppStack";
import moment from "moment";
import {
  getAppAffiliate,
  getAppNumber,
  getAppStatus,
  getAppStatusGroup,
  getAppUsage,
  getMobileExp,
  getRegistration,
  getSettingChange,
} from "apis/DashBoard/DashBoardApi";
import { TmpText } from "pages/common.style";
import WorldMap from "components/Chart/WorldMap/WorldMap";
import WorldMapDim from "components/Chart/WorldMap/WorldMapDim";
import useIsMedia from "hooks/useIsMedia";
import { getCookie } from "util/Cookie";
import QnANotilModal from "components/modal/QnANotiModal";
import LocalNotiModal from "components/modal/LocalNotiModal";
import AffiliateChanel from "components/Chart/AffiliateChanel";
import SettingChange from "components/Chart/SettingChange";
import MobileExpStack from "components/Chart/MobileExpStack";

export default function Dashboard() {
  const userInfo = JSON.parse(localStorage.getItem("User") || "{}");

  const today = moment();
  const nowday = moment();
  const isMobile = useIsMedia();

  const [requestSub, setRequestSub] = useState<any>([]);
  const [dropSub, setDropSub] = useState<string>("");

  const [startDate, setStartDate] = useState<string>(
    today.subtract("365", "d").format("YYYYMM")
  );
  const [endDate, setEndtDate] = useState<string>(nowday.format("YYYYMM"));
  //data
  const [registrationData, setRegistrationData] = useState<any>();
  const [appGroupStatusData, setAppGroupStatusData] = useState<any>();
  const [appStatusData, setAppStatusData] = useState<any>();
  const [appNumberData, setAppNumberData] = useState<any>();
  const [appUsageData, setAppUsageData] = useState<any>();
  const [appAffiliate, setAppAffiliate] = useState<any>();
  const [appSetting, setAppSetting] = useState<any>();
  const [mobileExpData, setMobileExpData] = useState<any>();

  const [localNoti, setLocalNoti] = useState<boolean>(
    getCookie("isLocalDont") === "true" ? false : true
  );
  const [qnaNoti, setQnaNoti] = useState<boolean>(
    getCookie("isQnADont") === "true" ? false : true
  );

  const [isMobileChart, setIsMobileChart] = useState<boolean>(
    userInfo?.Permission?.viewAllSubsidiary ? true : false
  );

  const initRequestList = async () => {
    if (userInfo?.Permission?.viewAllSubsidiary) {
      const subResponse = await getMySubListAll();
      console.log(subResponse, "subResponse");
      setRequestSub(subResponse);
      setDropSub(subResponse[0]?.value);
      requestData("");
      // requestData(subResponse[0]?.value);
    } else {
      setDropSub(userInfo?.Subsidiary?.code);
      requestData(userInfo?.Subsidiary?.code);
    }
  };

  const requestData = async (sub: string) => {
    const regRes = await getRegistration(startDate, endDate, sub);
    const appGroupRes = await getAppStatusGroup(startDate, endDate, sub);
    const appStatusRes = await getAppStatus(startDate, endDate);
    const appNumberRes = await getAppNumber(startDate, endDate, sub);
    const appUsages = await getAppUsage(startDate, endDate, sub);
    const appAffiliateRes = await getAppAffiliate(startDate, endDate, sub);
    const appSettingChangeRes = await getSettingChange(startDate, endDate, sub);
    const mobileExp = await getMobileExp(startDate, endDate);

    setRegistrationData(regRes?.data);
    setAppGroupStatusData(appGroupRes?.data);
    setAppStatusData(appStatusRes?.data);
    setAppNumberData(appNumberRes?.data);
    setAppUsageData(appUsages?.data);
    setAppAffiliate(appAffiliateRes?.data);
    setAppSetting(appSettingChangeRes?.data[0].value);
    setMobileExpData(mobileExp?.data);
  };

  useEffect(() => {
    initRequestList();
  }, []);

  const moveAction = () => {
    if (dropSub === "ALL") {
      requestData("");
      setIsMobileChart(true);
    } else {
      requestData(dropSub);
      setIsMobileChart(false);
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <TmpText>
            Dashboard is not supported on mobile devices. Please use a desktop.
          </TmpText>
        </>
      ) : (
        <>
          <PageTop
            title="DashBoard"
            icon={<DashboardOutlinedIcon />}
            isRe={false}
            isSideLine={true}
          />
          <S.ChartContainer>
            <S.ColumnSection>
              <S.RowSection>
                {/* <WorldMap /> */}
                {userInfo?.Permission?.viewAllSubsidiary ? (
                  <WorldMap />
                ) : (
                  <WorldMapDim />
                )}
                <Experience />
              </S.RowSection>
            </S.ColumnSection>

            <S.ColumnSection>
              {userInfo?.Permission?.viewAllSubsidiary && (
                <TableDropDown
                  label="Subsidiary"
                  setValue={setDropSub}
                  onchange={() => null}
                  option={requestSub}
                  value={dropSub}
                  width={"180px"}
                />
              )}
              <MonthPicker
                startMonth={startDate}
                endMonth={endDate}
                setStartMonth={setStartDate}
                setEndMonth={setEndtDate}
              />
              <CustomButton
                title="MOVE"
                classname="move-btn"
                icon={<></>}
                btnClick={() => moveAction()}
                Width="85"
                Height="40"
              />
            </S.ColumnSection>

            <S.ColumnSection>
              <S.RowSection>
                {userInfo?.Permission?.viewAllSubsidiary ? (
                  <StatusAppStack
                    getData={appGroupStatusData}
                    startDate={startDate}
                    endDate={endDate}
                    dropSub={dropSub}
                  />
                ) : (
                  <StatusApp
                    getData={appStatusData}
                    startDate={startDate}
                    endDate={endDate}
                    dropSub={dropSub}
                  />
                )}
                <UsageTime
                  getData={appUsageData}
                  startDate={startDate}
                  endDate={endDate}
                  dropSub={dropSub}
                />
              </S.RowSection>
            </S.ColumnSection>

            <S.ColumnSection>
              <S.RowSection>
                <NumberApp
                  getData={appNumberData}
                  startDate={startDate}
                  endDate={endDate}
                  dropSub={dropSub}
                />
                <Registeration
                  getData={registrationData}
                  startDate={startDate}
                  endDate={endDate}
                  dropSub={dropSub}
                />
                <S.HalfSection>
                  <AffiliateChanel
                    getData={appAffiliate}
                    startDate={startDate}
                    endDate={endDate}
                    dropSub={dropSub}
                  />
                  <SettingChange
                    getData={appSetting}
                    startDate={startDate}
                    endDate={endDate}
                    dropSub={dropSub}
                  />
                </S.HalfSection>
              </S.RowSection>
            </S.ColumnSection>
            {isMobileChart === true ? (
              <MobileExpStack
                getData={mobileExpData}
                startDate={startDate}
                endDate={endDate}
                dropSub={dropSub}
              />
            ) : (
              <></>
            )}
          </S.ChartContainer>

          <QnANotilModal isOpen={qnaNoti} onClose={() => setQnaNoti(false)} />
          <LocalNotiModal
            isOpen={localNoti}
            onClose={() => setLocalNoti(false)}
          />
        </>
      )}
    </>
  );
}
