import { theme } from "constants/theme";
import styled from "styled-components";

export const ContentBox = styled.div<{ $isMyEdit?: boolean; }>`
    width: 1074px;
    /* height: 270px; */
    display: flex;
    flex-direction: column;
    padding : 20px;
    overflow-y: auto;

    @media (${theme.MOBILE}) {
        width: 320px;
        /* height: 70vh; */
        /* height: ${(props) => props.$isMyEdit ? "560px" : "500px"}; */
        gap : 0;
        padding : 10px;

        @media (orientation: landscape){
        width: 500px;
        overflow-y: scroll;
        }
    }
`;

export const TitleSection = styled.div`
    flex : 0.5;
    font-size: 20px;
    font-style: normal;
    font-weight: 666;
    line-height: 34px; /* 170% */
    letter-spacing: 1px;
`;

export const InputSection = styled.div`
    margin-top: 48px;
    margin-left: 24px;
    margin-right: 24px;
`

export const BtnSection = styled.div<{ $isMyEdit?: boolean; }>`
    flex : 1;
    display: flex;
    gap : 10px;
    margin-right : 24px;
    margin-top : 48px;
    justify-content: right;

    @media (${theme.MOBILE}) {
        margin-right : 0;
        margin-top : ${(props) => props.$isMyEdit ? "" : "10px"};
    }
`;
