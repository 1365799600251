
import { useContext, useState } from "react";

import { SvgIcon } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import CachedIcon from '@mui/icons-material/Cached';

import * as S from "./PageTop.style";

import CustomButton from "components/Button/CustomButton";

import { RenderContext } from "hooks/context";
import { MediaType } from "util/media";

interface PageTopProps {
    isRe?: boolean;
    title?: string;
    icon?: any;
    IsExcel?: boolean;
    IsRegister?: boolean;
    RegisterHandle?: Function | undefined;
    isSideLine? : boolean;
}

export default function PageTop({ title, IsExcel, icon, IsRegister, RegisterHandle, isRe ,isSideLine}: PageTopProps) {

    const { isRender, renderAction } = useContext(RenderContext);
    const media = MediaType();

    return (
        <>
            {media === "MOBILE" ?
                <></>
                :
                <S.TopSection>
                    <S.TitleBox $sideline={isSideLine}>
                        {icon}
                        {title}
                    </S.TitleBox>
                    <S.BtnBox>
                        {isRe !=false &&
                            <CustomButton
                                title="REFRESH"
                                icon={
                                    <SvgIcon component={CachedIcon} inheritViewBox />

                                }
                                btnClick={() => renderAction()}
                            />
                        }
                        {IsExcel &&
                            <CustomButton
                                title="EXCEL"
                                icon={
                                    <SvgIcon component={RefreshIcon} inheritViewBox />
                                }
                                btnClick={() => null}
                            />
                        }
                    </S.BtnBox>

                    {IsRegister &&
                        <div style={{ marginLeft: "auto" }}>
                            <CustomButton
                                title="NEW"
                                icon={
                                    <SvgIcon component={RefreshIcon} inheritViewBox />
                                }
                                btnClick={() => RegisterHandle?.(true)}
                            />
                        </div>
                    }
                </S.TopSection>
            }

        </>
    );
}