import { useState, useContext, useEffect } from "react";

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { GridColDef } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import { Box } from "@mui/material";

import { deleteUser, getMyUserList, updateUser } from "apis/Admin/AdminApi";

import { RenderContext } from "hooks/context";
import { MediaType } from "util/media";
import { OverFlowBox } from "pages/common.style";
import * as S from "pages/common.style";
import PopupAlert from "components/alert/PopupAlert";
import PageTop from "components/PageTop/PageTop";
import DataTable from "components/Table/DataTable";
import ConfirmAlert from "components/alert/ConfirmAlert";
import ModifyUserModal from "components/modal/ModifyUserModal";

export default function User() {

  const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");
  const media = MediaType();

  const {
    isUser,
    renderAction,
    openUser,
    isRender
  } = useContext(RenderContext);
  const [selectedData, setSelectedData] = useState<any>();

  const [isError, setIsError] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<string>("");
  const [isDelete, setIsDelete] = useState<boolean>(false);


  const [rowData, setRowData] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [take, setTake] = useState<number>(10);

  useEffect(() => {
    requestList();
  }, [text, page, take, isRender]);

  const requestList = async () => {
    try {
      const response: any = await getMyUserList(text, page + 1, take);
      setRowData(response.rowData);
      setPageCount(response.pageData?.itemCount);
    } catch (e: any) {
      console.log(e, 'getList Error');
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name', headerName: 'ID',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.name}
        </OverFlowBox>
      )
    },
    {
      field: 'subsidiary', headerName: 'Subsidiary',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.subsidiary}
        </OverFlowBox>
      )
    },
    {
      field: 'information', headerName: 'Information',
      minWidth: 200,
      flex: 3,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.information}
        </OverFlowBox>
      )
    },
    {
      field: 'use', headerName: 'Use',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <>
          <Switch
            checked={row.row.use}
            value={row.row.use}
            onClick={() => userUseAction(row.row.id, row.row.use)}
          />
        </>
      )
    },
    {
      field: 'action', headerName: 'Action',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <S.BtnBox>
          <S.BtnWrapper>
            <EditOutlinedIcon onClick={(e: any) => {
              e.stopPropagation();
              // console.log(row, 'btnclci');
              setSelectedData([row.row]);
              // setIsEdit(true);
              if (userInfo?.permission.createUser) {
                openUser();
              } else {
                alert("no permission edit");
                return;
              }
            }}
            />
          </S.BtnWrapper>
          <S.BtnWrapper>
            <DeleteOutlineOutlinedIcon onClick={(e: any) => {
              e.stopPropagation();
              setDeleteID(row.row.id);
              openCloseModal();
              renderAction();
            }}
            />
          </S.BtnWrapper>
        </S.BtnBox>
      )
    }
  ];
  const seachkey = [
    "name",
    "information",
    "subsidiary",
    // "authority",
  ];

  let updateUserData = {
    "status": ""
  };

  const [IsEdit, setIsEdit] = useState<boolean>(false);

  const userUseAction = async (id: string, currentState: boolean) => {
    if (!userInfo?.permission.createUser) {
      alert("no permission edit");
      return;
    }
    if (currentState) {
      updateUserData.status = "inactive";
    } else {
      updateUserData.status = "active";
    }
    try {
      const inputresponse = await updateUser(id, updateUserData);
      if (inputresponse.isSuccess) {
        console.log(inputresponse, 'use action response');
        renderAction();
      }
    } catch (e: any) {
      console.log(e, "use action error");
    }
  };


  const deleteUserAction = async (id: string, deleteName?: string) => {
    if (userInfo?.group.name != "SUPER") {
      alert("no permission delete");
      return;
    }
    try {
      const inputresponse = await deleteUser(deleteID);
      if (inputresponse.isSuccess) {
        console.log(inputresponse, 'use delete response');
        setIsError(true);
        // setDeleteName(deleteName);
        renderAction();
      }
    } catch (e: any) {
      console.log(e, "use delete error");
    }
  };


  const openCloseModal = () => {
    if (userInfo?.group.name != "SUPER") {
      alert("no permission delete");
      return;
    } else {
      setIsDelete(true);
    }
  };

  return (
    <>
      <PageTop
        title="User"
      />
      <Box
        sx={{
          width: "100%",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(238, 238, 238, 1)",
            color: "black",
            fontFamily: "SamsungOne",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "16px"
          }
        }}
      >
        <DataTable
          setSearchText={setText}
          setSearchPage={setPage}
          rowCount={pageCount}
          setTableCount={setTake}
          apiData={rowData}
          columnData={columns}
          placeHolder={"ID, Subsidiary, Information"}
          isNewBtn
        />
      </Box>
      {isUser &&
        <ModifyUserModal
          isOpen={isUser}
          onClose={() => setIsEdit(false)}
          selectedData={selectedData}
        />
      }
      {isDelete &&
        <ConfirmAlert
          isOpen={isDelete}
          onClose={() => setIsDelete(false)}
          requserApi={() => deleteUserAction(deleteID)}
        />
      }
      {isError &&
        <PopupAlert
          isOpen={isError}
          onClose={() => setIsError(false)}
          contents={`Delete Complete`}
        />
      }
    </>
  );
}
