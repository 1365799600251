import world from 'assets/world.png';
import { styled } from 'styled-components';
import ChartBox from "../ChartBox";

function WorldMapDim() {

    return (
        <ChartBox
            title="Worldmap"
            wd="40%"
            ht="530px"
            isDim
            contents={
                <>
                    <Container>
                        <Img src={world} alt="Vite logo" />
                        <TextBox>
                            <TextContent>For the purpose of understanding <br/>the status of global subsidiary.<br/>
                            It is not necessary to check from <br/>accounts other than global subsidiary.</TextContent>
                        </TextBox>
                    </Container>
                </>
            }
        />

    );
}

export default WorldMapDim;

const Container = styled.div`
    position: relative;
    margin-top: 5%;
`;

const Img = styled.img`
    width: 100%;
    height: auto;
    opacity: 0.1;
    z-index: 10;
`;

const TextBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 999;
`;

const TextContent = styled.div`
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    white-space: nowrap;
`;