import { create } from 'zustand';

export interface SidebarState {
    sidebarNav: {
      text: string;
      url: string;
      sub: {
        text: string;
        url: string;
        isVisible: boolean;
      }[] | null;
      icon: string; 
      isVisible: boolean;
    }[];
    setSidebarNav: (newSidebarNav: {
      text: string;
      url: string;
      sub: {
        text: string;
        url: string;
        isVisible: boolean;
      }[] | null;
      icon: string; 
      isVisible: boolean;
    }[]) => void;
  }

  const iconNames = {
    desktopWindowsOutlinedIcon: "DesktopWindowsOutlinedIcon",
    dashboardOutlinedIcon: "DashboardOutlinedIcon",
    manageAccountsOutlinedIcon: "ManageAccountsOutlinedIcon",
    translateIcon: "TranslateIcon",
    questionAnswerOutlinedIcon: "QuestionAnswerOutlinedIcon",
    descriptionOutlinedIcon: "DescriptionOutlinedIcon",
    TranslateIcon : "local",
    AppDownload : "appdownload"
  };

// Define your store
const useSidebarStore = create<SidebarState>((set) => ({
  sidebarNav: [
    {
      text: "Dashboard",
      url: "/dashboard",
      sub: null,
      icon: iconNames.dashboardOutlinedIcon, 
      isVisible: true,
    },
    {
      text: "TV status",
      url: "/tvstatus",
      sub: null,
      icon: iconNames.desktopWindowsOutlinedIcon, 
      isVisible: true,
    },
    {
        text: "Localization",
        url: "/localization",
        sub: null,
        icon : iconNames.TranslateIcon,
        isVisible: true,
    },
    {
      text: "Q&A",
      url: "/qna",
      sub: null,
      icon: iconNames.questionAnswerOutlinedIcon,
      isVisible: true,
    },
    {
      text: "How-to Guide",
      url: "/howtoguide",
      sub: null,
      icon: iconNames.descriptionOutlinedIcon,
      isVisible: true,
    },
    {
      text: "App Download",
      url: "/appdownload",
      sub: null,
      icon: iconNames.AppDownload,
      isVisible: true,
    },
    {
      text: "Admin",
      url: "/admin",
      icon: iconNames.manageAccountsOutlinedIcon,
      sub: [
        {
          text: "User",
          url: "/user",
          isVisible: true,
        },
        {
          text: "Subsidiary",
          url: "/subsidiary",
          isVisible: true,
        },
      ],
      isVisible: true,
    },
  ],
  setSidebarNav: (newSidebarNav : any) => set({ sidebarNav: newSidebarNav }),
}));

export default useSidebarStore;
