import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@mui/material/FormHelperText";

import { getGroupList, getMySubList, getSubList, inPutUser, updateMe, updateUser } from "apis/Admin/AdminApi";
import { getMyProfile } from "apis/AuthApi/AuthApi";

import { RenderContext } from "hooks/context";

import CustomButton from "components/Button/CustomButton";
import SettingDropDown from "components/DropDown/SettingDropDown";
import PopupAlert from "components/alert/PopupAlert";

import { INPUT_LABEL_VALUE, INPUT_VALUE } from "constants/CssConstant";
import { AUTHORITY, CREATE_AUTHORITY } from "constants/AuthConstant";
import * as S from "./UserModal.style";
import { MediaType } from "util/media";
import CloseAlert from "components/alert/CloseAlert";
import CustomCheckBox from "components/CheckBox/CustomCheckBox";
import { Checkbox } from "@mui/material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    p: 3,
};

interface ModalProps {
    isOpen: boolean;
    isNew?: boolean;
    onClose: () => void;
    selectedData?: any;
    isMyEdit?: boolean;
}


export default function ModifyUserModal({ isNew, isOpen, onClose, selectedData, isMyEdit }: ModalProps) {

    const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");

    const media = MediaType();
    const {
        isCancel,
        closeModal,
        renderAction,
        renderOpen,
        openCancel,
        closeCancel,
        closeUser,
        closeUserTable,
    } = useContext(RenderContext);


    const [getId, setId] = useState<string>(selectedData[0].name);
    const [getPw, setPw] = useState<any>("******");

    const [getSub, setSub] = useState<string>(selectedData[0].subsidiary);
    const [getGroup, setGroup] = useState<string>(selectedData[0].group);

    const [information, setInformation] = useState<string>(selectedData[0].information);

    const getEntries = isMyEdit != true && selectedData != undefined && Object.entries(selectedData[0]?.permission).map((entrie, idx) => {
        return {
            value: entrie[0],
            label: CREATE_AUTHORITY.find((x: any) => x.value === entrie[0]).label,
            checked: entrie[1]
            // entrie, idx
        };
    });
    const [authData, setAuthData] = useState<any>(selectedData != undefined ? getEntries : CREATE_AUTHORITY);

    const [prePw, setPrePw] = useState<string>("");
    const [createPw, setCreatePw] = useState<string>("");
    const [responeText, setResponseText] = useState<string>("");
    const [isAble, setIsAble] = useState<boolean>(true);
    // const [isAble, setIsAble] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isErrorModal, setIsErrorModal] = useState<boolean>(false);
    const [myData, setMyData] = useState<any>({
        loginId: "",
        authority: "",
        subsidiary: "",
        information: "",
        getGroup: "",

    });

    const requsetMyProfile = async () => {
        try {
            const response = await getMyProfile();
            // console.log(response.Subsidiary?.code, 'response.Subsidiary?.code');
            setMyData({
                ...myData,
                loginId: response.loginId,
                // authority: response.Role.name,
                subsidiary: response.Subsidiary?.code,
                information: response.information,
                getGroup: response.Group.name
            });
            console.log(response, 'my data');
        } catch (e: any) {
            console.log(e, 'change Error');
        }
    };

    useEffect(() => {
        requsetMyProfile();
    }, []);

    const useStyles = makeStyles(theme => ({
        root: {
            "& .MuiFormLabel-root": INPUT_LABEL_VALUE,
            "& .MuiInputLabel-shrink": {
                fontSize: 20,
                lineHeight: 1.4
            },
            "& .MuiInput-input": {
                marginTop: 5
            },
        }
    }));
    const classes = useStyles();

    const updateUserData = {
        "name": getId,
        "SubsidiaryCode": getSub,
        // "RoleName": getAuth,
        "StatusName": "active",
        "information": information,
        "GroupName": getGroup
    };

    const inputUser = async () => {
        if (!isAble) {
            return;
        } else {
            try {
                const inputresponse = await updateUser(selectedData[0].id, updateUserData, authData);
                if (inputresponse.isSuccess) {
                    console.log(inputresponse, 'updatetresponse');
                    renderAction();
                    renderOpen();
                    onClose();
                    closeUser();
                    closeUserTable();
                    closeModal();
                }
            } catch (e: any) {
                console.log(e, "update user error");
            }
        }

    };

    const reqSubsidiary = async () => {
        try {
            // const response = await getSubList(isMyEdit ? myData.getGroup : getGroup);
            const response = await getMySubList(isMyEdit ? myData.getGroup : getGroup);
            // const response = await getSubList(myData.getGroup);
            // console.log(myData.getGroup, 'myData.getGroup');
            setRequestSub(response);
        } catch (e: any) {
            console.log(e, "sub res error");
        }
    };

    const reqGroup = async () => {
        try {
            const response = await getGroupList();
            setRequestGroup(response);
        } catch (e: any) {
            console.log(e, "group res error");
        }
    };

    const [requestSub, setRequestSub] = useState<any>([]);
    const [requestGroup, setRequestGroup] = useState<any>([]);

    useEffect(() => {
        if (getGroup != "") {
            reqSubsidiary();
            setSub(selectedData != undefined ? selectedData[0].subsidiary : "");
        }
    }, [getGroup]);

    useEffect(() => {
        reqGroup();
    }, []);


    // useEffect(() => {
    //     if (authData.find((x: any) => x.check === true)) {
    //         setIsAble(true);
    //     } else {
    //         setIsAble(false);
    //     }
    // }, [authData]);

    return (
        <div>
            <Modal open={isOpen} onClose={onClose}>
                <Box sx={style}>
                    <S.ContentBox $isMyEdit={isMyEdit}>
                        <S.TitleSection>
                            Edit
                        </S.TitleSection>
                        <S.ContentSection>
                            <TextField
                                variant="standard"
                                label={<>ID</>}
                                type="text"
                                className={classes.root}
                                value={getId || ''}
                                placeholder="Enter ID"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => setId(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "90%",
                                    input: INPUT_VALUE
                                }}
                                disabled={true}
                            />
                            <TextField
                                variant="standard"
                                label={<>Password</>}
                                type="password"
                                className={classes.root}
                                value={getPw || ''}
                                placeholder="Enter Password"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => setPw(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "90%",
                                    input: INPUT_VALUE
                                }}
                                disabled={true}
                            />
                        </S.ContentSection>

                        <S.ContentSection $isMyEdit={isMyEdit}>
                            <SettingDropDown
                                label={"Group"}
                                setValue={setGroup}
                                option={requestGroup}
                                value={getGroup}
                                isMyEdit={isMyEdit}
                                isPoint={false}
                                width={media === "MOBILE" ? "100%" : "90%"}
                                isDisable={isNew ? myData.getGroup.name === "SUPER" ? false : true : true}
                            />
                            <SettingDropDown
                                label={"Subsidiary"}
                                setValue={setSub}
                                option={requestSub}
                                value={isMyEdit ? myData.subsidiary : getSub}
                                // value={myData.subsidiary}
                                isMyEdit={isMyEdit}
                                isPoint={isMyEdit ? false : true}
                                width={media === "MOBILE" ? "100%" : "90%"}
                            // isUser
                            />
                        </S.ContentSection>


                        <S.CheckBoxSection>
                            <label>Authority</label>
                            <div>
                                <CustomCheckBox
                                    data={authData}
                                    // data={authData}
                                    setValue={setAuthData}
                                />
                            </div>
                        </S.CheckBoxSection>
                        <Box>
                            <TextField
                                variant="standard"
                                label="Information"
                                type="text"
                                className={classes.root}
                                value={isMyEdit ? myData.information : information || ''}
                                placeholder="Enter Information"
                                InputLabelProps={{
                                    shrink: true, sx: {
                                        //label css
                                    }
                                }}
                                onChange={(e) => isMyEdit ? setMyData({ ...myData, information: e.target.value }) : setInformation(e.target.value)}
                                sx={{
                                    width: media === "MOBILE" ? "100%" : "95%",
                                    mt: isMyEdit && media === "MOBILE" ? 1 : 0,
                                    input: INPUT_VALUE,
                                }}
                            />
                            <FormHelperText
                                sx={{
                                    display: "flex",
                                    justifyContent: "right",
                                    padding: media === "MOBILE" ? 0 : "0 55px"
                                }}
                            >
                                <span>{`${information != undefined ? information.length : 0}/${255}`}</span>
                            </FormHelperText>
                        </Box>

                        <S.BtnSection>
                            <CustomButton
                                title="SAVE"
                                classname={isAble ? "save-btn" : "disable-check"} //disable-check
                                icon={
                                    <></>
                                }
                                btnClick={() => inputUser()}
                                Width="79"
                                Height="40"
                            />
                            <CustomButton
                                title="CANCEL"
                                classname="cancel-btn"
                                icon={
                                    <></>
                                }
                                Width="100"
                                Height="40"
                                btnClick={() => openCancel()} />
                        </S.BtnSection>
                    </S.ContentBox>

                    {isError
                        &&
                        <PopupAlert
                            isOpen={isErrorModal}
                            onClose={() => setIsErrorModal(false)}
                            contents={responeText}
                        />
                    }
                    {isCancel
                        &&
                        <CloseAlert
                            isOpen={isCancel}
                            onClose={() => closeCancel()}
                        // contents={responeText}
                        />
                    }
                    {/* <button onClick={()=>console.log(authData,'autdaada')}>test</button> */}
                </Box>
            </Modal>
        </div>
    );
}
