import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import * as S from "./QnaNoti.style";

import qna1080 from 'assets/qna1080.svg';
import qna1513 from 'assets/qna1513.svg';
import qna1920 from 'assets/qna1920.png';

import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { setCookie } from "util/Cookie";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};
interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}


export default function QnANotilModal({ isOpen, onClose }: ModalProps) {

    const [isDont, setIsDont] = useState<boolean>(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const selectImage = () => {
        if (windowWidth >= 1920) {
            return qna1920;
        } else if (windowWidth >= 1512) {
            return qna1513;
        } else {
            return qna1080;
        }
    };

    const saveDontShow = () => {
        if (isDont) {
            setCookie("isQnADont", "true", 1);
        } else {
            setCookie("isQnADont", "false", 1);
        }
        onClose();
    };

    return (
        <div>
            <Modal
                open={isOpen}
            >
                <Box sx={style}>
                    <S.ContentBox>
                        <S.NumberTitle>
                            2. Q&A
                        </S.NumberTitle>
                        <S.DesText>
                            Download How-to Guide:
                            <a href="https://drive.google.com/drive/u/3/folders/1QsNZt28nIvgV0r4KXhYN7s8fe2cUe1Dd">
                                https://drive.google.com/drive/u/3/folders/1QsNZt28nIvgV0r4KXhYN7s8fe2cUe1Dd
                            </a>
                            <S.SubText>
                                ** If you have any inquiries, please use the <span> Q&A forum!!!</span>
                                You can get answers faster than contacting Samsung directly!
                            </S.SubText>
                        </S.DesText>


                        <S.MobileImgBox>
                            <img src={selectImage()} />
                        </S.MobileImgBox>

                        <S.BtnBox>
                            <div style={{
                                color: "#707070",
                                fontFamily: "SamsungOne",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "19px",
                                marginRight: "auto"

                            }}>
                                <Checkbox
                                    onChange={() => setIsDont(!isDont)}
                                />Don’t show for today
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{
                                    background: "#2196F3",
                                    color: "#FFF",
                                    fontFamily: "SamsungOne",
                                    fontSize: "15px",
                                    fontStyle: "normal",
                                    fontWeight: "800",
                                    lineHeight: "24px",
                                    marginLeft: "auto"
                                }}
                                onClick={saveDontShow}
                            // onClick={()=>console.log(isShow)}
                            >
                                GOT IT
                            </Button>
                        </S.BtnBox>


                    </S.ContentBox>
                </Box>
            </Modal>
        </div>
    );
}