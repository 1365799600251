import { useEffect, useState } from "react";
import * as S from "./style";
import { Link, useNavigate } from "react-router-dom";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Box } from "@mui/material";

import { postAdminAuth } from "apis/AuthApi/AuthApi";
import PopupAlert from "components/alert/PopupAlert";
import useSidebarStore, { SidebarState } from "util/store/sideNavStore";

interface LoginProps {
    btnHandle(): void;
}

export default function Login() {

    const { sidebarNav, setSidebarNav } = useSidebarStore();
    const [id, setInputId] = useState<string>("");
    const [password, setInputPw] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [isErrorModal, setIsErrorModal] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<any>();

    const [showPassword, setShowPassword] = useState(false);

    const [enable, setEnable] = useState<boolean>(false);

    const navi = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const login = async (id: string, password: string) => {
        if (enable) {
            const isLogin = await postAdminAuth(id, password);
            console.log(isLogin, 'isLogin');
            if (isLogin.isSuccess === true) {
                setIsError(false);

                if (isLogin.useData.group.name === "GMO") {
                    const newSidebarNav = sidebarNav.map(item => {
                        if (item.text === "How-to Guide" || item.text === "App Download") {
                            return { ...item, isVisible: false };
                        }
                        else if (isLogin.useData.permission.modifyLocalization === false) {
                            return item.text === "Localization" ? { ...item, isVisible: false } : item;
                        }
                        return item;
                    });
                    console.log(newSidebarNav, 'new nav');
                    setSidebarNav(newSidebarNav);
                }
                else {
                    if (isLogin.useData.permission.modifyLocalization === false) {
                        const newSidebarNav = sidebarNav.map(item =>
                            item.text === "Localization" ? { ...item, isVisible: false } : item
                        );
                        setSidebarNav(newSidebarNav);
                    }
                }
                navi("/dashboard");
            } else {
                setIsError(true);
                setIsErrorModal(true);
                setErrorText("Please verify your ID or password and try again.");
            }
        } else {
            return null;
        }
    };


    const handleOnKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            login(id, password);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleOnKeyPress);
        return () => {
            window.removeEventListener('keydown', handleOnKeyPress);
        };
    }, [id, password]);


    useEffect(() => {
        if ((id && password) != "") {
            setEnable(true);
        } else {
            setEnable(false);
        }
    }, [id, password]);


    return (
        <S.Container>
            <S.Wrapper>
                <S.TextBox>
                    Samsung <br />Retail App Console
                </S.TextBox>

                <S.InputBox>
                    <TextField
                        error={isError}
                        variant="filled"
                        label="ID"
                        type="text"
                        value={id}
                        onChange={(e) => setInputId(e.target.value)}
                        sx={{
                            // width: ["343px","520px"],
                            // mt: ["54px"]
                            width: { xs: "343px", lg: "520px" },
                            mt: { xs: "54px" },
                        }}
                    />
                    <TextField
                        error={isError}
                        variant="filled"
                        label="PASSWORD"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setInputPw(e.target.value)}
                        sx={{
                            width: { xs: "343px", lg: "520px" },
                            mt: { xs: 3 },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {password &&
                                            <>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        disabled={!enable}
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{
                            width: { xs: "343px", lg: "520px" },
                            mt: { xs: "36px", lg: "50px" },
                        }}
                        onClick={() => login(id, password)}
                        onKeyDown={(e) => handleOnKeyPress(e)}
                    >
                        LOGIN
                    </Button>
                </S.InputBox>

            </S.Wrapper>
            {isError && <PopupAlert isOpen={isErrorModal} onClose={() => setIsErrorModal(false)} contents={errorText} />}
        </S.Container>
    );
};


