import * as S from "./ChartBox.style";

interface BoxProps {
    title: string | React.ReactNode;
    contents: React.ReactNode;
    Topcontents?: React.ReactNode;
    wd?: string;
    ht?: string;
    isDim?: boolean;
}

export default function ChartBox({ title, contents, wd, ht, Topcontents, isDim }: BoxProps) {


    return (
        <S.ContentBox
            wd={wd}
            ht={ht}
            isDim={isDim}
        >
            <S.ChartTopContents>
                <S.ChartTitle>
                    {title}
                </S.ChartTitle>
                <S.ChartBtn>
                    {Topcontents}
                </S.ChartBtn>
            </S.ChartTopContents>

            <S.ChartContents>
                <S.SizeBox>
                    {contents}
                </S.SizeBox>
            </S.ChartContents>

        </S.ContentBox>

    );
}