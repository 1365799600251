
export const AUTHORITY = [
    {
        key: "Admin",
        value: "admin",
    },
    {
        key: "User",
        value: "user",
    }
];

export const CREATE_AUTHORITY = [
    {
        value : "createUser",
        label : "Manage User",
        checked : false
    },
    {
        value : "createSubsidiary",
        label : "Manage Subsidiary",
        checked : false
    },
    {
        value : "viewAllSubsidiary",
        label : "Access to all Subsidiary",
        checked : false
    },
    {
        value : "modifyLocalization",
        label : "Edit Localization",
        checked : false
    },
]