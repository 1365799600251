import { DataGrid } from "@mui/x-data-grid";
import { useState, useRef } from "react";
import * as S from "./DataTable.style";
import {
  Button,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import SettingModal from "components/modal/SettingModal";
import { useContext } from "react";
import { RenderContext } from "hooks/context";
import UserModal from "components/modal/UserModal";
import { InputAdornment } from "@material-ui/core";
import {
  BTN_TEXT,
  BTN_TEXT_RED,
  TABEL_CELL,
  TABEL_HEADER,
  TEXT_PLACEHOLDER,
} from "constants/CssConstant";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import SubsidiaryModal from "components/modal/SubsidiaryModal";
import PopupAlert from "components/alert/PopupAlert";
import CloseAlert from "components/alert/CloseAlert";
import { deleteTvDuid, inPutTv, getTvList } from "apis/TvStatus/TvStatusApi";
import { MediaType } from "util/media";
import ConfirmAlert from "components/alert/ConfirmAlert";
import { CSVLink } from "react-csv";
import { TV_STATUS_EXCEL } from "constants/ExcelConstant";
import styled from "styled-components";
import SettingAllModal from "components/modal/SettingAllModal";

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

interface TableProps {
  checked?: boolean;
  rowData?: any;
  columnData?: any;
  isSettingAllBtn?: boolean;
  isNewBtn?: boolean;
  isSubBtn?: boolean;
  isExportBtn?: boolean;
  placeHolder: string;
  btnContent?: React.ReactNode;
  cellHandle?: (props: any) => void;
  apiData?: any;
  rowCount?: number;
  searchText?: string;
  setSearchText?: (value: any) => void;
  setSearchPage?: (value: any) => void;
  setTableCount?: (value: any) => void;
}

export default function DataTable({
  rowCount,
  setTableCount,
  setSearchPage,
  searchText,
  setSearchText,
  apiData,
  checked,
  columnData,
  isNewBtn,
  isSettingAllBtn,
  placeHolder,
  isSubBtn,
  btnContent,
  isExportBtn,
  cellHandle,
}: TableProps) {
  const userInfo = JSON.parse(localStorage.getItem("User") || "{}");
  const isAcessSCIC =
    userInfo?.Subsidiary?.code === "SCIC";
  const {
    isOpen,
    isAlert,
    isNppTable,
    isAllNppTable,
    isUserTable,
    isSubTable,
    renderClose,
    renderAction,
    openNppTable,
    openAllNppTable,
    openUserTable,
    openSubTable,
  } = useContext(RenderContext);

  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [isSetting, setIsSetting] = useState<boolean>(false);
  const [isAllSetting, setIsAllSetting] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [isSubsidiary, setIsSubsidiary] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const [csvData, setCsvData] = useState([]);

  const media = MediaType();

  const apiRef = useRef<any | undefined>(null);

  const handleOnKeyPress = (e?: any) => {
    if (e.key === "Enter") {
      setSearchText(searchValue);
      apiRef.current?.setPage(0);
    }
  };

  const inputTestData = () => {
    for (let i = 3000; i < 5000; i++) {
      inPutTv(i.toString());
    }
  };

  const deleteChecked = async () => {
    const deleteList = [];
    for (let i = 0; i < selectedData.length; i++) {
      deleteList.push(selectedData[i].id);
    }
    try {
      const inputresponse = await deleteTvDuid(deleteList);
      if (inputresponse.isSuccess) {
        renderAction();
        setIsError(true);
      }
    } catch (e: any) {
      console.log(e, "use delete error");
    }
  };

  const convertDate = (dateString: string | null) => {
    if (!dateString) return null;
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const requestCsvData = async () => {
    if (rowCount === 0) {
      alert("There is no list available for download.");
    } else {
      try {
        const response: any = await getTvList(searchText, 1, 0);
        console.log(response.rowData);
        const transformedData = response.rowData.map((row) => {
          const transformedRow = {};
          TV_STATUS_EXCEL.forEach((header) => {
            if (header.key === "startTime" || header.key === "endTime") {
              transformedRow[header.key] = convertDate(row[header.key]);
            } else {
              transformedRow[header.key] = row[header.key];
            }
          });
          return transformedRow;
        });
        setCsvData(transformedData);
        setTimeout(() => {
          document.getElementById("csv-link").click();
        }, 1000);
      } catch (e) {
        console.error("getList Error:", e);
      }
    }
  };

  return (
    <S.Container>
      <S.TableTop>
        <Box
          sx={{
            p: media === "MOBILE" ? "" : "2px 4px",
            display: "flex",
            alignItems: "center",
            gap: 3,
          }}
        >
          <TextField
            id="searchbar"
            variant="standard"
            label="Search"
            InputLabelProps={{
              shrink: true,
              sx: {},
            }}
            placeholder={placeHolder}
            sx={{ width: "100%", minWidth: "350px" }}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={(e) => handleOnKeyPress(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    onClick={() => {
                      setSearchText(searchValue);
                      apiRef.current?.setPage(0);
                    }}
                    sx={{ cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
              sx: {
                "&::placeholder": {
                  color: TEXT_PLACEHOLDER,
                },
              },
            }}
          />
          {isSettingAllBtn && isAcessSCIC && (
            <NoMaxWidthTooltip
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -2],
                      },
                    },
                  ],
                },
              }}
              title="Newly registered or updated TVs during the process will also be automatically included."
            >
              <Button
                disabled={rowCount < 1}
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => openAllNppTable()}
                sx={{
                  minWidth: "auto",
                  whiteSpace: "nowrap",
                  color: BTN_TEXT,
                }}
              >
                SETTING ALL TVS ({rowCount})
              </Button>
            </NoMaxWidthTooltip>
          )}
        </Box>
        <S.BtnWrapper>
          {btnContent ? (
            <div style={{ display: "flex", gap: "20px" }}>{btnContent}</div>
          ) : (
            <>
              {isExportBtn && (
                <>
                  <CsvDownloadBtn onClick={requestCsvData}>
                    EXPORT
                  </CsvDownloadBtn>
                  <CSVLink
                    data={csvData}
                    filename={"TvStatusList.csv"}
                    id="csv-link"
                    style={{ display: "none" }}
                  >
                    CSV
                  </CSVLink>
                </>
              )}
              {isNewBtn ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => (isSubBtn ? openSubTable() : openUserTable())}
                  sx={{
                    color: BTN_TEXT,
                    gap: 1,
                  }}
                >
                  <PersonAddAlt1Icon />
                  NEW
                </Button>
              ) : (
                <div style={{ display: "flex", gap: "20px" }}>
                  <Button
                    disabled={!isCheck}
                    variant="outlined"
                    color="warning"
                    size="medium"
                    onClick={() => setIsDelete(true)}
                    sx={{
                      color: BTN_TEXT_RED,
                      borderColor: "red",
                    }}
                  >
                    DELETE
                  </Button>
                  <Button
                    disabled={!isCheck}
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => openNppTable()}
                    sx={{
                      color: BTN_TEXT,
                    }}
                  >
                    SETTING
                  </Button>
                </div>
              )}
            </>
          )}
        </S.BtnWrapper>
      </S.TableTop>

      <DataGrid
        apiRef={apiRef}
        onCellClick={cellHandle}
        onPaginationModelChange={(param: any) => {
          setTableCount(param.pageSize);
          setSearchPage(param.page);
        }}
        rows={apiData}
        columns={columnData}
        getRowHeight={() => "auto"}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 50, 100]}
        paginationMode="server"
        pagination
        rowCount={rowCount}
        checkboxSelection={checked}
        keepNonExistentRowsSelected={true}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(ids) => {
          console.log("as;dlfkj;lasdfj;askl");
          const selectedIDs = new Set(ids);
          const selectedRows = apiData.filter((row: any) =>
            selectedIDs.has(row.id)
          );
          setSelectedData(selectedRows);
          selectedRows?.length > 0 ? setIsCheck(true) : setIsCheck(false);
        }}
        sx={{
          borderLeft: media === "MOBILE" ? "" : "none",
          borderRight: media === "MOBILE" ? "" : "none",
          borderBottom: media === "MOBILE" ? "" : "none",
          borderRadius: 0,
          "& .MuiDataGrid-main": {},
          "& .MuiDataGrid-cell": {
            borderRadius: "0px",
            paddingTop: 2,
            paddingBottom: 2,
            color: TABEL_CELL,
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            overflow: "visible !important",
            color: TABEL_HEADER,
          },
          width: "100%",
        }}
      />

      {isAllNppTable && (
        <SettingAllModal
          isOpen={isAllNppTable}
          searchQuery={searchValue}
          onClose={() => setIsAllSetting(false)}
        />
      )}
      {isNppTable && (
        <SettingModal
          isOpen={isNppTable}
          onClose={() => setIsSetting(false)}
          selectedData={selectedData}
        />
      )}
      {isUserTable && (
        <UserModal isOpen={isUserTable} onClose={() => setIsNew(false)} isNew />
      )}
      {isSubTable && (
        <SubsidiaryModal
          isOpen={isSubTable}
          onClose={() => setIsSubsidiary(false)}
          isNew
        />
      )}
      {isOpen && (
        <PopupAlert
          isOpen={isOpen}
          onClose={() => renderClose()}
          contents="Saving is complete"
        />
      )}
      {isAlert && (
        <CloseAlert
          isOpen={isAlert}
          onClose={() => setIsSetting(false)}
          contents=""
          allClose={() => setIsSetting}
        />
      )}
      {isDelete && (
        <ConfirmAlert
          isOpen={isDelete}
          onClose={() => setIsDelete(false)}
          requserApi={() => deleteChecked()}
        />
      )}
      {isError && (
        <PopupAlert
          isOpen={isError}
          onClose={() => setIsError(false)}
          contents={`Delete Complete`}
        />
      )}
    </S.Container>
  );
}

const CsvDownloadBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  gap: 5px;

  min-width: 80px;
  padding: 10px;
  border-radius: 5px;

  width: 98px;
  height: 40px;

  color: #2196f3;

  cursor: pointer;

  border: none;
  background-color: rgba(33, 150, 243, 0.04);
  color: #2196f3;
  font-family: SamsungOne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  text-transform: uppercase;

  text-decoration: none;
`;
