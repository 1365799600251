import React, { useState } from 'react';
import axios from 'axios';
import CustomButton from "components/Button/CustomButton";
import { downloadFile, getFileSrc } from "apis/DashBoard/DashBoardApi";
import { getData } from "apis/apiCore";

const ExportBtn = ({ startDate, endDate, subCode, type }) => {

    const [isModal, setIsModal] = useState<boolean>(false);

    return (
        <>
            <CustomButton
                title="EXPORT"
                classname="preview-btn"
                icon={
                    <></>
                }
                btnClick={() => downloadFile(startDate, endDate, subCode, type)}
                Width="98"
                Height="40"
            />
        </>
    );
};

export default ExportBtn;
