import { theme } from "constants/theme";
import styled from "styled-components";

export const ContentBox = styled.div<{ $ismobile: string; }>`
    display: flex;
    flex-direction: column;
    width: 1074px;
    /* height: 750px; */
    /* height: 80vh; */
    gap : 15px;
    padding : 20px;
    padding-left: 55px;

    @media (${theme.MOBILE}) {
        width: 320px;
        height: 70vh;
        gap : 25px;
        padding : 0;
        overflow-y: auto;

        @media (orientation: landscape){
        width: 500px;
        height: 70vh;
        overflow-y: scroll;
        }
    }
`;

export const TitleSection = styled.div`
    flex : 0.5;
    font-size: 20px;
    font-style: normal;
    font-weight: 666;
    line-height: 34px; /* 170% */
    letter-spacing: 1px;
`;

export const ContentSection = styled.div<{ $ismobile?: string; }>`
    display: grid;
    gap : 10px;
    grid-template-columns: repeat(2, 1fr);

    @media (${theme.MOBILE}) {
        grid-template-columns:  none;
    };
`;

export const BtnSection = styled.div<{ $ismobile?: string; }>`
    flex : 1;
    display: flex;
    justify-content: right;
    gap : 10px;
    margin-top : 0;
    margin-right : 50px;
    padding-top : 70px;

    @media (${theme.MOBILE}) {
        margin-top : 30px;
        margin-right : 0;
        padding-top: 0;
    };
`;

export const ModifyBtnSection = styled.div<{ $ismobile?: string; }>`
    flex : 1;
    display: flex;
    justify-content: right;
    gap : 10px;
    margin-top : 0;
    margin-right : 50px;

    @media (${theme.MOBILE}) {
        margin-top : 30px;
        margin-right : 0;
        padding-top: 0;
    };
`;

export const Title = styled.div`
    font-size: 20px;
    font-style: normal;
    font-weight: 666;
    line-height: 34px; /* 170% */
    letter-spacing: 1px;
`;