export const PROMOTION_OPTION = [
    {
        key : "Promotion Image only",
        value : 1
    },
    {
        key : "Promotion Video only",
        value : 2
    },
    {
        key : "Promotion Image + Promotion Video",
        value : 3
    },
    {
        key : "Promotion Video + Promotion Image",
        value : 4
    },
]
 
export const PROMOTION_OPTION_MIXED = [
    {
        key : "Mixed",
        value : 0
    },
    {
        key : "Promotion Image only",
        value : 1
    },
    {
        key : "Promotion Video only",
        value : 2
    },
    {
        key : "Promotion Image + Promotion Video",
        value : 3
    },
    {
        key : "Promotion Video + Promotion Image",
        value : 4
    },
]
 