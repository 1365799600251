import { getData, postDataTest } from "../apiCore";

export const postAdminAuth = async (id: string, password: string) => {
    const response = await postDataTest(`/auth/login`, {
        "loginId": id.toLowerCase(),
        "password": password
    });
    console.log(response, 'response login');
    if (response.status === 200) {
        const userData = {
            id: response.data.user.id,
            subsidiary: response.data.user.code,
            information: response.data.user.information,
            name: response.data.user.loginId,
            group : response.data.user.Group,
            permission : response.data.user.Permission,
        };
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("tokenTime", response.data.tokenExpires);
        localStorage.setItem("User", JSON.stringify(response.data.user));
        localStorage.setItem("UserData", JSON.stringify(userData));
        // return true;
        return { isSuccess : true , useData : userData }
    }else{
        return { isSuccess : false , data : response }
    }
};

export const getMyProfile = async () => {
    const response = await getData(`/auth/me`);
    return response.data;
};

