import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from "./router/PrivateRoute";
import Login from "pages/login/Login";
import Dashboard from "pages/dashboard/DashBoard";
import User from "pages/admin/user/User";
import Empty from "pages/error/Empty";
import Tvstatus from "pages/tvstatus/TvStatus";
import Subsidiary from "pages/admin/subsidiary/Subsidiary";
import Localization from "pages/localization/Localization";
import QnA from "pages/qna/QnA";
import WriteQnA from "pages/qna/WriteQnA";
import QnaDetail from "pages/qna/QnaDetail";
import QnaModify from "pages/qna/QnaModify";
import HowToGuide from "pages/howtoguide/HowtoGuide";
import Packagefile from "./pages/packagefile/Packagefile";

function App() {

  return (
      <Router>
        <Routes>
          {/* 기본 접속 LOGIN PAGE */}
          <Route path="/" element={<Login />} />
          {/* 로그인 해야 접근 가능한 페이지 */}
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/tvstatus" element={<Tvstatus />} />
            <Route path="/localization" element={<Localization />} />
            <Route path="/user" element={<User />} />
            <Route path="/subsidiary" element={<Subsidiary />} />
            <Route path="/qna" element={<QnA />} />
            <Route path="/qnaDetail" element={<QnaDetail />} />
            <Route path="/qnaModify" element={<QnaModify />} />
            <Route path="/writeqna" element={<WriteQnA />} />
            <Route path="/howtoguide" element={<HowToGuide />} />
            <Route path="/appdownload" element={<Packagefile />} />
          </Route>
          {/* 빈 페이지 */}
          <Route path="*" element={<Empty />} />
        </Routes>
      </Router>
  );
}

export default App;
