import { getData, getFileData, postData, postDataTest } from "../apiCore";


export const getWorldMap = async () => {
    const response = await getData(`/dashboards/worldmap`);
    return response.data;
};

export const getRegistration = async (startDate: string, endDate: string, subCode: string) => {
    const response = await getData(`/dashboards/registration?startDate=${startDate}&endDate=${endDate}&subsidiaryCode=${subCode}`);
    return response.data;
};

export const getAppTrends = async (subCode: string) => {
    const response = await getData(`/dashboards/trends?subsidiaryCode=${subCode}`);
    return response.data;
};

export const getAppStatusGroup = async (startDate: string, endDate: string, subCode: string) => {
    const response = await getData(`/dashboards/appstatus/group?startDate=${startDate}&endDate=${endDate}&subsidiaryCode=${subCode}`);
    return response.data;
};

export const getAppStatus = async (startDate: string, endDate: string) => {
    const response = await getData(`/dashboards/appstatus/subsidiary?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const getAppNumber = async (startDate: string, endDate: string,subCode: string) => {
    const response = await getData(`/dashboards/appnumber?startDate=${startDate}&endDate=${endDate}&subsidiaryCode=${subCode}`);
    return response.data;
};

export const getAppUsage = async (startDate: string, endDate: string,subCode: string) => {
    const response = await getData(`/dashboards/appusages?startDate=${startDate}&endDate=${endDate}&subsidiaryCode=${subCode}`);
    return response.data;
};

export const getAppAffiliate = async (startDate: string, endDate: string,subCode: string) => {
    const response = await getData(`/dashboards/affiliate?startDate=${startDate}&endDate=${endDate}&subsidiaryCode=${subCode}`);
    return response.data;
};

export const getSettingChange = async (startDate: string, endDate: string,subCode: string) => {
    const response = await getData(`/dashboards/tvsetting?startDate=${startDate}&endDate=${endDate}&subsidiaryCode=${subCode}`);
    return response.data;
};

export const getMobileExp = async (startDate: string, endDate: string) => {
    const response = await getData(`/dashboards/mobileexperience?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const getFileSrc = async (startDate: string, endDate: string,subCode: string,type : string) => {
    const response = await getData(`/dashboards/${type}/download?startDate=${startDate}&endDate=${endDate}&subsidiaryCode=${subCode}`);
    return response.data;
};

export const downloadFile = async (startDate: string, endDate: string, subCode: string, type: string) => {
    try {
        const response = await getFileData(`/dashboards/${type}/download?startDate=${startDate}&endDate=${endDate}&subsidiaryCode=${subCode === "ALL" ? "" : subCode}`);
        console.log(response,'response fileasdasd')
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${response.fileName}`);
        document.body.appendChild(link);
        link.click();

    } catch (error) {
        console.error('Error downloading file:', error);
        // 오류 처리
    } finally {
        console.log("final")
    }
};
