import { getData, postData, postDataTest, postTest } from "../apiCore";



export const getLanguageJson = async (projcetName: string, subsidiaryCode: string, deviceType?: string) => {
    const response = await getData(`/languages?projectName=${projcetName}&subsidiaryCode=${subsidiaryCode}&deviceType=${deviceType}`);
    console.log(projcetName, subsidiaryCode, 'res json');
    return response.data;
};

export const getConfigJson = async (projcetName: string, subsidiaryCode: string) => {
    const response = await getData(`/projects/config?subsidiaryCode=${subsidiaryCode}&projectName=${projcetName}`);
    
    return response.data;
};

export const postLanguageJson = async (postJson: any, projcetName: string, subsidiaryCode: string, deviceType: string) => {

    let obj = {};
    postJson.forEach((element) => {
        obj[element.key] = element.local;
    });

    const response = await postTest(`/languages`, {
        "projectName": projcetName,
        "subsidiaryCode": subsidiaryCode,
        "languageJson": obj,
        "deviceType": deviceType.toLocaleLowerCase()
    });
    console.log(response, 'response local');
    return response;
};


