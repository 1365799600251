import { theme } from "constants/theme";
import styled from "styled-components";

export const ContentBox = styled.div<{ height?: string; }>`
    width: 1074px;
    /* width: 1074px; */
    /* height: ${props => props.height}; */

    /* height: 1000px; */
    display: flex;
    flex-direction: column;
    gap : 20px;

    @media (${theme.MOBILE}) {
        overflow-y: scroll;
        width: auto;
        height: 70vh;

        @media (orientation: landscape){
        width: 500px;
        /* height: 75vh; */
        overflow-y: scroll;
        }
    }
`;

export const Title = styled.div`
    font-size: 20px;
    font-style: normal;
    font-weight: 666;
    line-height: 34px; /* 170% */
    letter-spacing: 1px;
`;

export const TitleSection = styled.div`
    color : #202020;
    font-family: SamsungOne;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.15px;
`;

export const Content = styled.div`
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 50%);
    margin-top: 20px;

    @media (${theme.MOBILE}) {
        grid-template-columns: none;
        gap: 10px;
    }
`;

export const BtnSection = styled.div<{ mgTop?: string; }>`
    flex : 1;
    display: flex;
    gap : 16px;
    justify-content: right;
    align-items: center;
    margin-top: 0;

    @media (${theme.MOBILE}) {
        margin-top: ${props => props.mgTop};
    }
`;

export const SubTitle = styled.div<{ mgTop?: string; }>`
    width: 100%;
    font-family: SamsungOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    margin-top: ${props => props.mgTop};
`;

export const ADDTEXT = styled.div`
    position: absolute;
    right: 0;
    color : #2196F3;
    font-family: SamsungOne;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
`;

export const ADDTEXTInBox = styled.div`
    color : #2196F3;
    font-family: SamsungOne;
    font-size: 15px;
    font-weight: 800;

    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;

    /* @media (${theme.MOBILE}) {
        margin-top: 10px;
    } */
`;
export const ADDsection = styled.div`
    width: 500px;
    height: 120px;
    display: flex;
    gap : 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background:  #FFF;
    border-radius: 4px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
    color : #9F9F9F;
    font-family: SamsungOne;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 12px; 100% */

    @media (${theme.MOBILE}) {
        width: 320px;
        margin-top: 10px;

        @media (orientation: landscape) {
        margin-left: 2px;
        width: 99%;
        }
    }
`;


export const ImgArrayBox = styled.div`
    width: 100%;
    padding : 15px;
    font-size: 14px;
    overflow: scroll;
    overflow-x: hidden;
    display : flex;
    flex-direction: column;

    > span {
        justify-content: right;
    }
    @media (${theme.MOBILE}) {
        font-size: 12px;
    }
    .droppable {
        padding: 1rem;
        background: #aaa;
        height: 180px;
        overflow-y: scroll;
    }
    
`;

export const Boxtext = styled.div`
    display: flex;
    align-items: center;
    padding : 5px;
    border-radius: 5px;
    /* height: 100px; */

    &:hover {
        transition: all 0.5s;
        background-color: #e8e8e8;
    }

    > svg {
        margin-left: auto;
        cursor: pointer;
    }

    a {
        margin-top: 3px;
        color : #9F9F9F;
        text-decoration: none;
        &:hover {
            color : #2196F3;
        }
    }
`;

export const IconBox = styled.div`
    height: inherit;
    display: flex;
    gap : 5px;

    justify-content: center;
    align-items: center;

    > svg {
        cursor : pointer;
    }

    
    .down-btn {
        color : #9F9F9F;
        text-decoration: none;
        &:hover {
            color : #2196F3;
        }
    }
`;


export const SectionDivsion = styled.div<{ isRight?: boolean, mgTop?: string; }>`
    width: 50%;
    margin-top: ${props => props.mgTop};
    @media (${theme.MOBILE}) {
        width: 100%;
    }
`;

export const ContentSection = styled.div<{ height?: string, isInput?: boolean; }>`
    width: 100%;
    height: ${props => props.height};
    padding : 20px;
    position: relative;
    border-radius: 5px;
    background:  #e8e8e8;
    box-shadow:  0px 2px 7px 0px rgba(0, 0, 0, 0.25);

    @media (${theme.MOBILE}) {
        height: 500px;
        padding : 0;
        background:  none; 
        box-shadow: none;
        flex-direction: column;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    gap : 30px;

    @media (${theme.MOBILE}) {
        flex-direction: column;
    }

`;