import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface MarkerProps {
    data: any;
}

export default function Marker({ data }: MarkerProps) {

    const [hoveredMarker, setHoveredMarker] = useState<string | null>(null);

    const [hoveredValue, setHoveredValue] = useState<any>([]);

    const MarkerPosition = [
        { region: "Africa", top: 60, left: 55 },
        { region: "Asia", top: 50, left: 80 },
        { region: "China", top: 35, left: 75 },
        { region: "CIS", top: 20, left: 70 },
        { region: "Europe", top: 30, left: 48 },
        { region: "Korea", top: 36.8, left: 85 },
        { region: "LATAM", top: 65, left: 30 },
        { region: "Middle East", top: 40, left: 60.5 },
        { region: "North America", top: 40, left: 18 }
    ];

    const handleMarkerHover = (region: string) => {
        setHoveredMarker(region);

        const regionData = data?.data?.find((item: any) => item.name === region);

        if (regionData && typeof regionData.subsidiarys === 'object') {
            setHoveredValue(regionData.subsidiarys);
        } else {
            setHoveredValue(null);
        }
    };


    const [clickedMarker, setClickedMarker] = useState<string | null>(null);
    const [clickedValue, setClickedValue] = useState<any>([]);
    const [isShow, setIsShow] = useState<boolean>(false);


    const handleMarkerClick = (region: string) => {
        setIsShow(true);
        if (clickedMarker === region) {
            setClickedMarker(null);
            setClickedValue([]);
        } else {
            const regionData = data?.data?.find((item: any) => item.name === region);
            if (regionData && typeof regionData.subsidiarys === 'object') {
                setClickedValue(regionData.subsidiarys);
            } else {
                setClickedValue([]);
            }
            setClickedMarker(region);
        }
    };

    const handleCloseMessage = () => {
        setIsShow(false);
        setClickedMarker(null);
        setClickedValue([]);
    };


    return (
        <>
            {MarkerPosition.map((marker: any) => (
                <MarkerWrapper
                    key={marker.region}
                    ptop={marker.top}
                    pleft={marker.left}
                    onMouseEnter={() => handleMarkerHover(marker.region)}
                    onMouseLeave={() => setHoveredMarker(null)}
                    onClick={() => handleMarkerClick(marker.region)}
                    showMarker={hoveredMarker !== marker.region}
                >
                    {hoveredMarker === marker.region && (
                        <MarkerName>{marker.region}</MarkerName>
                    )}

                    {clickedMarker === marker.region && isShow && (
                        <Message>
                            <CloseButton onClick={handleCloseMessage}>X</CloseButton>
                            <h5>{marker.region}</h5>

                            <div className="scoll-box">
                                {clickedValue.length > 0 ? (
                                    clickedValue?.map((x: any, idx: number) => (
                                        <div key={idx}>
                                            {x.key} - {x.value}
                                        </div>
                                    ))
                                ) : (
                                    <div>No data</div>
                                )}
                            </div>

                        </Message>
                    )}
                </MarkerWrapper>
            ))}
        </>
    );
}
const MarkerWrapper = styled.div<{ ptop: number; pleft: number; showMarker: boolean; }>`
    z-index: ${({ showMarker }) => (showMarker ? '1' : '10')};
    position: absolute;
    top: ${({ ptop }) => `${ptop}%`};
    left: ${({ pleft }) => `${pleft}%`};
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    cursor: pointer;
`;

const Message = styled.div`
    text-align: center;
    width: auto;
    z-index: 20;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    overFlow:hidden;
    white-space:nowrap;

    text-align: center;
    position: absolute;
    cursor: auto;

    h5 {
        margin-bottom: 10px;
    }

    .scoll-box {
        margin-bottom: 5px;
        max-height: 100px;
        overflow-y: auto;
    }
`;

const CloseButton = styled.div`
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
    color: black;
    font-weight: bold;
`;

const MarkerName = styled.div`
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: black;
`;