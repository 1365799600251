export default function Empty() {



    return(
        <div style={{width : "100%",height : "100vh",display : "flex", alignItems : "center" , justifyContent : "center" , fontSize : 20}}>
         Not found page
        </div>
    )
}

