import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";

function CustomTable({ columns, data }) {

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <Table {...getTableProps()} style={{ width: "100%" }}>
      <thead style={{ height: "60px", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", background: "#EEE", textAlign: "left", tableLayout: "fixed" }}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={{ padding: "15px" }}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", padding: "15px" }}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default CustomTable;

const Table = styled.table`
    width : "100%";
    font-family: "SamsungOne";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
`;
  
const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;

const TableCell = styled.div<{ width: number; }>`
  width: ${({ width }) => width}px;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  word-break: break-all;
`;

const TableRow = styled.div`
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const TableSubRow = styled.div`
  width: 100%;
  padding: 24px;
`;

const TableHeader = styled.div`
  font-weight: 500;
`;
