import { createContext, useState } from "react";

export const RenderContext = createContext({
  isModal: false,
  isUser: false,
  isNpp: false,
  isAllNpp: false,
  isSubsidiary: false,
  isNppTable: false,
  isUserTable: false,
  isSubTable: false,
  isAllNppTable: false,

  isDelete: false,

  isRender: false,
  isOpen: false,
  isAlert: false,
  isCancel: false,

  isLogOut: false,
  isChecked: false,

  openLogOut: () => {},

  openDelete: () => {},
  closeDelete: () => {},

  openModal: () => {},
  closeModal: () => {},

  openUser: () => {},
  closeUser: () => {},

  openNppTable: () => {},
  closeNppTable: () => {},
  openAllNppTable: () => {},
  closeAllNppTable: () => {},
  openUserTable: () => {},
  closeUserTable: () => {},
  openSubTable: () => {},
  closeSubTable: () => {},

  openNpp: () => {},
  closeNpp: () => {},

  openAllNpp: () => {},
  closeAllNpp: () => {},

  openSub: () => {},
  closeSub: () => {},

  openCancel: () => {},
  closeCancel: () => {},
  renderAction: () => {},
  renderOpen: () => {},
  renderClose: () => {},

  ableChecked: () => {},
  disableChecked: () => {},
});

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const RenderProvider = ({ children }: Props): JSX.Element => {
  const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");
  // console.log(userInfo);

  const [isRender, setIsRender] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isUser, setIsUser] = useState<boolean>(false);
  const [isNpp, setIsNpp] = useState<boolean>(false);
  const [isAllNpp, setIsAllNpp] = useState<boolean>(false);
  const [isNppTable, setIsNppTable] = useState<boolean>(false);
  const [isAllNppTable, setIsAllNppTable] = useState<boolean>(false);
  const [isUserTable, setIsUserTable] = useState<boolean>(false);
  const [isSubTable, setIsSubTable] = useState<boolean>(false);
  const [isSubsidiary, setIsSubsidiary] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isLogOut, setIsLogOut] = useState<boolean>(false);

  const [isChecked, setIsCheck] = useState<boolean>(false);

  const ableChecked = (): void => {
    setIsCheck(true);
  };
  const disableChecked = (): void => {
    setIsCheck(false);
  };

  const openLogOut = (): void => {
    setIsLogOut(true);
  };

  const renderAction = (): void => {
    setIsRender(!isRender);
  };

  // const renderAction = () => {
  //   setIsRender(true);
  //   setTimeout(() => {
  //     setIsRender(false);
  //   }, 500);
  // };

  const renderOpen = (): void => {
    setIsOpen(true);
  };
  const renderClose = (): void => {
    setIsOpen(false);
  };

  //all
  const openModal = (): void => {
    setIsModal(true);
  };
  const closeModal = (): void => {
    setIsModal(false);
  };

  const openCancel = (): void => {
    setIsCancel(true);
  };
  const closeCancel = (): void => {
    setIsCancel(false);
  };
  //to table
  const openUser = (): void => {
    // if (userInfo?.group.name != "SUPER") {
    //   alert("no permission edit");
    //   return;
    // }
    setIsUser(true);
  };
  const closeUser = (): void => {
    setIsUser(false);
  };
  const openNpp = (): void => {
    setIsNpp(true);
  };
  const closeNpp = (): void => {
    setIsNpp(false);
  };

  const openAllNpp = (): void => {
    setIsNpp(true);
  };
  const closeAllNpp = (): void => {
    setIsNpp(false);
  };

  const openNppTable = (): void => {
    setIsNppTable(true);
  };
  const closeNppTable = (): void => {
    setIsNppTable(false);
  };

  const openAllNppTable = (): void => {
    setIsAllNppTable(true);
  };
  const closeAllNppTable = (): void => {
    setIsAllNppTable(false);
  };

  const openUserTable = (): void => {
    if (!userInfo?.permission.createUser) {
      alert("no permission create User");
      return;
    }
    setIsUserTable(true);
  };
  const closeUserTable = (): void => {
    setIsUserTable(false);
  };

  const openSubTable = (): void => {
    if (!userInfo?.permission.createSubsidiary) {
      alert("no permission create Subsidiary");
      return;
    }
    setIsSubTable(true);
  };
  const closeSubTable = (): void => {
    setIsSubTable(false);
  };

  const openSub = (): void => {
    if (!userInfo?.permission.createSubsidiary) {
      alert("no permission edit");
      return;
    }
    setIsSubsidiary(true);
  };
  const closeSub = (): void => {
    setIsSubsidiary(false);
  };

  const openDelete = (): void => {
    setIsDelete(true);
  };
  const closeDelete = (): void => {
    setIsDelete(false);
  };

  return (
    <RenderContext.Provider
      value={{
        isModal,
        isUser,
        isCancel,
        isOpen,
        isRender,
        isAlert,
        isNpp,
        isAllNpp,
        isNppTable,
        isAllNppTable,
        isSubTable,
        isUserTable,
        isSubsidiary,
        isDelete,
        isLogOut,
        isChecked,
        ableChecked,
        disableChecked,

        openLogOut,

        openDelete,
        closeModal,

        openModal,
        closeDelete,

        openNpp,
        closeNpp,

        openNppTable,
        closeNppTable,

        openAllNpp,
        closeAllNpp,

        openAllNppTable,
        closeAllNppTable,

        openUserTable,
        closeUserTable,

        openSubTable,
        closeSubTable,

        openUser,
        closeUser,

        openSub,
        closeSub,

        openCancel,
        closeCancel,

        renderAction,
        renderOpen,

        renderClose,
      }}
    >
      {children}
    </RenderContext.Provider>
  );
};
