import * as S from './CustomButton.style';
interface ButtonProps {
    title: string;
    icon?: any;
    btnClick(): void;
    classname?: string;
    Width? : string;
    Height? : string
}

export default function CustomButton({ title, icon, btnClick, classname ,Width,Height}: ButtonProps) {

    return (
        <S.CustomBtn
            className={classname}
            onClick={btnClick}
            width={Width}
            height={Height}
        >
            {icon}
            <div>
                {title}
            </div>
        </S.CustomBtn>
    );
}
