import { deleteData, getData, postData, postImgData, patchData } from "apis/apiCore";
import Moment from 'moment';

export const postQna = async (title: string, content: string, imgData?: any, email?: string) => {
    const formData = new FormData();
    let tmp = [];

    imgData.forEach((item: any) => {
        formData.append("attachments", item);
    });

    const ImgResponse = await postImgData(`/posts/attachments`, formData);
    ImgResponse.data.data.map((x: any, idx: number) =>
        tmp?.push({
            fileId: x.id,
        })
    );
    const response = await postData(`/posts`, {
        title: title,
        content: content,
        attachments: tmp,
        email: email
    });
    return response;
};

export const getQnaDetail = async (id: string) => {
    const response = await getData(`/posts/${id}`);
    return response;
};

export const deleteQna = async (id: string) => {
    const response = await deleteData(`/posts/${id}`);
    return response;
};

const dateFormat = (date: string) => {
    if (date != "") {
        return Moment(date).format("MM/DD/YYYY HH:mm");
    } else {
        return null;
    }
};

export const getQnaList = async (text? : string ,page? : number,take? : number) => {
    const response = await getData(`/posts/me?sort=-id&searchQuery=${text}&page=${page}&take=${take}`);
    const temp = [];
    if (response.isSuccess) {
        console.log(response, 'response');
        for (let i = 0; i < response.data.data.length; i++) {
            temp.push({
                id: i + 1,
                postId: response.data.data[i].id,
                title: response.data.data[i].title,
                status: response.data.data[i].status,
                date: dateFormat(response.data.data[i].createdAt),
                user: response.data.data[i].userLoginId,
                subsidiary: response.data.data[i].subsidiaryCode,
            });
        }
        console.log(temp, 'temp');
        return {rowData : temp , pageData : response.data.meta};
    } else {
        return response;
    }
};


export const postResponse = async (id: string, data: string, imgData?: any) => {
    const formData = new FormData();
    console.log(imgData, 'imgData');
    let tmp = [];
    imgData.forEach((item: any) => {
        formData.append("attachments", item);
    });

    const ImgResponse = await postImgData(`/comments/attachments`, formData);
    ImgResponse.data.data.map((x: any, idx: number) =>
        tmp?.push({
            fileId: x.id,
        })
    );

    const response = await postData(`/comments`, {
        postId: id,
        content: data,
        attachments: tmp
    });
    return response;
};

export const updateResponse = async (id: string, data: string, existImg?: any, updataImg?: any) => {
    const formData = new FormData();
    let tmp = [];
    if (existImg.length > 0) {
        for (let i = 0; i < existImg.length; i++) {
            tmp?.push({
                attachmentId: existImg[i].id,
                fileId: existImg[i].File.id,
            });
        }

    }
    if (updataImg.length > 0) {
        updataImg.forEach((item: any) => {
            formData.append("attachments", item);
        });
        const ImgResponse = await postImgData(`/comments/attachments`, formData);
        ImgResponse.data.data.map((x: any, idx: number) =>
            tmp?.push({
                fileId: x.id,
            })
        );
    }

    const response = await patchData(`/comments/${id}`, {
        content: data,
        attachments: tmp
    });
    return response;
};
