import Chart from "react-apexcharts";
import { ChartProps } from "./ChartInterface";
import ExportBtn from "components/ExportBtn/ExportBtn";
import { CHART_COLORS } from "constants/ChartColor";
import HalfChartBox from "./HalfChartBox";


export default function AffiliateChanel({ getData, startDate, endDate, dropSub }: ChartProps) {

    const data: any = {
        series: [{
            name: "",
            data: getData ? getData?.map((x: any) => x.value) : []
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                    }
                },
                toolbar: {
                    show: false
                },
            },
            labels: getData ? getData.map((x: any) => x.key) : [],
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            xaxis: {
                categories: getData ? getData?.map((x: any) => x.key) : [],
                labels: {
                    show: true,
                    style: {
                        fontSize: '10px',
                        fontWeight: 500,
                        color: "#000",
                        fontFamily: "Samsung Sharp Sans"
                    },
                    rotate: 10
                }
            },
            tooltip: {
                enabled: true,
                x: {
                    show: false,
                },
                y: {
                    formatter: function (val, opts) {
                        const dataKey = opts.w.globals.labels[opts.dataPointIndex];
                        return `${dataKey}: ${val}`;
                    }
                }
            },
            colors: CHART_COLORS
        },
    };

    return (
        <>
            <HalfChartBox
                title="Number of Clicks on Affiliate Channel"
                wd="100%"
                ht="50%"
                Topcontents={
                    <ExportBtn
                        startDate={startDate}
                        endDate={endDate}
                        subCode={dropSub}
                        type="affiliate"
                    />
                }
                contents={
                    <Chart
                        options={data.options}
                        // series={test}
                        series={data.series}
                        type="bar"
                        width="100%"
                        height="100%"
                    />
                } />
        </>
    );
}