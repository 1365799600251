import { theme } from "constants/theme";
import styled from "styled-components";

export const ContentBox = styled.div<{wd: string ;ht : string,isDim? : boolean}>`
    position: relative;
    border: 1px solid #BFBFBF;

    background: ${props => `${props.isDim ? "#BFBFBF" : ""}`};
    width: ${props => `${props.wd}`};
    height: ${props => `${props.ht}`};

       @media (${theme.MOBILE}) {
        width: 100%;
        height: auto;
    }
`;

export const ChartTopContents = styled.div`
    display: flex;
    padding : 24px;
`

export const HalfChartTopContents = styled.div`
    display: flex;
    padding : 24px;
    padding-bottom: 0px;
`

export const ChartTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    font-family: "Samsung Sharp Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    `;
    
export const ChartBtn = styled.div`
    display: flex;
    gap : 10px;
    margin-left: auto;
    z-index: 999;
`;


export const ChartContents = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const SizeBox = styled.div`
    position: absolute;
    width: 70%;
    margin: auto;
    top : 0;
    bottom : 0;
    left: 0;
    right: 0;
`

export const HalfSizeBox = styled.div`
    position: absolute;
    width: 90%;
    margin: auto;
    top : 60px;
    bottom : 0;
    left: 0;
    right: 0;
`


