import {
  deleteData,
  getData,
  patchData,
  postData,
  postImgData,
} from "../apiCore";

export const getTvList = async (
  text?: string,
  page?: number,
  take?: number
) => {
  // const response = await getData(`/tvs/me?sort=-id&searchQuery=${text}&page=1&take=10`);
  const response = await getData(
    `/tvs/me?sort=-id&searchQuery=${text}&page=${page}&take=${take}`
  );
  const temp: any = [];
  if (response.isSuccess) {
    for (let i = 0; i < response.data.data.length; i++) {
      temp.push({
        idx: i,
        duid: response.data.data[i].duid,
        id: response.data.data[i].id,
        tvName: response.data.data[i].tvName,
        subsidiary: response.data.data[i].subsidiaryCode,
        retailer: response.data.data[i].retailer,
        storeName: response.data.data[i].storeName,
        pdp: response.data.data[i].pdp,
        loopType: response.data.data[i].loopType,
        imgDurationTime: response.data.data[i].imgDurationTime,
        use: response.data.data[i].status === "Running" ? true : false,
        nppstatus: response.data.data[i].status,
        img: response.data.data[i].TvImgs,
        video: response.data.data[i].TvVideos,
        startTime: response.data.data[i].periodStartTime,
        endTime: response.data.data[i].periodEndTime,
        playType: response.data.data[i].playType,
        isQrUrl: response.data.data[i].offlineQr,
        modelCode: response.data.data[i].deviceModel,
      });
    }
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].img) {
        temp[i].img.sort((a: any, b: any) => a.order - b.order);
      }
      if (temp[i].video) {
        temp[i].video.sort((a: any, b: any) => a.order - b.order);
      }
    }
    return { rowData: temp, pageData: response.data.meta };
  } else {
    return response;
  }
};

export const inPutTv = async (id: string) => {
  const response = await postData(`/tvs`, {
    duid: `123asd${id}`,
    language: "ko",
    retailer: `retailer${id}`,
    SubsidiaryCode: "TTTT",
    pdp: "https://www.samsung.com",
    scenario: "oled",
    storeName: `상점이름${id}`,
    tvName: `티비이름${id}`,
    deviceModel: `device${id}`,
  });
  return response;
};

export const previewTv = async (duid: any) => {
  const response = await postData(`/tvs/${duid}/preview`);
  return response;
};

export const changeTvSetting = async (
  id: any,
  inputData: any,
  imageData?: any,
  videoData?: any,
  replaceImg?: boolean,
  isMulti?: boolean
) => {
  inputData.imgs = [];
  inputData.videos = [];

  if (isMulti) {
    inputData.imgs = imageData.existImgs.map((x) => ({
      id: x.id,
      order: x.order,
      FileId: x.File.id,
    }));
    inputData.videos = videoData.existImgs.map((x) => ({
      id: x.id,
      order: x.order,
      FileId: x.File.id,
    }));

    if (imageData.uploadImgs.length > 0) {
      const formData = new FormData();
      imageData.uploadImgs.forEach((item: any) => {
        formData.append("images", item);
      });
      const imageResponse = await postImgData(`/tvs/images`, formData);
      imageResponse.data.data.map((x: any, idx: number) =>
        inputData.imgs?.push({
          // id : imageData.displayImgs.find((t: any) => t.File.originalName === x.originalName).id,
          FileId: x.id,
          order: imageData.displayImgs.findIndex(
            (t: any) => t.File.originalName === x.originalName
          ),
        })
      );
    } else {
      inputData.imgs = undefined;
    }

    //video
    if (videoData.uploadImgs.length > 0) {
      const formDatavideo = new FormData();
      videoData.uploadImgs.forEach((item: any) => {
        formDatavideo.append("videos", item);
      });
      const videoResponse = await postImgData(`/tvs/videos`, formDatavideo);
      videoResponse.data.data.map((x: any, idx: number) =>
        inputData.videos?.push({
          FileId: x.id,
          order: videoData.displayImgs.findIndex(
            (t: any) => t.File.originalName === x.originalName
          ),
        })
      );
    } else {
      inputData.videos = undefined;
    }
  } else {
    //img
    if (imageData.displayImgs.length > 0) {
      for (let i = 0; i < imageData.displayImgs.length; i++) {
        if (!imageData.displayImgs[i].upload) {
          console.log("ok");
          inputData.imgs.push({
            id: imageData.displayImgs[i].id,
            FileId: imageData.displayImgs[i].File.id,
            order: i,
          });
        }
      }
      if (imageData.uploadImgs.length > 0) {
        console.log("update");
        const formData = new FormData();
        imageData.uploadImgs.forEach((item: any) => {
          formData.append("images", item);
        });
        const imageResponse = await postImgData(`/tvs/images`, formData);
        imageResponse.data.data.map((x: any, idx: number) =>
          inputData.imgs?.push({
            FileId: x.id,
            order: imageData.displayImgs.findIndex(
              (t: any) => t.File.originalName === x.originalName
            ),
          })
        );
      }
    } else {
      console.log("cle");
      inputData.imgs = [];
    }
    //video
    if (videoData.displayImgs.length > 0) {
      for (let i = 0; i < videoData.displayImgs.length; i++) {
        if (!videoData.displayImgs[i].upload) {
          console.log("ok");
          inputData.videos.push({
            id: videoData.displayImgs[i].id,
            FileId: videoData.displayImgs[i].File.id,
            order: i,
          });
        }
      }
      if (videoData.uploadImgs.length > 0) {
        console.log("update");
        const formDatavideo = new FormData();
        videoData.uploadImgs.forEach((item: any) => {
          formDatavideo.append("videos", item);
        });

        const videoResponse = await postImgData(`/tvs/videos`, formDatavideo);
        videoResponse.data.data.map((x: any, idx: number) =>
          inputData.videos?.push({
            FileId: x.id,
            order: videoData.displayImgs.findIndex(
              (t: any) => t.File.originalName === x.originalName
            ),
          })
        );
      }
    } else {
      console.log("cle");
      inputData.videos = [];
    }
  }
  const response = await patchData(`/tvs/${id}`, inputData);
  return response;
};

export const changeAllTvSetting = async (
  inputData: any,
  imageData?: any,
  videoData?: any,
  searchQuery?: string
) => {
  inputData.imgs = [];
  inputData.videos = [];

  inputData.imgs = imageData.existImgs.map((x) => ({
    id: x.id,
    order: x.order,
    FileId: x.File.id,
  }));
  inputData.videos = videoData.existImgs.map((x) => ({
    id: x.id,
    order: x.order,
    FileId: x.File.id,
  }));

  if (imageData.uploadImgs.length > 0) {
    const formData = new FormData();
    imageData.uploadImgs.forEach((item: any) => {
      formData.append("images", item);
    });
    const imageResponse = await postImgData(`/tvs/images`, formData);
    imageResponse.data.data.map((x: any, idx: number) =>
      inputData.imgs?.push({
        // id : imageData.displayImgs.find((t: any) => t.File.originalName === x.originalName).id,
        FileId: x.id,
        order: imageData.displayImgs.findIndex(
          (t: any) => t.File.originalName === x.originalName
        ),
      })
    );
  } else {
    inputData.imgs = undefined;
  }

  //video
  if (videoData.uploadImgs.length > 0) {
    const formDatavideo = new FormData();
    videoData.uploadImgs.forEach((item: any) => {
      formDatavideo.append("videos", item);
    });
    const videoResponse = await postImgData(`/tvs/videos`, formDatavideo);
    videoResponse.data.data.map((x: any, idx: number) =>
      inputData.videos?.push({
        FileId: x.id,
        order: videoData.displayImgs.findIndex(
          (t: any) => t.File.originalName === x.originalName
        ),
      })
    );
  } else {
    inputData.videos = undefined;
  }

  const response = await patchData(
    `/tvs/me?searchQuery=${searchQuery}`,
    inputData
  );
  return response;
};

export const changeTvStatus = async (id?: any, data?: any) => {
  const response = await patchData(`/tvs/${id}`, data);
  return response;
};

export const inputTvImg = async (formData?: any) => {
  const response = await postImgData(`/tvs/images`, formData);
  return response;
};

export const deleteTvDuid = async (id: any) => {
  const response = await deleteData(`/tvs/${id}`);
  return response;
};

export const getTvQr = async (duid: any) => {
  const response = await getData(`/tvs/qr?duid=${duid}`);
  return response;
};
