import { useEffect, useState } from "react";
import useMediaQuery from "./useMediaQuery";
import { theme } from "constants/theme";

function useIsMediaQuery(type: string) {
  const [isMediaQuery, setIsMediaQuery] = useState<boolean>(false);
  const [query, setQuery] = useState<string>(`(${theme.PC})`);

  const media = useMediaQuery(query);

  useEffect(() => {
    switch (type) {
      case "MOBILE":
        setQuery(`(${theme.MOBILE})`);
        break;
      // case "TABLET":
      //   setQuery(`(${theme.TABLET})`);
      //   break;
      case "PC":
        setQuery(`(${theme.PC})`);
    }
  }, [type]);

  useEffect(() => {
    setIsMediaQuery(media);
  }, [media]);

  return isMediaQuery;
}

export default useIsMediaQuery;
