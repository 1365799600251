import { useState, useContext, useEffect } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { GridColDef } from "@mui/x-data-grid";
import { Box, SvgIcon } from "@mui/material";
import { getTableProjectList } from "apis/Admin/AdminApi";
import { RenderContext } from "hooks/context";
import { OverFlowBox } from "pages/common.style";
import * as S from "pages/common.style";
import PageTop from "components/PageTop/PageTop";
import DataTable from "components/Table/DataTable";
import PackageModal from "components/modal/PackageModal";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { dateFormat } from "util/Formatter";

export default function Packagefile() {

  const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");

  console.log(userInfo, 'userInfo');

  const {
    isRender,
    isUser,
    renderAction,
    openUser,
  } = useContext(RenderContext);
  const [selectedData, setSelectedData] = useState<any>();

  const [rowData, setRowData] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [take, setTake] = useState<number>(10);

  useEffect(() => {
    requestList();
  }, [text, page, take, isRender]);

  const requestList = async () => {
    try {
      const response: any = await getTableProjectList(page + 1, take, text, false);
      setRowData(response.rowData);
      setPageCount(response.pageData?.itemCount);
    } catch (e: any) {
      console.log(e, 'getList Error');
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name', headerName: 'Project',
      minWidth: 150,
      flex: 1.5,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.name}
        </OverFlowBox>
      )
    },
    {
      field: 'version', headerName: 'Version',
      minWidth: 200,
      flex: 2,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.version}
        </OverFlowBox>
      )
    },
    {
      field: 'packageUpdate', headerName: 'Date',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <OverFlowBox>
          {row.row.packageUpdate != null && row.row.packageUpdate != "" && dateFormat(row.row.packageUpdate)}
        </OverFlowBox>
      )
    },
    {
      field: 'packageLink', headerName: 'Download Link',
      minWidth: 200,
      flex: 2,
      renderCell: (row: any) => (
        <>
          {row.row.packageLink != null &&
            <S.DownloadLink href={row.row.packageLink} target='_blank'>
              <SvgIcon component={SaveAltIcon} inheritViewBox />
              download
            </S.DownloadLink>
          }
        </>
      )
    },
    userInfo.group.name === "SUPER" &&
    {
      field: 'action', headerName: 'Actions',
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => (
        <>
          <S.BtnBox>
            <S.BtnWrapper>
              <EditOutlinedIcon onClick={(e: any) => {
                e.stopPropagation();
                setSelectedData([row.row]);
                openUser();
              }
              }
              />
            </S.BtnWrapper>
          </S.BtnBox>
        </>
      )
    }
  ];


  const [IsEdit, setIsEdit] = useState<boolean>(false);

  return (
    <>
      <PageTop
        title="App Download"
        icon={<SaveAltIcon />}
      />
      <Box
        sx={{
          width: "100%",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(238, 238, 238, 1)",
            color: "black",
            fontFamily: "SamsungOne",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "16px"
          }
        }}
      >
        <DataTable
          setSearchText={setText}
          setSearchPage={setPage}
          rowCount={pageCount}
          setTableCount={setTake}
          apiData={rowData}
          columnData={columns}
          placeHolder={"Project, Version"}
          btnContent={
            <>
            </>
          }
        />
      </Box>
      {isUser &&
        <PackageModal
          isOpen={isUser}
          onClose={() => setIsEdit(false)}
          selectedData={selectedData}
        />
      }
    </>
  );
}
