import { theme } from "constants/theme";
import styled from "styled-components";

const local1920 = '1920px';
const local1513 = '1513px';

export const ContentBox = styled.div<{ height?: string; }>`
    width: 846px;
    height: 370px;

    font-family: "SamsungOne";

    display: flex;
    flex-direction: column;

    align-items: flex-start;

    @media (max-width: ${local1920}) {
        width: 734px;
        height: 340px;
    }

    @media (max-width: ${local1513}) {
        width: 606px;
        height: 320px;
    }
`;

export const NumberTitle = styled.div`
    margin-top: 42px;
    margin-left: 50px;
    color: #00C2FF;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 19px;

    @media (max-width: ${local1920}) {
        margin-left: 24px;
    }

    @media (max-width: ${local1513}) {
        font-size: 18px;
        margin-left: 24px;
    }
`

export const DesText = styled.div`
    margin-left: 50px;
    margin-top: 14px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;

    > a {
        text-decoration: underline;
        cursor: pointer;
        color: #000;
        font-style: normal;
        font-weight: 600;
    }

    @media (max-width: ${local1920}) {
        font-size: 14px;
        margin-left: 24px;
    }

    @media (max-width: ${local1513}) {
        font-size: 11px;
        margin-left: 24px;
    }
`
export const SubText = styled.div`
    margin-top: 5px;
    color: #F00;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px;

    > span {
        font-size: 16px;
    }

    @media (max-width: ${local1920}) {
        font-size: 14px;

        > span {
            font-size: 14px;
        }
    }

    @media (max-width: ${local1513}) {
        font-size: 12px;

        > span {
            font-size: 12px;
        }
    }
`

export const MobileImgBox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-left: 40px;
    margin-top: 10px;

    @media (max-width: ${local1920}) {
        margin-left: 18px;
    }

    @media (max-width: ${local1513}) {
        margin-left: 18px;
    }
`

export const BtnBox = styled.div`
    width: 94%;
    margin-top: 32px;
    margin-left: 24px;
    display: flex;

    @media (max-width: ${local1920}) {
        margin-left: 18px;
        margin-top: 10px;
    }

    @media (max-width: ${local1513}) {
        margin-left: 18px;
        margin-top: 10px;
    }
`