import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { Button } from "@mui/material";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiListItem from "@material-ui/core/ListItem";

import UserModal from "../modal/UserModal";
import { MENU_TEXT, PROFILE_TEXT, TITLE_TEXT } from "constants/CssConstant";
import { SIDEBAR_NAV, SIDEBAR_NAV_LOCALIZATION } from "constants/SidebarConstant";
import { MediaType } from "util/media";
import * as S from "./Header.style";
import { RenderContext } from "hooks/context";
import Footer from "components/Footer/Footer";
import EditMyModal from "components/modal/EditMyModal";

import packageJson from "../../../package.json";

import useSidebarStore from "util/store/sideNavStore";

import {
    DesktopWindowsOutlined as DesktopWindowsOutlinedIcon,
    DashboardOutlined as DashboardOutlinedIcon,
    ManageAccountsOutlined as ManageAccountsOutlinedIcon,
    Translate as TranslateIcon,
    QuestionAnswerOutlined as QuestionAnswerOutlinedIcon,
    DescriptionOutlined as DescriptionOutlinedIcon,
    Translate as Local,
    SaveAlt as App
} from '@mui/icons-material';

const getIconComponent = (iconName) => {
    switch (iconName) {
        case 'DesktopWindowsOutlinedIcon':
            return <DesktopWindowsOutlinedIcon />;
        case 'DashboardOutlinedIcon':
            return <DashboardOutlinedIcon />;
        case 'ManageAccountsOutlinedIcon':
            return <ManageAccountsOutlinedIcon />;
        case 'TranslateIcon':
            return <TranslateIcon />;
        case 'QuestionAnswerOutlinedIcon':
            return <QuestionAnswerOutlinedIcon />;
        case 'DescriptionOutlinedIcon':
            return <DescriptionOutlinedIcon />;
        case 'local':
            return <Local />;
        case 'appdownload':
            return <App />;
        default:
            return null;
    }
};

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    drawerwd?: number;
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
    drawerwd?: number;
}>(({ theme, open, drawerwd }) => ({
    flexGrow: 1,
    padding: theme.spacing(1.5),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${drawerwd}px)`,
    marginLeft: `-${drawerwd}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, drawerwd }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        // marginLeft: `${drawerWidth}px`,
        marginLeft: `${drawerwd}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function DrawerLeft({
    children,
}: {
    children: React.ReactNode;
}) {
    const { sidebarNav, setSidebarNav } = useSidebarStore();

    const media = MediaType();
    const locationPath = useLocation();
    const [currentPath, setCurrnetPath] = useState<string>("Dashboard");
    const [drawerWidth, setDrawerWidth] = useState<number>(290);
    const { isModal, openModal } = useContext(RenderContext);

    // useEffect(()=>{
    //     console.log(packageJson.version ,' ver')
    // },[])


    useEffect(() => {
        if (media === "MOBILE") {
            setDrawerWidth(370);
            // setOpen(false)
        } else {
            setDrawerWidth(290);
        }
    }, [media]);

    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [subOpen, setSubOpen] = useState(false);
    const [navSelect, setNavSelect] = useState<any>([]);

    const navi = useNavigate();

    const handleDrawer = () => {
        setOpen(!open);
    };

    const nestMenuOn = (data: any) => {
        // console.log(data,'enter');
        setCurrnetPath(data.text);
        if (data.url === "/admin") {
            setSubOpen(true);
        } else {
            if (data.url === "/subsidiary" || data.url === "/user") {
                setSubOpen(true);
            } else {
                setSubOpen(false);
            }
            if (media === "MOBILE") {
                setOpen(false);
            }
            navi(data.url);
        }
    };

    const selectedNav = (data: any) => {
        if (data.url === locationPath.pathname) {
            return true;
        } else {
            return false;
        }
    };

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };

    const [IsEdit, setIsEdit] = useState<boolean>(false);
    const userInfo = JSON.parse(localStorage.getItem("UserData") || "{}");

    const selecedItme = [
        {
            id: userInfo?.id,
            subsidiary: userInfo?.subsidiary,
            authority: userInfo?.authority,
            information: userInfo?.information,
            name: userInfo?.name
        }
    ];

    // useEffect(() => {
    //     if (userInfo?.permission?.modifyLocalization) {
    //         setNavSelect(SIDEBAR_NAV_LOCALIZATION);
    //     } else {
    //         setNavSelect(SIDEBAR_NAV);
    //     }
    // }, [userInfo]);

    useEffect(() => {
        if (media === "MOBILE") {
            // console.log("snb", media);
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [media]);


    return (
        <Box sx={{ display: 'flex' }}>
            {/* {IsEdit && */}
            {isModal &&
                <EditMyModal isOpen={isModal} onClose={() => setIsEdit(false)} selectedData={selecedItme} isNew={false} isMyEdit />
            }
            <CssBaseline />
            <AppBar position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    background: media === "MOBILE" ? "white" : "#e8e8e8",
                    boxShadow: media === "MOBILE" ? "none" : ""
                }}
                drawerwd={drawerWidth}
            >
                {media === "MOBILE" ?
                    <>
                        {!open &&
                            <S.MobileHeaderContainer>
                                <IconButton
                                    color="default"
                                    aria-label="open drawer"
                                    onClick={handleDrawer}
                                    sx={{
                                        position: "absolute",
                                        ml: "10px",
                                        mt: "5px"
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>

                                <S.MobileHeader>
                                    {currentPath}
                                </S.MobileHeader>
                            </S.MobileHeaderContainer>
                        }
                    </>
                    :
                    <Toolbar>
                        <IconButton
                            color="default"
                            aria-label="open drawer"
                            onClick={handleDrawer}
                            edge="start"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            sx={{
                                color: TITLE_TEXT
                            }}
                        >
                            Samsung Retail App Console Ver {packageJson.version}
                        </Typography>
                    </Toolbar>
                }
            </AppBar>

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                {media !== "MOBILE" && <DrawerHeader />}
                <div style={{ display: "flex", alignItems: "center", height: "80px", padding: "24px", gap: "14px" }}>
                    <Avatar alt="Avatar" variant="circular">
                        <PersonIcon />
                    </Avatar>
                    <div style={{
                        width: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: "#202020",
                        fontFamily: "SamsungSharpSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "600",
                    }}
                    >
                        {selecedItme[0].name}<br />
                        <span
                            style={{
                                fontSize: "12px",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                            // onClick={() => setIsEdit(true)}
                            onClick={() => openModal()}
                        >Edit My Profile</span>
                    </div>

                    <Button
                        // disabled
                        onClick={() => logout()}
                        variant="contained"
                        size="small"
                        sx={{
                            width: "76px",
                            height: "40px",
                            fontSize: "13px",
                            background: "#e8e8e8",
                            color: "black",
                            ":hover": {
                                background: "#e8e8e8",
                            }
                        }}
                    >
                        LOGOUT
                    </Button>
                </div>
                <Divider />
                <List sx={{
                    paddingTop: "0px",
                    '&& .Mui-selected': {
                        bgcolor: 'rgba(33, 150, 243, 0.08)',
                        '&, & .MuiListItemIcon-root': {
                            color: '#2196F3',
                        },
                        "&, & .MuiTypography-root": {
                            color: "#2196F3"
                        }
                    },
                    '& .MuiListItemButton-root:hover': {
                        bgcolor: 'rgba(33, 150, 243, 0.08)',
                        '&, & .MuiListItemIcon-root': {
                            color: '#2196F3',
                        },
                        "&, & .MuiTypography-root": {
                            color: "#2196F3"
                        }
                    },
                }}>
                    {sidebarNav
                        .filter((x) => x.isVisible) 
                        .map((x: any, index: any) => (
                            <div key={index}>
                                <ListItemButton
                                    onClick={() => nestMenuOn(x)}
                                    sx={{
                                        ":hover": {
                                            color: '#2196F3'
                                        },
                                    }}
                                    selected={selectedNav(x)}
                                >
                                    <ListItemIcon key={index} sx={{}}>
                                        {getIconComponent(x.icon)}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={x.text}
                                        primaryTypographyProps={{ fontSize: MENU_TEXT }}
                                    />
                                    {x.sub != null && (
                                        <>
                                            {subOpen ? <ExpandLess /> : <ExpandMore />}
                                        </>
                                    )}
                                </ListItemButton>
                                {x.sub != null && (
                                    <Collapse in={subOpen} timeout="auto" unmountOnExit>
                                        <List disablePadding>
                                            {x.sub.map((t: any, idx: number) => (
                                                <div key={idx}>
                                                    <ListItemButton
                                                        sx={{ pl: 4 }}
                                                        onClick={() => nestMenuOn(t)}
                                                        selected={selectedNav(t)}
                                                    >
                                                        <ListItemText
                                                            primary={t.text}
                                                            sx={{ pl: 5 }}
                                                            primaryTypographyProps={{ fontSize: MENU_TEXT }}
                                                        />
                                                    </ListItemButton>
                                                </div>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        ))}

                </List>
            </Drawer>
            <Main open={open} sx={{ width: "inherit", overflow: "hidden" }} drawerwd={drawerWidth}>
                <DrawerHeader />
                {children}
            </Main>
        </Box >

    );
}


