export const INPUT_LABEL_VALUE = {
    color: "#525457",
    fontSize: "15.3px",
    fontFamliy: "SamsungOne",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "16px"
};
export const INPUT_DISABLED_VALUE = {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "15.3px",
    fontFamliy: "SamsungOne",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "16px"
};

export const INPUT_VALUE = {
    color: "#525457",
    fontSize: "16px",
    fontFamliy: "SamsungOne",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.15px"
};
export const INPUT_VALUE_TITLE = {
    color: "#525457",
    fontSize: "15.3px",
    fontFamliy: "SamsungOne",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0.15px"
};

export const INPUT_MIXED_VALUE = {
    color: "#000",
    fontSize: "16px",
    fontFamliy: "SamsungOne",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.15px"
};

export const DROP_MENU_VALUE = {
    color: "#202020",
    fontSize: "14px",
    fontFamliy: "SamsungOne",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22px",
};

export const TEXT_PLACEHOLDER = {
    color: "rgba(0, 0, 0, 0.38)",
    fontFamily: "SamsungOne",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0.15px",
};

export const TABEL_CELL = {
    color: "#202020",
    fontFamily: "SamsungOne",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
};

export const TABEL_HEADER = {
    color: "#202020",
    fontFamily: "SamsungOne",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 800,
};

export const MENU_TEXT = {
    color: "#202020",
    fontFamily: "SamsungOne",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
};

export const TITLE_TEXT = {
    color: "#202020",
    fontFamily: "Samsung Sharp Sans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
};

export const PROFILE_TEXT = {
    color: "#202020",
    fontFamily: "SamsungSharpSans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
};

export const BTN_TEXT = {
    fontFamily: "SamsungOne",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "800",
};

export const BTN_TEXT_RED = {
    fontFamily: "SamsungOne",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "800",
    color: "red",
};

export const CHECK_BOX_TEXT = {
    fontFamily: "SamsungOne",
    color: "red",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
}

export const QA_TEXT = {
    fontFamily: "SamsungOne",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "800",
    color : "rgba(0, 0, 0, 0.38)"
};
