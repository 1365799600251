

export const IMAGETIME = [
    {
        key : "5 secs",
        value : 5
    },
    {
        key : "10 secs",
        value : 10
    },
    {
        key : "15 secs",
        value : 15
    },
    {
        key : "20 secs",
        value : 20
    },
    {
        key : "30 secs",
        value : 30
    },
    {
        key : "60 secs",
        value : 60
    },
    {
        key : "Input the time",
        value : "Input the time"
    },
]
 
export const IMAGETIME_MIXED = [

    {
        key : "Mixed",
        value : 0
    },
    {
        key : "5 sec",
        value : 5
    },
    {
        key : "10 secs",
        value : 10
    },
    {
        key : "15 secs",
        value : 15
    },
    {
        key : "20 secs",
        value : 20
    },
    {
        key : "30 secs",
        value : 30
    },
    {
        key : "60 secs",
        value : 60
    },
    {
        key : "Input the time",
        value : "Input the time"
    },
]
 
