export interface test {
    [key: string]: {
        title: string;
        icon: any,
        isExcel: boolean,
        isRegister: boolean,
        dataTable: boolean,
    };
}


export const NPPTIME = [
    {
        key: "Every 1 min(UTC)",
        value: 1
    },
    {
        key: "Every 2 mins(UTC)",
        value: 2
    },
    {
        key: "Every 3 mins(UTC)",
        value: 3
    },
    {
        key: "Every 5 mins(UTC)",
        value: 5
    },
    {
        key: "Every 10 mins(UTC)",
        value: 10
    },
    {
        key: "Every 30 mins(UTC)",
        value: 30
    },
    {
        key: "Every 1 hour(UTC)",
        value: 60
    },
    {
        key: "Every 2 hours(UTC)",
        value: 120
    },
    {
        key: "Every 4 hours(UTC)",
        value: 240
    },
];


export const NPPTIME_MIXED = [
    {
        key: "Mixed",
        value: 0
    },
    {
        key: "Every 1 min(UTC)",
        value: 1
    },
    {
        key: "Every 2 mins(UTC)",
        value: 2
    },
    {
        key: "Every 3 mins(UTC)",
        value: 3
    },
    {
        key: "Every 5 mins(UTC)",
        value: 5
    },
    {
        key: "Every 10 mins(UTC)",
        value: 10
    },
    {
        key: "Every 30 mins(UTC)",
        value: 30
    },
    {
        key: "Every 1 hour(UTC)",
        value: 60
    },
    {
        key: "Every 2 hours(UTC)",
        value: 120
    },
    {
        key: "Every 4 hours(UTC)",
        value: 240
    },
]

