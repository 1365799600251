import { useState, useContext, useEffect, useRef } from "react";
import { RenderContext } from "hooks/context";
import * as S from "./WriteQnA.style";
import { Button } from "@mui/material";
import { BTN_TEXT, QA_TEXT } from "constants/CssConstant";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getQnaDetail, postResponse } from "apis/Qna/QnaApi";
import CloseAlert from "components/alert/CloseAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { getData } from "apis/apiCore";
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteAlert from "components/alert/DeleteAlert";

export default function QnaDetail() {
    const {
        isSubsidiary,
        renderAction,
        openSub
    } = useContext(RenderContext);

    const navi = useNavigate();
    const getState: any = useLocation();

    const userInfo = JSON.parse(localStorage.getItem("User") || "{}");
    console.log(userInfo, 'userInfo');

    const [getData, setGetData] = useState<any>([]);
    const [imgData, setImgData] = useState<any>([]);
    const [inputComment, setInputCommet] = useState<string>("");
    const [isDelelte, setIsDelete] = useState<boolean>(false);

    const [inputImg, setInputImg] = useState<any>([]);
    const [getResImg, setGetResImg] = useState<any>([]);


    const requsetData = async () => {
        const requsetData = await getQnaDetail(getState.state.getId);
        console.log(requsetData.data, 'asdasd');
        setGetData(() => requsetData?.data);
        setInputCommet(requsetData?.data.Comment[0]?.content);
        setImgData(requsetData?.data.PostAttachment);
        setGetResImg(requsetData?.data?.Comment[0]?.CommentAttachment);
    };

    useEffect(() => {
        requsetData();
    }, [getState.state.getId]);

    useEffect(() => {
        if (inputComment != "" && inputComment != undefined) {
            setIsAble(false);
        } else {
            setIsAble(true);
        }
    }, [inputComment]);

    const submitAction = async () => {
        if (isAble) {
            return;
        }
        try {
            const response = await postResponse(getState.state.getId, inputComment, inputImg);
            console.log(response);
            navi("/qna");
        } catch (error) {
            console.log(error);
        }
    };


    const handleDownloadFile = async (fileUrl: string, fileName: string) => {
        const file = await fetch(fileUrl);
        const blob = await file.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = fileName;
        link.href = url;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const [closeModal, setCloseModal] = useState<boolean>(false);
    const [isAble, setIsAble] = useState<boolean>(true);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSizeInBytes) {
            alert("The file is too large. Please select a file smaller than 5MB.");
            return;
        }
        setInputImg([...inputImg, file]);
    };

    const handleRemoveImage = (index: number) => {
        const newImgData = [...inputImg];
        newImgData.splice(index, 1);
        setInputImg(newImgData);
    };
    console.log(getData, 'get img');

    return (
        <>
            <S.TopSection>
                <S.TitleBox>
                    Compose Response
                </S.TitleBox>
                {userInfo.Permission.createUser ?
                    <S.BtnBox>
                        <S.IconWrapper>
                            <DeleteOutlineIcon
                                onClick={() => setIsDelete(true)}
                                color="warning" />
                        </S.IconWrapper>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="medium"
                            sx={{
                                color: QA_TEXT,
                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                '&:hover': {
                                    backgroundColor: "white",
                                    border: "1px solid rgba(0, 0, 0, 0.12)",
                                }
                            }}
                            onClick={() => setCloseModal(true)}
                        >
                            CANCLE
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            sx={{
                                color: BTN_TEXT,
                            }}
                            onClick={submitAction}
                            disabled={isAble}
                        >
                            SUBMIT
                        </Button>
                    </S.BtnBox>
                    :
                    <>
                        {getData.userLoginId === userInfo.loginId ?
                            <S.BtnBox>
                                <S.IconWrapper>
                                    <DeleteOutlineIcon
                                        onClick={() => setIsDelete(true)}
                                        color="warning" />
                                </S.IconWrapper>
                            </S.BtnBox>
                            :
                            <S.BtnBox>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    sx={{
                                        color: BTN_TEXT,
                                    }}
                                    onClick={() => navi("/qna")}
                                >
                                    LIST
                                </Button>
                            </S.BtnBox>
                        }
                    </>
                }
            </S.TopSection>

            <S.ContentWrapper>
                <S.ResponseTitle>
                    {getData.title}
                </S.ResponseTitle>
                <S.ResponseName>
                    {getData.userLoginId}
                </S.ResponseName>
                {getData.email != "" &&
                    <S.ResponseName>
                    {getData.email}
                    </S.ResponseName>
                }
                <S.ResponseContent>
                    {getData.content}
                </S.ResponseContent>
                <S.ImgWrapper>
                    {imgData?.length > 0 &&
                        imgData.map((x: any, idx: number) => {
                            return (
                                <S.ImgBox key={x.File.url}
                                >
                                    <UploadFileIcon
                                        color="primary"
                                        style={{ flex: 1 }}
                                    />
                                    <div style={{ flex: 11 }}>
                                        {x.File.originalName}
                                    </div>
                                    <div style={{ flex: 1, display: "flex", gap: "14px" }}>
                                        <DownloadIcon
                                            sx={{
                                                '&:hover': {
                                                    color: "#2196F3"
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleDownloadFile(x.File.url, x.File.originalName)}
                                        />
                                    </div>

                                </S.ImgBox>
                            );
                        })
                    }
                </S.ImgWrapper>
                {userInfo.Permission.createUser ?
                    <div style={{ marginTop: "52px" }}>
                        <S.ResponseTitle>
                            Response <span style={{ color: "red" }}> *</span>
                        </S.ResponseTitle>
                        <S.InputArea
                            $height={400}
                            placeholder={"Input Response"}
                            value={inputComment || ""}
                            onChange={(e) => setInputCommet(e.target.value)}
                        />
                        <S.ADDsection>
                            <UploadFileIcon color="primary" />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "12px",
                                }}
                            >
                                <label htmlFor="upload-photo" style={{ marginLeft: "auto" }}>
                                    <input
                                        style={{ display: 'none' }}
                                        id="upload-photo"
                                        name="upload-photo"
                                        type="file"
                                        onChange={(e) => handleFileInputChange(e)}
                                        accept=".jpg, .jpeg, .png, .svg, .GIF"
                                    />
                                    <S.ADDTEXT>
                                        Click to upload
                                    </S.ADDTEXT>
                                </label>
                            </div>
                            SVG, PNG, JPG or GIF (max. 5MB per file)
                        </S.ADDsection>
                        <S.ImgWrapper>
                            {getResImg?.length > 0 &&
                                getResImg.map((x: any, idx: number) => {
                                    return (
                                        <S.ImgBox key={x.File.url}
                                            onClick={() => handleDownloadFile(x.File.url, x.File.originalName)}
                                        >
                                            <UploadFileIcon
                                                color="primary"
                                                style={{ flex: 1, cursor: "pointer" }}
                                            />
                                            <div style={{ flex: 11 }}>
                                                {x.File.originalName}
                                            </div>
                                        </S.ImgBox>
                                    );
                                })
                            }
                        </S.ImgWrapper>
                        <S.ImgWrapper>
                            {inputImg.length > 0 &&
                                inputImg.map((x: any, idx: number) => {
                                    return (
                                        <S.ImgBox>
                                            <UploadFileIcon
                                                color="primary"
                                                style={{ flex: 1 }} />
                                            <div style={{ flex: 11 }}>
                                                {x.name}
                                            </div>
                                            <DeleteOutlineOutlinedIcon
                                                onClick={() => handleRemoveImage(idx)}
                                                style={{ flex: 1 }}
                                            />
                                        </S.ImgBox>
                                    );
                                })
                            }
                        </S.ImgWrapper>
                    </div>
                    :
                    <div style={{ display: "flex", flexDirection: "column", gap: "30px", marginTop: "52px" }}>
                        <S.ResponseTitle>
                            Response
                        </S.ResponseTitle>
                        <S.ResponseContent>
                            {inputComment}
                        </S.ResponseContent>
                        <S.ImgWrapper>
                            {getResImg?.length > 0 &&
                                getResImg.map((x: any, idx: number) => {
                                    return (
                                        <S.ImgBox key={x.File.url}>
                                            <UploadFileIcon
                                                color="primary"
                                                style={{ flex: 1 }}
                                            />
                                            <div style={{ flex: 11 }}>
                                                {x.File.originalName}
                                            </div>

                                            <div style={{ flex: 1, display: "flex", gap: "14px" }}>
                                                <DownloadIcon
                                                    sx={{
                                                        '&:hover': {
                                                            color: "#2196F3"
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleDownloadFile(x.File.url, x.File.originalName)}
                                                />
                                            </div>
                                        </S.ImgBox>
                                    );
                                })
                            }
                        </S.ImgWrapper>
                    </div>
                }

            </S.ContentWrapper>
            {closeModal &&
                <CloseAlert
                    isOpen={closeModal}
                    onClose={() => setCloseModal(false)}
                    navi={() => navi("/qna")}
                />
            }
            {isDelelte &&
                <DeleteAlert
                    isOpen={isDelelte}
                    onClose={() => setIsDelete(false)}
                    navi={() => navi("/qna")}
                    deleteId={getState.state.getId}
                />
            }
        </>
    );
}
