import { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import styled from "styled-components";

interface AlertProps {
    isOpen: any;
    onClose: () => void;
    contents?: string;
}

export default function PopupAlert({ isOpen, contents, onClose }: AlertProps) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={() => onClose()}
            >
                <DialogTitle id="alert-dialog-title">
                    <TextSection>
                    {contents}
                    </TextSection>
                </DialogTitle>
                <BtnContainer>
                    <OkBtn onClick={() => onClose()}>CLOSE</OkBtn>
                </BtnContainer>
            </Dialog>
        </>
    );
}

const TextSection = styled.div`
    text-align: center;
    font-family: SamsungOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
`;


export const OkBtn = styled.div`
    width: 100px;
    height: 40px;
    background-color: #e8e8e8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #2196F3;
        color : white;
    }
`

export const BtnContainer = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

`