import { useState, useContext } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';

import { RenderContext } from "hooks/context";
import styled from "styled-components";
import CustomButton from "components/Button/CustomButton";
import PopupAlert from "./PopupAlert";
interface AlertProps {
    isOpen: any;
    onClose: () => void;
    requserApi?: () => void;
}

export default function ConfirmAlert({ isOpen, onClose, requserApi }: AlertProps) {

    const { closeDelete } = useContext(RenderContext);
    const [isError, setIsError] = useState<boolean>(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: "5px",
        boxShadow: 24,
    };

    const requestAction = async () => {
        try {
            const response = await requserApi();
            // console.log(response, 'table');
            console.log(response, 'delete response');
            onClose();
            // setTimeout(() => {
            setIsError(true);
            // }, 1000);
        } catch (e: any) {
            console.log(e, 'delete Error');
        }
    };

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onClose}
            >
                <Box sx={style}>
                    <Wrapper>
                        <TextSection>
                            Are you sure you want to delete the selected item from the table?
                        </TextSection>
                        <BtnBox>
                            <CustomButton
                                title="DELETE"
                                classname={"save-btn"} //disable-check
                                icon={
                                    <></>
                                }
                                btnClick={() => requestAction()}
                                Width="96"
                                Height="40"
                            />
                            <CustomButton
                                title="CANCEL"
                                classname="cancel-btn"
                                icon={
                                    <></>
                                }
                                Width="96"
                                Height="40"
                                btnClick={() => onClose()} />
                        </BtnBox>
                    </Wrapper>

                </Box>
            </Modal>
        </div>
    );
}

const Wrapper = styled.div`
    width: 300px;
    height: 152px;

    padding-top : 24px;
    padding-bottom : 24px;

    padding-left: 16px;
    padding-right: 16px;
`;
const TextSection = styled.div`
    text-align: center;
    font-family: SamsungOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
`;

const BtnBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    gap : 20px;
`;