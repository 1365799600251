import { useState, useContext, useEffect } from "react";
import * as S from "pages/common.style";

import { Box, SvgIcon } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import Switch from "@mui/material/Switch";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";

import {
  changeTvSetting,
  changeTvStatus,
  deleteTvDuid,
  getTvList,
  getTvQr,
} from "apis/TvStatus/TvStatusApi";

import { RenderContext } from "hooks/context";
import { NPPTIME } from "constants/NppLoopConstant";
import { IMAGETIME } from "constants/ImageDurationConstant";

import PopupAlert from "components/alert/PopupAlert";
import PageTop from "components/PageTop/PageTop";
import DataTable from "components/Table/DataTable";
import SettingModal from "components/modal/SettingModal";
import ConfirmAlert from "components/alert/ConfirmAlert";
import CustomButton from "components/Button/CustomButton";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
// import QRCode from 'qrcode.react';
import QRCode from "qrcode";
import { dateFormat } from "util/Formatter";

export default function Tvstatus() {
  const { isRender, isNpp, renderAction, openNpp } = useContext(RenderContext);
  const [isSetting, setIsSetting] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);
  const [deleteName, setDeleteName] = useState<string>("");
  const [deleteID, setDeleteID] = useState<string>("");
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [rowData, setRowData] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [take, setTake] = useState<number>(10);

  useEffect(() => {
    requestList();
  }, [text, page, take, isRender]);

  const requestList = async () => {
    try {
      const response: any = await getTvList(text, page + 1, take);
      setRowData(response.rowData);
      setPageCount(response.pageData?.itemCount);
    } catch (e: any) {
      console.log(e, "getList Error");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "subsidiary",
      headerName: "Subsidiary",
      width: 150,
      // flex : 1,
      renderCell: (row: any) => (
        <S.OverFlowBox>{row.row.subsidiary}</S.OverFlowBox>
      ),
    },
    {
      field: "retailer",
      headerName: "Retailer",
      width: 150,
      // flex: 1,
      renderCell: (row: any) => (
        <S.OverFlowBox>{row.row.retailer}</S.OverFlowBox>
      ),
    },
    {
      field: "storeName",
      headerName: "Store",
      width: 150,
      // flex: 1,
      renderCell: (row: any) => (
        <S.OverFlowBox>{row.row.storeName}</S.OverFlowBox>
      ),
    },
    {
      field: "tvName",
      headerName: "TV Name",
      width: 300,
      // flex: 2,
      renderCell: (row: any) => <S.OverFlowBox>{row.row.tvName}</S.OverFlowBox>,
    },
    {
      field: "modelCode",
      headerName: "Model Code",
      width: 300,
      // flex: 2,
      renderCell: (row: any) => (
        <S.OverFlowBox>{row.row.modelCode}</S.OverFlowBox>
      ),
    },
    {
      field: "pdp",
      headerName: "Product Detail Page",
      width: 300,
      // flex: 2,
      renderCell: (row: any) => <S.OverFlowBox>{row.row.pdp}</S.OverFlowBox>,
    },
    {
      field: "loopType",
      headerName: "NPP LOOP Type",
      width: 150,
      // flex: 1,
      renderCell: (row: any) => (
        <S.OverFlowBox>
          {NPPTIME.find((x: any) => x.value == row.row.loopType)?.key}
        </S.OverFlowBox>
      ),
    },
    {
      field: "startTime",
      headerName: "Promotion Period",
      width: 150,
      // flex: 1,
      renderCell: (row: any) => (
        <S.OverFlowBox>
          {row.row.startTime && (
            <>
              {dateFormat(row.row.startTime)}~ <br />
              {dateFormat(row.row.endTime)}
            </>
          )}
        </S.OverFlowBox>
      ),
    },
    {
      field: "imgDurationTime",
      headerName: "Image Duration Time",
      width: 150,
      // flex: 1,
      renderCell: (row: any) => (
        <S.OverFlowBox>
          {row.row.imgDurationTime != null && row.row.imgDurationTime + " secs"}
        </S.OverFlowBox>
      ),
    },
    {
      field: "use",
      headerName: "Use",
      width: 150,
      // flex: 1,
      renderCell: (row: any) => (
        <Switch
          value={row.row.use}
          checked={row.row.use}
          disabled={
            row.row.nppstatus === "Not Set" ||
            row.row.nppstatus === "Expired" ||
            row.row.nppstatus === "Wait"
              ? true
              : false
          }
          onClick={() => toggleChange(row.row.id, row.row.nppstatus)}
        />
      ),
    },
    {
      field: "nppstatus",
      headerName: "Npp Status",
      width: 150,
      // flex: 1,
      renderCell: (row: any) => (
        <S.StatusBox
          className={
            row.row.nppstatus === "Not Set" ? "NotSet" : row.row.nppstatus
          }
          onClick={(e) => {
            e.stopPropagation();
            console.log(row.row.img, "row");
          }}
        >
          {row.row.nppstatus}
        </S.StatusBox>
      ),
    },
    {
      field: "img",
      headerName: "Image Files",
      width: 200,
      // flex: 1.5,
      renderCell: (row: any) => (
        <S.OverFlowBox style={{ width: "inherit" }}>
          {row.row.img?.map((x: any, idx: number) => (
            <S.OverFlowBox key={idx} style={{ width: "inherit" }}>
              {x.File?.originalName}
              <br />
            </S.OverFlowBox>
            // <>sdas</>
          ))}
        </S.OverFlowBox>
      ),
    },
    {
      field: "isQr",
      headerName: "Offline QR",
      width: 230,
      // flex: 1.5,
      renderCell: (row: any) => (
        <>
          {row.row.isQrUrl != "" && (
            <S.DownloadBtn
              onClick={() => requestQrUrl(row.row.duid, row.row.tvName)}
            >
              <SvgIcon component={SaveAltIcon} inheritViewBox />
              {/* {row.row.guideLink} */}
              download
            </S.DownloadBtn>
          )}
        </>
      ),
    },
    //offine qr
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      // flex: 1,
      renderCell: (row: any) => (
        <S.BtnBox>
          <S.BtnWrapper>
            <EditOutlinedIcon
              onClick={(e: any) => {
                e.stopPropagation();
                setSelectedData([row.row]);
                openNpp();
              }}
            />
          </S.BtnWrapper>
          <S.BtnWrapper>
            <DeleteOutlineOutlinedIcon
              onClick={(e: any) => {
                e.stopPropagation();
                setDeleteID(row.row.id);
                setIsDelete(true);
              }}
            />
          </S.BtnWrapper>
        </S.BtnBox>
      ),
    },
  ];

  const requestQrUrl = async (
    value: string,
    fileName: string
  ): Promise<void> => {
    try {
      const response = await getTvQr(value);
      const qrCodeDataURL: string = await QRCode.toDataURL(response.data.path, {
        width: 500,
        height: 500,
      });
      downloadQR(qrCodeDataURL, fileName);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const downloadQR = async (dataURL: string, fileName: string) => {
    const file = await fetch(dataURL);
    const blob = await file.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${fileName}_QrCode`;
    link.href = url;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const deleteRow = async (id: string, tvname?: string) => {
    try {
      const response = await deleteTvDuid(deleteID);
      console.log(response, "delete response");
      if (response.isSuccess) {
        // setDeleteName(tvname);
        setIsError(true);
        renderAction();
      }
    } catch (e: any) {
      console.log(e, "change Error");
    }
  };

  const toggleChange = async (id: string, originData: string) => {
    let changeValue = {
      status: "",
    };

    if (originData === "Running") {
      changeValue.status = "Pause";
    } else {
      changeValue.status = "Running";
    }
    try {
      const response = await changeTvStatus(id, changeValue);
      if (response.isSuccess) {
        console.log(response, "toggle");
        renderAction();
      }
    } catch (e: any) {
      console.log(e, "change Error");
    }
  };

  return (
    <>
      <PageTop title="TV Status" icon={<DesktopWindowsOutlinedIcon />} />

      <Box
        sx={{
          width: "100%",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(238, 238, 238, 1)",
            color: "black",
            fontFamily: "SamsungOne",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "16px",
          },
        }}
      >
        <DataTable
          searchText={text}
          setSearchText={setText}
          setSearchPage={setPage}
          rowCount={pageCount}
          setTableCount={setTake}
          apiData={rowData}
          isExportBtn
          isSettingAllBtn
          columnData={columns}
          checked
          placeHolder={"Subsidiary,Retailer,Store,TV Name"}
        />
      </Box>
      {isNpp && (
        <SettingModal
          isOpen={isNpp}
          onClose={() => setIsSetting(false)}
          selectedData={selectedData}
        />
      )}
      {isError && (
        <PopupAlert
          isOpen={isError}
          onClose={() => setIsError(false)}
          contents={`${deleteName} Delete Complete`}
        />
      )}
      {isDelete && (
        <ConfirmAlert
          isOpen={isDelete}
          onClose={() => setIsDelete(false)}
          requserApi={() => deleteRow(deleteID)}
        />
      )}
    </>
  );
}
