import ChartBox from "./ChartBox";
import Chart from "react-apexcharts";
import { ChartProps } from "./ChartInterface";
import { useEffect, useState } from "react";
import { getRegistration } from "apis/DashBoard/DashBoardApi";
import ExportBtn from "components/ExportBtn/ExportBtn";
import { CHART_COLORS } from "constants/ChartColor";


export default function Registeration({ getData, startDate, endDate, dropSub }: ChartProps) {

    const data: any = {
        // series: test,
        series: [{
            name: "",
            data: getData ? getData?.map((x: any) => x.value) : []
        }],
        options: {
            chart: {
                height: 140,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                    }
                },
                toolbar: {
                    show: false
                },
            },
            labels: getData ? getData.map((x: any) => x.key) : [],
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true
            },
            xaxis: {
                // categories: ["asdasd  asdasd",'asdasd','asdaasd'],
                categories: getData ? getData?.map((x: any) => x.key) : [],
                labels: {
                    show : false,
                    style: {
                        fontSize: '10px',
                        fontWeight: 500,
                        color: "#000",
                        fontFamily: "Samsung Sharp Sans"
                    },
                    rotate: -40
                }
            },
            tooltip: {
                enabled: true,
                x: {
                    show: false,
                },
                y: {
                    formatter: function (val, opts) {
                        const dataKey = opts.w.globals.labels[opts.dataPointIndex];
                        return `${dataKey}: ${val}`;
                    }
                }
            },
            colors : CHART_COLORS
        },
    };

    return (
        <>
            <ChartBox
                title="Number of New TVs Registration"
                wd="40%"
                ht="510px"
                Topcontents={
                    <ExportBtn
                        startDate={startDate}
                        endDate={endDate}
                        subCode={dropSub}
                        type="registration"
                    />
                }
                contents={
                    <Chart
                        options={data.options}
                        // series={test}
                        series={data.series}
                        type="bar"
                        width="100%"
                        height="390"
                    />
                } />
        </>
    );
}