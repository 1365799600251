import ChartBox from "./ChartBox";
import Chart from "react-apexcharts";
import { ChartProps } from "./ChartInterface";
import { useEffect, useState } from "react";
import ExportBtn from "components/ExportBtn/ExportBtn";
import { SizeBox } from "./ChartBox.style";
import { CHART_COLORS } from "constants/ChartColor";


export default function UsageTime({ getData, startDate, endDate, dropSub }: ChartProps) {

    const [series, setSeries] = useState<any>([]);

    useEffect(() => {
        if (getData) {
            const processedDataArray = getData?.time?.map((time: any, timeIndex: any) => {
                const appValues: string[] = getData ? getData?.apps?.map((app) => getData?.content[app][timeIndex]?.value) : [];
                return { name: time, data: appValues };
            });

            getData?.time?.forEach((time: any, timeIndex: any) => {
                const appValues = [];
                getData?.apps?.forEach((app) => {
                    const value = getData?.content[app][timeIndex]?.value;
                    appValues.push(value);
                });
            });
            setSeries(processedDataArray);
        }
    }, [getData]);


    const data: any = {
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    // borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                // colors: colors,
                                fontSize: '14px',
                                fontWeight: 500,
                                color: "#000",
                                fontFamily: "Samsung Sharp Sans"
                            }
                        }
                    }
                },
            },
            xaxis: {
                categories: getData ? getData.apps : [],
                labels: {
                    style: {
                        // colors: colors,
                        fontSize: '14px',
                        fontWeight: 500,
                        color: "#000",
                        fontFamily: "Samsung Sharp Sans"
                    }
                }
            },
            legend: {
                position: 'right',
                offsetY: 40
            },
            fill: {
                opacity: 1
            },
            colors : CHART_COLORS
        },
    };
    return (
        <>
            <ChartBox
                title="Number of App Usages by Time"
                wd="60%"
                ht="500px"
                Topcontents={
                    <ExportBtn
                        startDate={startDate}
                        endDate={endDate}
                        subCode={dropSub}
                        type="appusage"
                    />
                }
                contents={
                    <Chart
                        options={data.options}
                        series={series || []}
                        type="bar"
                        width="100%"
                        height="390"
                    />
                } />

        </>
    );
}