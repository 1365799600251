import { useState, useContext, useEffect } from "react";
import { RenderContext } from "hooks/context";
import * as S from "./WriteQnA.style";
import { Button } from "@mui/material";
import { BTN_TEXT, QA_TEXT } from "constants/CssConstant";
import { postQna } from "apis/Qna/QnaApi";
import CloseAlert from "components/alert/CloseAlert";
import { useNavigate } from "react-router-dom";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export default function WriteQnA() {
    const {
        isSubsidiary,
        renderAction,
        openSub
    } = useContext(RenderContext);

    const navi = useNavigate();

    const [inputTitle, setInputTitle] = useState<string>("");
    const [inputDes, setInputDes] = useState<string>("");
    const [inputEmail, setInputEmail] = useState<string>("");

    const [files, setFiles] = useState([]);

    const [imgData, setImgData] = useState<any>([]);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSizeInBytes) {
            alert("The file is too large. Please select a file smaller than 5MB.");
            return;
        }
        setImgData([...imgData, file]);
    };

    useEffect(() => {
        if (inputTitle != "" && inputDes != "") {
            setIsAble(false);
        } else {
            setIsAble(true);
        }
    }, [inputTitle, inputDes]);

    const submitAction = async () => {
        if (isAble) {
            return;
        }
        try {
            if (inputEmail != "") {
                if (validateEmail(inputEmail)) {
                    const response = await postQna(inputTitle, inputDes, imgData, inputEmail);
                    navi("/qna");
                } else {
                    alert("Check input E-mail");
                }
            } else {
                const response = await postQna(inputTitle, inputDes, imgData, inputEmail);
                navi("/qna");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [closeModal, setCloseModal] = useState<boolean>(false);
    const [isAble, setIsAble] = useState<boolean>(true);

    const handleRemoveImage = (index: number) => {
        const newImgData = [...imgData];
        newImgData.splice(index, 1);
        setImgData(newImgData);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    return (
        <>
            <S.TopSection>
                <S.TitleBox>
                    Compose Question
                </S.TitleBox>
                <S.BtnBox>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        sx={{
                            color: QA_TEXT,
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            '&:hover': {
                                backgroundColor: "white",
                                border: "1px solid rgba(0, 0, 0, 0.12)",
                            }
                        }}
                        onClick={() => setCloseModal(true)}
                    >
                        CANCLE
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        sx={{
                            color: BTN_TEXT,
                        }}
                        onClick={submitAction}
                        disabled={isAble}
                    >
                        SUBMIT
                    </Button>
                </S.BtnBox>
            </S.TopSection>

            <S.ContentWrapper>
                <S.SubText>
                    Title<span style={{ color: "red" }}> *</span>
                    <S.InputArea
                        $height={50}
                        placeholder="Input Title"
                        onChange={(e: any) => setInputTitle(e.target.value)}
                    />
                </S.SubText>

                <S.SubText>
                    E-Mail
                    <S.InputArea
                        $height={50}
                        placeholder="Input E-mail"
                        onChange={(e: any) => setInputEmail(e.target.value)}
                    />
                </S.SubText>

                <S.SubText>
                    Description<span style={{ color: "red" }}> *</span>
                    <S.InputArea
                        $height={400}
                        placeholder="Input Description"
                        onChange={(e) => setInputDes(e.target.value)}
                    />
                </S.SubText>

                <S.ADDsection>
                    <UploadFileIcon color="primary" />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                        }}
                    >
                        <label htmlFor="upload-photo" style={{ marginLeft: "auto" }}>
                            <input
                                style={{ display: 'none' }}
                                id="upload-photo"
                                name="upload-photo"
                                type="file"
                                // onChange={(e)=>console.log(e)}
                                onChange={(e) => handleFileInputChange(e)}
                                accept=".jpg, .jpeg, .png, .svg, .GIF"
                            />
                            <S.ADDTEXT>
                                Click to upload
                            </S.ADDTEXT>
                        </label>
                    </div>
                    SVG, PNG, JPG or GIF (max. 5MB per file)
                </S.ADDsection>
                <S.ImgWrapper>
                    {imgData.length > 0 &&
                        imgData.map((x: any, idx: number) => {
                            return (
                                <S.ImgBox>
                                    <UploadFileIcon
                                        color="primary"
                                        style={{ flex: 1 }} />
                                    <div style={{ flex: 11 }}>
                                        {x.name}
                                    </div>
                                    <DeleteOutlineOutlinedIcon
                                        onClick={() => handleRemoveImage(idx)}
                                        style={{ flex: 1, cursor: "pointer" }}
                                        sx={{
                                            '&:hover': {
                                                color: "#2196F3"
                                            }
                                        }}
                                    />
                                </S.ImgBox>
                            );
                        })
                    }
                </S.ImgWrapper>
            </S.ContentWrapper>

            {closeModal &&
                <CloseAlert
                    isOpen={closeModal}
                    onClose={() => setCloseModal(false)}
                    navi={() => navi("/qna")}
                />
            }
        </>
    );
}
