import { theme } from "constants/theme";
import styled from "styled-components";

export const ContentBox = styled.div<{wd: string ;ht : string,isDim? : boolean}>`
    position: relative;
    padding : 24px;
    border: 1px solid #BFBFBF;
    background: ${props => `${props.isDim ? "#BFBFBF" : ""}`};
    width: ${props => `${props.wd}`};
    height: ${props => `${props.ht}`};

       @media (${theme.MOBILE}) {
        width: 100%;
        height: auto;
    }
`;


export const ChartTopContents = styled.div`
    display: flex;
`

export const ChartTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    font-family: "Samsung Sharp Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
`;

export const ChartBtn = styled.div`
    display: flex;
    gap : 10px;
    margin-left: auto;
`;


export const ChartContents = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    /* margin-top: -20px; */
    display: flex;

    /* align-items: center; */
    justify-content: center;

    /* background-color: red; */

    /* path:hover{
    fill:var(--color-blue)
} */
`;
// export const ChartContents = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;

//     /* path:hover{
//     fill:var(--color-blue)
// } */
// `;

export const SizeBox = styled.div`
    position: absolute;
    width: 70%;
    margin: auto;
    top : 0;
    bottom : 0;
    left: 0;
    right: 0;

    /* background-color: red; */
`

